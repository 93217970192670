import React, {FC} from "react";
import ReactCodeInput from "react-code-input";
import {RegPinCodeProps, RegStages} from "../../reg-types";
import classes from "./pinCode.module.css";
import Button from "@mui/material/Button";
import { Box, Link, Typography } from "@mui/material";


export const RegPinCodeForm: FC<RegPinCodeProps> = (
    {
        action,
        pinCode,
        handlePinChange,
        error,
        isValid,
        isAgreementChecked,
        handleAgreementChecked,
        isConfirmationSended,
        isTimerStarted,
        repeatConfirmationSend,
        timeLeft,
        toggleErrors,
        isErrorHidden,
        type,
        mail,
        phone
    }) => {

    return (
        <div className={classes.main}>
            {
                type === 'email'
                    ? <Typography className={classes.text}>Введите код из сообщения. Сообщение с кодом направлено на электронную почту {mail}</Typography>
                    :
                    <Typography className={classes.text}>Введите код из сообщения. Сообщение с кодом направлено на номер {phone}</Typography>
            }
            <Box sx={{
                marginTop:2,
            }}>
                <ReactCodeInput onChange={handlePinChange} value={pinCode} name={"pincode"} inputMode={'numeric'} fields={4} />
            </Box>



            {isConfirmationSended
                ? isTimerStarted
                    ? <Typography sx={{
                        marginTop: '22px',
                        marginBottom: '22px',
                        textDecoration: 'none',
                        color: '#009FDA',
                    }}
                    >Запросить код повторно через {timeLeft}</Typography>
                    : <Typography
                        sx={{
                            marginTop: '22px',
                            marginBottom: '22px',
                            textDecoration: 'none',
                            color: '#009FDA',
                            cursor: 'pointer',
                        }}

                        onClick={() => { repeatConfirmationSend() }}
                    >Запросить код повторно</Typography>
                : null
            }
            {
                isErrorHidden
                    ? null
                    : <p onClick={() => { toggleErrors() }} className={classes.error}>{error}</p>
            }





            <Button
                fullWidth={true}
                disabled={!isAgreementChecked}
                sx={{
                    color: 'white',
                    textTransform: 'none',
                    fontSize: 16,
                    fontWeight: 700,
                    height: 50,
                    backgroundColor: '#1890FF',
                    marginTop: '24px'
                }}
                onClick={() => { action() }}
                variant='contained'>Подтвердить</Button>
            <div className={classes.checkboxCont}>
                <input
                style={{cursor:'pointer'}}
                 checked = {isAgreementChecked} onChange={(ev)=>handleAgreementChecked(ev.target.checked)} name={'agreement'} type={'checkbox'} />
                <div className={classes.agreementCont}>
                    <label htmlFor={'agreement'}> <Typography
                        sx={{
                            fontWeight: '400',
                            fontSize: '12px',
                            color: '#757575',
                        }}
                    >Принимаю условия Пользовательского соглашения и даю согласие на обработку своих персональных данных согласно Политике конфиденциальности</Typography></label>

                    <div className={classes.agreementLinks}>
                        <Link
                            sx={{
                                color: '#757575',
                                textDecoration: 'underline',
                                fontSize: '12px'
                            }}
                            href="#">Пользовательское соглашение</Link>
                        <Link
                            sx={{
                                color: '#757575',
                                textDecoration: 'underline',
                                fontSize: '12px'
                            }}
                            href={'#'}>Политика конфиденциальности</Link>
                    </div>

                </div>
            </div>


        </div>
    )
}