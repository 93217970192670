import {useLoginModuleModel} from "../loginModuleModel";
import {RegStages} from "./reg-types";
import {useEffect, useState} from "react";
import {
    createPasswordRequest,
    registerByEmailRequest,
    registerByPhoneRequest,
    sendConfirmationRequest
} from "../../../../services/AuthService";
import {LoginErrors} from "../loginConstants";


export const useRegModel=()=>{

    const templateModel = useLoginModuleModel()

    


    
    const [currentRegStage, setRegStage] = useState<RegStages>(RegStages.initReg)
    const [time, setTime] = useState<string>()
    const [isConfirmationSended, setConfSended] = useState<boolean>(false)
    const [isTimerStarted, setTimerStarted] = useState<boolean>(false)
    const [isErrorHidden, setErorrHidden] = useState<boolean>(true)
    const [isRegistrationComplete, setRegistrationComplete] = useState<boolean>(false)
    const [isAgreementChecked, setAgreementCheked] = useState<boolean>(false)

    const changeRegStage=(value:RegStages)=>{
        setRegStage(value)
    }


    const toggleErrors=()=>{
        setErorrHidden(!isErrorHidden)
    }

    const handleAgreementChecked=(value:boolean)=>{
        setAgreementCheked(value)
    }

    const sendConfirmation= async ()=>{
        const clearPhone = templateModel.phone.replace(/\D+/g,'') 
                templateModel.setLoading(true)
                try {
                    console.log(templateModel.currentTabValue)
                    const response = (templateModel.currentTabValue==='email')
                        ?  await sendConfirmationRequest("EMAIL", templateModel.mail)
                        : await sendConfirmationRequest("PHONE", `+7${clearPhone}`)
                        console.log(response)
                        templateModel.setFormValid(true)
                        setConfSended(true)
                        setErorrHidden(true)
                        timer();
                        console.log('Успешно')
                        changeRegStage(RegStages.pinCode)
                } catch (e) {
                    console.log('Ошибка')
                    console.log(e)
                    templateModel.setFormValid(false)
                    setConfSended(false)
                    setErorrHidden(false)
                }
                templateModel.setLoading(false)
    }


    const register= async (inviteCode?:string)=>{
        const clearPhone = templateModel.phone.replace(/\D+/g,'') 
                templateModel.setLoading(true)
                try {
                    let response;
                    templateModel.currentTabValue==='email'
                        ? response = await registerByEmailRequest(templateModel.mail, templateModel.pinCode)
                        : response = await registerByPhoneRequest(`+7${clearPhone}`, templateModel.pinCode)
                    localStorage.setItem('accessToken', response.data.access_token)
                    localStorage.setItem('refreshToken', response.data.refresh_token)
                    templateModel.setFormValid(true)
                    console.log('Успешно')
                    setErorrHidden(true)
                    changeRegStage(RegStages.passwordCreation)
                } catch (e) {
                    console.log('Ошибка')
                    templateModel.setFormValid(false)
                    setErorrHidden(false)
                }
                templateModel.setLoading(false)
    }

    const createPassword= async ()=>{
        
                templateModel.setLoading(true)
                try {
                   const response = await createPasswordRequest( templateModel.password, templateModel.passwordConfirm)
                    templateModel.setFormValid(true)
                    console.log('Успешно')
                    setErorrHidden(true)
                    setRegistrationComplete(true)
                } catch (e) {
                    console.log('Ошибка')
                    setErorrHidden(false)
                    templateModel.setFormValid(false)
                    setErorrHidden(false)
                }
                templateModel.setLoading(false)
    }


    const timer=()=>{

        let count = 1;
        if (isTimerStarted) {
            return
        }
        let start_time = new Date();
        let stop_time = start_time.setMinutes(start_time.getMinutes() + count);
        let countdown = setInterval(function() {
            let now = new Date().getTime();
            let remain = stop_time - now;
            let min = Math.floor( (remain % (1000 * 60 * 60)) / (1000 * 60) );
            let sec = Math.floor( (remain % (1000 * 60)) / 1000 );
            let strSec = sec < 10 ? "0" + sec : sec;
            if (remain <= 0) {
                clearInterval(countdown);
                setTimerStarted(false)
            }
            (min>=0 && sec>=0) && setTime(min + ":" + strSec)


        }, 1000);
        // помечаем, что таймер уже запущен
        setTimerStarted(true)
    }



    return {
        changeRegStage,
        handleTabChange: templateModel.handleTabChange,
        handlePinChange: templateModel.handlePinChange,
        handlePhoneChange: templateModel.handlePhoneChange,
        handleMailChange: templateModel.handleMailChange,
        handlePasswordChange: templateModel.handlePasswordChange,
        handlePasswordConfirmChange: templateModel.handlePasswordConfirmChange,
        sendConfirmation,
        register,
        createPassword,
        toggleConfirmHide: templateModel.toggleConfirmHide,
        togglePasswordHide: templateModel.togglePasswordHide,
        time,
        timer,
        toggleErrors,
        isRegistrationComplete,
        isErrorHidden,
        isConfirmationSended,
        isTimerStarted,
        LoginErrors,
        showPass: templateModel.showPass,
        showConf: templateModel.showConf,
        isFormValid: templateModel.isFormValid,
        isLoading: templateModel.isLoading,
        currentTabValue: templateModel.currentTabValue,
        pinCode: templateModel.pinCode,
        currentRegStage,
        mail: templateModel.mail,
        password: templateModel.password,
        phone: templateModel.phone,
        isAgreementChecked,
        handleAgreementChecked
    }
}
