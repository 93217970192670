import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route, redirect, Navigate } from "react-router-dom";
import LoginPage from "./pages/Login/LoginPage";
import { ServicesPage } from './pages/Services/ServicesPage';
import { Layout } from './components/layout/Layout';
import { PrivateRoute } from './components/layout/components/PrivateRoute/PrivateRoute';
import { OrganisationsPage } from './pages/Organisations/OrganisationsPage';
import { AnalyticsPage } from './pages/Analytics/AnalyticPage';
import { OrganisationAllInfo } from './pages/Organisations/modules/OrganisationAllInfoModule/OrganisationAllInfo';
import { MainPage } from './pages/Main/MainPage';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path={"/"} element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }>
          <Route path={'/'} element={<MainPage />}/>
          <Route path={"organisations"} element={<OrganisationsPage />}>
          </Route>
          <Route path={'organisations/info'} element={<OrganisationAllInfo/>}/>
          <Route path={'organisations/editInfo/:id'} element={<OrganisationAllInfo/>}/>
          <Route path={"analytics"} element={<AnalyticsPage />} />
          <Route path={"services/"} element={<ServicesPage />}></Route>
          <Route path={"services/:curPage"} element={<ServicesPage />}>

          </Route>
        </Route>
        <Route path={"/login"} element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
