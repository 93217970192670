import { SvgIcon } from "@mui/material";

export const HierarchyIcon = (props: any) => (
    <SvgIcon width="15" height="14" viewBox="0 0 15 14" fill="black" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_3290_89666)">
            <path d="M1.27777 3.11133H2.05555V3.88911H1.27777V3.11133Z"/>
            <path d="M3.22223 3.88911H12.5556C12.6587 3.88911 12.7576 3.84813 12.8306 3.7752C12.9035 3.70227 12.9445 3.60336 12.9445 3.50022C12.9445 3.39708 12.9035 3.29816 12.8306 3.22523C12.7576 3.1523 12.6587 3.11133 12.5556 3.11133H3.22223C3.11909 3.11133 3.02018 3.1523 2.94725 3.22523C2.87432 3.29816 2.83334 3.39708 2.83334 3.50022C2.83334 3.60336 2.87432 3.70227 2.94725 3.7752C3.02018 3.84813 3.11909 3.88911 3.22223 3.88911Z"/>
            <path d="M1.27777 5.44531H2.05555V6.22309H1.27777V5.44531Z"/>
            <path d="M12.5556 5.44531H3.22223C3.11909 5.44531 3.02018 5.48628 2.94725 5.55922C2.87432 5.63215 2.83334 5.73106 2.83334 5.8342C2.83334 5.93734 2.87432 6.03626 2.94725 6.10919C3.02018 6.18212 3.11909 6.22309 3.22223 6.22309H12.5556C12.6587 6.22309 12.7576 6.18212 12.8306 6.10919C12.9035 6.03626 12.9445 5.93734 12.9445 5.8342C12.9445 5.73106 12.9035 5.63215 12.8306 5.55922C12.7576 5.48628 12.6587 5.44531 12.5556 5.44531Z"/>
            <path d="M1.27777 7.77734H2.05555V8.55512H1.27777V7.77734Z"/>
            <path d="M12.5556 7.77734H3.22223C3.11909 7.77734 3.02018 7.81832 2.94725 7.89125C2.87432 7.96418 2.83334 8.06309 2.83334 8.16623C2.83334 8.26937 2.87432 8.36829 2.94725 8.44122C3.02018 8.51415 3.11909 8.55512 3.22223 8.55512H12.5556C12.6587 8.55512 12.7576 8.51415 12.8306 8.44122C12.9035 8.36829 12.9445 8.26937 12.9445 8.16623C12.9445 8.06309 12.9035 7.96418 12.8306 7.89125C12.7576 7.81832 12.6587 7.77734 12.5556 7.77734Z"/>
            <path d="M1.27777 10.1113H2.05555V10.8891H1.27777V10.1113Z"/>
            <path d="M12.5556 10.1113H3.22223C3.11909 10.1113 3.02018 10.1523 2.94725 10.2252C2.87432 10.2982 2.83334 10.3971 2.83334 10.5002C2.83334 10.6034 2.87432 10.7023 2.94725 10.7752C3.02018 10.8481 3.11909 10.8891 3.22223 10.8891H12.5556C12.6587 10.8891 12.7576 10.8481 12.8306 10.7752C12.9035 10.7023 12.9445 10.6034 12.9445 10.5002C12.9445 10.3971 12.9035 10.2982 12.8306 10.2252C12.7576 10.1523 12.6587 10.1113 12.5556 10.1113Z"/>
        </g>
        <defs>
            <clipPath id="clip0_3290_89666">
                <rect width="14" height="14" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </SvgIcon>
)