export const MainPage = () => {

  const at = localStorage.getItem('accessToken')
  const rt = localStorage.getItem('refreshToken')
  const textEncoder = new TextEncoder()
  const ate = textEncoder.encode(at ?? '').toString().replaceAll(',', '.')
  const rte = textEncoder.encode(rt ?? '').toString().replaceAll(',', '.')
  window.location.assign('https://epl.test1.tetron.cloud' + '/' + `?&ate=${ate}&rte=${rte}`)
  
  return (<></>)
}