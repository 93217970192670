import axios from "axios";
import { refreshRequest } from "../services/AuthService";
import { Buffer } from "buffer";
import sanitizedConfig from "../Config";


export const API_URL = sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL

export const $api = axios.create({
    baseURL: API_URL,
    withCredentials: true
}
)

export const $file =axios.create({
    baseURL:API_URL,
    responseType:"blob",
    withCredentials: true
})


const needToUpdate = (token: string) => {

    let currentDate = new Date();

    let decodedToken = JSON.parse(atob(token.split('.')[1]).toString());

    console.log(decodedToken.exp - decodedToken.iat)
    console.log(decodedToken)
    console.log("refresh: ", currentDate.getTime() > decodedToken.exp * 1000)
    return currentDate.getTime() > decodedToken.exp * 1000

}


$api.interceptors.request.use(async (config) => {
    console.log(config)
    config.baseURL = sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL
    if (config.url === '/v1/web/auth/refresh') return config
    let currentAccessToken = localStorage.getItem('accessToken')
    if (!!currentAccessToken) {
        //  if (needToUpdate(currentAccessToken)) {
        //      await refreshRequest()
        //  }
        config.headers.Authorization = `Bearer ${currentAccessToken}`
        config.headers.Cookie = localStorage.getItem('refreshToken')
    }
    return config
}, (error) => {
    console.log('Ошибка!')
    console.log(error)
})


$file.interceptors.request.use(async (config) => {
    console.log(config)
    config.baseURL = sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL
    if (config.url === '/v1/web/auth/refresh') return config
    let currentAccessToken = localStorage.getItem('accessToken')
    if (!!currentAccessToken) {
        //  if (needToUpdate(currentAccessToken)) {
        //      await refreshRequest()
        //  }
        config.headers.Authorization = `Bearer ${currentAccessToken}`
        config.headers.Cookie = localStorage.getItem('refreshToken')
    }
    return config
}, (error) => {
    console.log('Ошибка!')
    console.log(error)
})



// $api.interceptors.response.use((config)=>{
//     config.data.access_token && localStorage.setItem('accessToken', config.data.access_token)
//     config.data.refresh_token && localStorage.setItem('refreshToken', config.data.access_token)
//     console.log('Успешно')
//     console.log(config)
//     return config
// },(error)=>{
//     console.log('Ошибка!')
//     console.log(error)
// })

$api.interceptors.response.use((config) => {
  // config.data.access_token && localStorage.setItem('accessToken', config.data.access_token)
  // config.data.refresh_token && localStorage.setItem('refreshToken', config.data.access_token)
  // console.log('Успешно')
  // console.log(config)
  // return config
  return config
}, async (error) => {
  console.log('Ошибка!')
  console.log(error)

  const originalRequest = error.config
  if (error.response.data.statusCode === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest._isRetry = true
      try {
          let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL+'/v1/web/auth/refresh',
              headers: {'Authorization': `Bearer ${originalRequest.headers.access_token/*localStorage.getItem('accessToken')*/}`},
              withCredentials: true
          }
          const response = await axios.request(config)
          localStorage.setItem('accessToken', response.data.access_token)
          //originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`
          return $api.request(originalRequest)
      } catch (e) {
          console.log(e)
      }
  }

})
