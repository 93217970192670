export const locationLinkStyle={
    fontSize:'14px',
    color:'black',
    '&:hover':{
      color: '#00000040',
    }
  }

export const locationTextStyle={
    fontSize:'14px',
    color:'black',
}