import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {  Employee, EmployeeBuffer, Position, Product, Role } from "./OrganisationEmployees-types"
import { statusType } from "../../Organisation-types"
import { OrganisationsTabsLabels } from "../OrganisationAllInfoModule/OrganisationAllInfo-types"
// import {InfoData } from "./Organisation-types"


interface EmployeeState {
    employees: Employee[],
    employeeData?: Employee,
    bearer: OrganisationsTabsLabels | null,

    employeeBuffer: EmployeeBuffer | null
}

const initialState: EmployeeState = {
    employees: [],
    employeeData: undefined,
    bearer: null,

    employeeBuffer: null
}


export const emplSlice = createSlice({
    name:'empl',
    initialState,
    reducers: {
        setEmployees(state, action: PayloadAction<Employee[]>) {
            state.employees = action.payload
        },
        clearEmployees(state) {
            state.employees = []
        },
        setEmployeeData(state, action:PayloadAction<Employee | undefined>) {
            state.employeeData = action.payload
        },
        clearEmployeeData(state) {
            state.employeeData = undefined
        },
        setBearer(state, action:PayloadAction<OrganisationsTabsLabels>) {
            state.bearer = action.payload
        },
        clearBearer(state) {
            state.bearer = null
        },



        setEmployeeBuffer(state, action: PayloadAction<EmployeeBuffer>) {
            state.employeeBuffer = action.payload
        },
        clearEmployeeBuffer(state) {
            state.employeeBuffer = null
        },

    },
})

export const {
    setEmployees,
    clearEmployees,

    setEmployeeData,
    setEmployeeBuffer,
    clearEmployeeBuffer,


} = emplSlice.actions

export default emplSlice.reducer