export const styles = {
  root: {
    position: 'relative',
    flexDirection: 'row',
    width: '100%',
    pointerEvent: 'none',
    '.iconSize': {
      width: '86px',
      height: '33px',
    },
    '.logo': {
      position: 'absolute',
      top: '6px',
      left: '40px',
      transition: '300ms ease-out',
    },
    '.logo-collapse': {
      position: 'absolute',
      scale: '0.5',
      top: '-4px',
      left: '-16px',
      transition: '300ms ease-out',
    },
    '.title': {
      position: 'absolute',
      top: '5px',
      left: '128px',
      transition: '300ms ease-out',
    },
    '.title-collapse': {
      position: 'absolute',
      scale: '0.5',
      top: '19px',
      left: '-16px',
      transition: '300ms ease-out',
    },
  },
}
