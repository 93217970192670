import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../../../store/hooks/redux";
import { getEmployeesRequest } from "../../../../../../services/EmployeeService";
import { clearEmployees, setEmployeeData, setEmployees } from "../../OrganisationEmployeesSlice";
import { TableCell, Box, Stack, IconButton, Popover, Chip, Typography } from "@mui/material";
import { fill, filter } from "lodash";
import { UnionIcon } from "./assets/UnionIcon";
import { CaretUpIcon } from "./assets/CaretUpIcon";
import { CaretDownIcon } from "./assets/CaretDownIcon";
import { Employee } from "../../OrganisationEmployees-types";
import { getStatusStyle } from "../../../../utils";
import { EMPLOYEE_TABLE_DOC_STATUSES } from "../../OrganisationEmployees-enums";


export interface column {
    id: number,
    name?: string,
    sort?: any | false,
    sortMode?: string,
    filter?: any | false,
    value?: any,
    visible?: boolean,
    onClick?: any | false,
    fullValue?: any | false
}

export const tableHeadRow = {
    backgroundColor: '#FAFAFA',
    position: 'sticky',
    top: '0px',
    zIndex: '2',
  
    '& .MuiTableCell-root': {
      borderColor: '#EEEEEE',
      position: 'relative',
  
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '1.3',
      color: '#222222',
      
      '&::after': {
        padding: '0px',
        content: '""',
        position: 'absolute',
        height: '22px',
        width: '1px',
        backgroundColor: '#EEEEEE',
        marginTop: '-20px',
        right: 0
      },
      "&:last-child": {
        '&::after': {
          display: 'none',
        },
      },
    },
  
    '& .MuiIconButton-root': {
      padding: '0px',
      margin: '-10px -5px -10px -5px',
      width: '20px',
      height: '40px',
      borderRadius: '6px',
      alignContent: 'center'
    }
  }
export const tableHeadCellData = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    textWrap: 'nowrap',
  }
  
export const tableBodyRow = {
    '& .MuiTableCell-body': {
      backgroundColor: '#FFFFFF',
      borderColor: '#EEEEEE',
      padding: '16px',
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#222222',
      // "&:last-child": {
      //   //borderTop: 'none',
      //   borderBottom: 'none',
      // },
      overflowX: 'hidden',
      //whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '300px', // ???

      '&:hover': {
        backgroundColor: "#E3F2FF"
      },
      '&.Mui-selected': {
        backgroundColor: "#E3F2FF"
      }
    },
    // '& :hover': {
    //   backgroundColor: "#E3F2FF"
    // },
    // '& .Mui-selected': {
    //   backgroundColor: "#E3F2FF"
    // }
  }


export const TableHeadCell = ({ id, name, sort, filter, visible, sortMode }: column) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget) }
    const handleClose = () => { setAnchorEl(null) }
  
    const [clickCount, setClickCount] = useState<number>(0)
    const handleSort = () => { setClickCount((prev)=>{sort(sortMode===''?1:prev+1); return sortMode===''?1:prev+1});}
  
    useEffect(()=>{console.log(id)},[sortMode])
    
    const [filterMode, setFilterMode] = useState<boolean>(false)
  
    return visible ?
      <TableCell align="left">
        <Box sx={tableHeadCellData}>
          <Box>{name}</Box>
          <Stack flexDirection={'row'} gap={'inherit'}>
          { sort &&
            <IconButton onClick={handleSort}>
              <Stack spacing={'-3px'}>
                <CaretUpIcon fill={sortMode === 'asc' ? '#1890FF' : '#9B9B9B'}/>
                <CaretDownIcon fill={sortMode === 'desc' ? '#1890FF' : '#9B9B9B'}/>
              </Stack>
            </IconButton>
          }
          { filter && 
            <IconButton 
              onClick={handleOpen}>
              <UnionIcon fill={filterMode ? '#1890FF' : '#9B9B9B'}/>
            </IconButton>
          }
          </Stack>        
        </Box>
        { filter &&
          <Popover 
            sx={{
              '& .MuiPaper-root': {
                marginTop: '17px',
                boxShadow: '0 5px 5px 7px rgba(0,0,0,0.05)',
                //maxWidth: '320px',
              }
            }}
            open={Boolean(anchorEl)} 
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
          >
            {filter && filter(handleClose, setFilterMode)}
          </Popover>
        }
      </TableCell>
    : null
}
  
export const TableBodyCell = ({ id, value, visible, onClick, fullValue }: column) => {
    return visible ?
      <TableCell align="left"
        onClick={() => onClick(fullValue)}
      >
        {value}
      </TableCell>
    : null
  }  


  export const getChipsStyle = (chips: string[]) => {

    return (
      <Stack gap={'4px'}>
      {!!chips && chips.length > 0 && chips.map((chip) => (
        <Chip 
        sx={{ 
          inlineSize: 'fit-content', maxHeight: '24px',
          backgroundColor: '#009FDA', color: '#FFFFFF',
          fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
          '& .MuiChip-label': { padding: '2px 8px' },
          borderRadius: '4px', 
          pointerEvents: 'none'
        }}
        label={chip}
      ></Chip>
      ))}
    </Stack>
    )
  }


  export const getEmployeeStatusChip = (status: string) => {

    const chipStyle = {
      '&.MuiChip-root': { 
        pointerEvents: 'none',
        maxHeight: '22px', border: '1px solid', borderRadius: '4px',
        fontFamily: 'Roboto', fintWeight: '400', fontSize: '14px', lineHeight: '1.3',
      },
      '& .MuiChip-label': { padding: '2px 8px' }
    }
  
    switch (status) {
      case 'В системе':
        return <Chip sx={{ ...chipStyle, backgroundColor: '#EFFFEE', borderColor: '#33C341', color: '#33C341' }} variant="outlined" label={'В системе'} />
      case 'Черновик':
        return <Chip sx={{ ...chipStyle, backgroundColor: '#EEEEEE', borderColor: '#8E8E8E', color: '#8E8E8E' }} variant="outlined" label={'Черновик'} />
      default:
        return <></>
    }
  }

export const getDocumentStatusChip = (status: EMPLOYEE_TABLE_DOC_STATUSES) => {

  const chipStyle = {
    '&.MuiChip-root': { 
      pointerEvents: 'none',
      maxHeight: '22px', borderRadius: '4px', border: 'none', //border: '1px solid',
      fontFamily: 'Roboto', fintWeight: '400', fontSize: '14px', lineHeight: '1.3',
    },
    '& .MuiChip-label': { padding: '2px 8px' }
  }

  switch (status) {
    case EMPLOYEE_TABLE_DOC_STATUSES.ACTUAL:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #00910E', borderColor: 'none', color: '#00910E' }} 
        variant="outlined" label={EMPLOYEE_TABLE_DOC_STATUSES.ACTUAL} />
    case  EMPLOYEE_TABLE_DOC_STATUSES.PROCESSING:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #009FDA', borderColor: 'none', color: '#009FDA' }} 
        variant="outlined" label={EMPLOYEE_TABLE_DOC_STATUSES.PROCESSING} />
    case EMPLOYEE_TABLE_DOC_STATUSES.MISSED:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #009FDA', borderColor: 'none', color: '#009FDA' }} 
        variant="outlined" label={EMPLOYEE_TABLE_DOC_STATUSES.MISSED} />
    case EMPLOYEE_TABLE_DOC_STATUSES.NEW:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #009FDA', borderColor: 'none', color: '#009FDA' }} 
        variant="outlined" label={EMPLOYEE_TABLE_DOC_STATUSES.NEW} />
    case EMPLOYEE_TABLE_DOC_STATUSES.ERROR:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #F0901E', borderColor: 'none', color: '#F0901E' }} 
        variant="outlined" label={EMPLOYEE_TABLE_DOC_STATUSES.ERROR} />
    case EMPLOYEE_TABLE_DOC_STATUSES.EXPIRED:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #DC4C4C', borderColor: 'none', color: '#DC4C4C' }} 
        variant="outlined" label={EMPLOYEE_TABLE_DOC_STATUSES.EXPIRED} />
    default:
      return <Chip sx={{ ...chipStyle, backgroundColor: 'transparent', textDecoration: 'underline #000', borderColor: 'none', color: '#000' }} 
        variant="outlined" label={status} />
  }

}

export interface EmployeesTableModelProps {
  handleSetEditEmployeeData: (employee: Employee) => void
}

export const useEmployeesTableModel = ({handleSetEditEmployeeData}: EmployeesTableModelProps) => {

    const { id } = useParams();

    const { employees } = useAppSelector(state => state.emplReducer)
    const dispatch = useAppDispatch();
    
  const [isCheckDocumentsOpen, setCheckDocumentsOpen] = useState(false);

  const getEmployees = async (query?: any) => {
    try {
        const response = await getEmployeesRequest(Number(id), query)
        console.log('EMPLOYEES')
        console.log(response)
        return response
    } catch(e) {
        console.error(e)
    }
  }

  const [page, setPage] = useState(1)
  const [curPage, setCurPage] = useState(0)
  const [pageQty, setPageQty] = useState(0)

    useEffect(() => {
      if (!!curPage) {
          setPage(Number(curPage))
       } else setPage(1);
    }, [curPage])

    useEffect(() => {
      const response = getEmployees({page: page})
      response.then((result) => {
          dispatch(setEmployees(result?.data.data))
          setPageQty(result?.data.last_page)  
        })
    }, [page])



    
    const [columns, setColumns] = useState<column[]>([
      {
        id: 1,
        name: 'Статус',
        sort: false,
        filter: false,
        value: (employee: Employee) => (getEmployeeStatusChip(employee?.status!)),
        visible: true,
        onClick: (employee: Employee) => handleSetEditEmployeeData(employee),
        fullValue: false
      },
      {
        id: 2,
        name: 'Ф.И.О.',
        sort: false,
        filter: false,
        value: (employee: Employee) => (employee.lastname + ' ' + employee.firstname + ' ' + employee.middlename),
        visible: true,
        onClick: (employee: Employee) => handleSetEditEmployeeData(employee),
        fullValue: false
      },
      {
        id: 3,
        name: 'Должность',
        sort: false,
        filter: false,
        value: (employee: Employee) => employee.position?.name,
        visible: true,
        onClick: (employee: Employee) => handleSetEditEmployeeData(employee),
        fullValue: false
      },
      {
        id: 4,
        name: 'Документы',
        sort: false,
        filter: false,
        value: (employee: Employee) => getDocumentStatusChip(employee.documents.status),
        visible: true,
        onClick: (employee: Employee) =>{
          dispatch(setEmployeeData(employee))
          setCheckDocumentsOpen(true)
        } ,
        fullValue: false
      },
      {
        id: 5,
        name: 'Подразделения',
        sort: false,
        filter: false,
        value: (employee: Employee) => employee.department?.name,
        visible: true,
        onClick: (employee: Employee) => handleSetEditEmployeeData(employee),
        fullValue: false
      },
      {
        id: 6,
        name: 'Продукты',
        sort: false,
        filter: false,
        value: (employee: Employee) => getChipsStyle(employee.products),
        visible: true,
        onClick: (employee: Employee) => handleSetEditEmployeeData(employee),
        fullValue: false
      },
      {
        id: 7,
        name: 'Роли',
        sort: false,
        filter: false,
        value: (employee: Employee) => getChipsStyle(employee.roles),
        visible: true,
        onClick: (employee: Employee) => handleSetEditEmployeeData(employee),
        fullValue: false
      },
    ])



    return {
        employees, 
        columns,
        isCheckDocumentsOpen,
        setCheckDocumentsOpen,
        page, curPage, pageQty,
        setPage,
    }
}