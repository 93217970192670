import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip 
        arrow 
        placement='top-start' 
        {...props} 
        classes={{ popper: className }} 
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: '#009FDA',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 0px 1px #009FDA',
        fontSize: 12,
        display: 'inline-block',
        maxHeight: '30px'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
        "&:before": {
            border: "1px solid #009FDA"
        },
    },
}));