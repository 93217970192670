import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled } from "@mui/material";
import { getEntryStyle, getStatusStyle } from "../../../../utils";
import { useSubdivisionsTableModel } from "./SubdivisionsTableModel";
import styles from './SubdivisionTableStyles.module.css'
import { TreeItem, TreeView } from "@mui/lab";
import { Subdivision } from "../../OrganisationSubdivision-types";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';


export const SubdivisionsTable = (props: {
    hierarchy: boolean
}) => {

    const subdivisionsTableModel = useSubdivisionsTableModel()

    const SubdivisionsTreeItem = styled(TreeItem)`
        & .MuiTreeItem-label {
            &.root {
                font-size: 16px;
                color: #4058B8;
                font-weight: bold;
            }

            &.node {
                font-size: 14px;
                margin-left: -8px;
            }
        }
        & .MuiTreeItem-content {
            &.node {
                margin-left: -6px;
            }
        }
    `

    const renderTree = (subdivision: Subdivision) => (
        <SubdivisionsTreeItem 
            key={subdivision.id} 
            nodeId={`${subdivision.id}`} 
            label={subdivision.name}

            collapseIcon={
                subdivisionsTableModel.subdivisions.find((item) => 
                    item.parent?.id === subdivision.id
                ) ? <KeyboardArrowDownRoundedIcon /> : null
            }
            expandIcon={
                subdivisionsTableModel.subdivisions.find((item) => 
                    item.parent?.id === subdivision.id
                ) ? <KeyboardArrowRightRoundedIcon /> : null
            }

            sx={{ margin: '8px 0px'}}
            classes={{
                label: ((!subdivision.parent) ? 'root' : 'node'),
                content: ((!subdivision.parent) ? '' : 'node')
            }}
        >
            {subdivisionsTableModel.subdivisions.map((node:Subdivision) => (
                (subdivision.id === node.parent?.id) ? renderTree(node) : null
            ))}
        </SubdivisionsTreeItem>
    );

    if (props.hierarchy)
        return (
            <TreeView>
            {subdivisionsTableModel.subdivisions.map((subdivisionRoot: Subdivision) => (
                !subdivisionRoot.parent ? renderTree(subdivisionRoot) : null
            ))}
            </TreeView>
    )

    return (
        <TableContainer sx={{maxWidth: '630px', width:'100%'}}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow 
                        sx={{backgroundColor:'#FAFAFA'}}>
                        <TableCell 
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                textAlign: 'left',
                            }} 
                            align="right">
                            Подразделение
                        </TableCell>

                        <TableCell 
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                textAlign: 'left',
                            }} 
                            align="right">
                            Родитель
                        </TableCell>

                        <TableCell 
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                textAlign: 'left',
                            }} 
                            align="right">
                            Статус
                        </TableCell>

                    </TableRow>
                </TableHead>

                <TableBody>
                    {subdivisionsTableModel.subdivisions.map((subdivision) => (
                        <TableRow
                        key={subdivision.id}>

                            <TableCell 
                                component="th" 
                                scope="row"
                                sx={{
                                    minWidth: '195px',
                                }}>
                                {subdivision.name}
                                {getEntryStyle(subdivision.is_new!)}
                            </TableCell>

                            <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                    minWidth: '195px'
                                }}>
                                {subdivision.parent?.name || '-' }
                            </TableCell>

                            <TableCell 
                                sx={{
                                    minWidth: '195px'
                                }}>
                                {getStatusStyle(subdivision?.status!)}
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        
    );
}