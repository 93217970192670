import { Card, Box, Typography, IconButton, FormGroup, FormControl, Autocomplete, Popper, Paper, TextField, Button } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { autocompleteStyle, popperFromAutocompleteStyle, paperFromAutocompleteStyle, textFieldFilledStyle, blueContainedBigButtonStyle } from "../../../../../../styles/MaterialCustomStyles";
import { statusLabelType } from "../../../../Organisation-types";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SubdivisionCardModelProps, useSubdivisionCardModel } from "./SubdivisionCardModel";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useEffect } from "react";
import { SucceedWindow } from "../../../../components/SucceedWindow/SucceedWindow";


export const SubdivisionCard = (props: SubdivisionCardModelProps) => {
    const subdivisionCardModel = useSubdivisionCardModel(props)

    if (subdivisionCardModel.isRequestSucceed) {
        return <SucceedWindow open={subdivisionCardModel.isRequestSucceed} />
    }

    return props.modal ?
    (
        <Card
            sx={{
                width: '362px',
                height: '713px',
                padding: '24px',
                overflowY: 'visible',
                backgroundColor: '#EEEEEE',
                position: 'fixed',
                zIndex: 10,
                top: 125,
                right: 24,
            }}>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '25px'
                }}>
                <Typography
                    variant='h5'
                    sx={{
                        color: '#3E3E3E',
                        fontSize: '20px',
                        fontWeight: '500',
                        marginLeft: '22px',
                    }}>
                    Подразделение
                </Typography>
                <IconButton onClick={subdivisionCardModel.handleCloseModal}>
                    <CloseRoundedIcon sx={{color: '#3E3E3E', scale: '1.2'}}/>
                </IconButton>
            </Box>
            
            <FormGroup
                    sx={{
                        rowGap: '24px',
                        maxWidth: '314px',
                    }}>

                    <FormControl>
                        <Autocomplete 
                            open={subdivisionCardModel.openNameSuggestions}
                            onOpen={() => {
                                //subdivisionCardModel.nameInput.length > 0 && 
                                subdivisionCardModel.setOpenNameSuggestions(true)
                            }}
                            onClose={() => {
                                subdivisionCardModel.setOpenNameSuggestions(false)
                            }}

                            onInputChange={(_, newInputValue) => {
                                subdivisionCardModel.setNameInput(newInputValue)
                            }}

                            inputValue={subdivisionCardModel.nameInput || subdivisionCardModel.subdivisionData?.name || ''}
                            

                            sx={autocompleteStyle}
                            popupIcon={<KeyboardArrowRightRoundedIcon />}
                            PopperComponent={(props) => (
                                <Popper {...props} sx={popperFromAutocompleteStyle} />
                            )}
                            PaperComponent={(props) => (
                                <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                            )}
                            freeSolo forcePopupIcon
                            getOptionDisabled={option => true}

                            options={subdivisionCardModel.nameOptions || []}
                            
                            isOptionEqualToValue={(option:any, value:any) => option === value}
                            //getOptionLabel={(option) => option}
                            getOptionLabel={(option: any) => option.name || option}
                            noOptionsText={'Не найдено'}

                            renderOption={(props, option:any, { inputValue }) => {
                                const matches = match(option.name, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.name, matches);
                                return (
                                    <li {...props} key={option.id}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        color: part.highlight
                                                            ? '#009FDA'
                                                            : 'none',
                                                    }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='filled'
                                    sx={textFieldFilledStyle}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ 
                                        ...params.InputProps, 
                                        disableUnderline: true,
                                    }}

                                    label='Название подразделения'
                                    placeholder='Название подразделения'
                                    autoComplete='off'
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl>
                        <Autocomplete 
                            open={subdivisionCardModel.openParentSuggestions}
                            onOpen={() => {
                                //subdivisionCardModel.parentInput.length > 0  && 
                                subdivisionCardModel.setOpenParentSuggestions(true)
                            }}
                            onClose={() => {
                                subdivisionCardModel.setOpenParentSuggestions(false)
                            }}

                            onChange={(_, newParent: any) =>
                                subdivisionCardModel.handleSetParentId(newParent?.id)
                            }
                            
                            onInputChange={(_, newInputValue) => {
                                subdivisionCardModel.setParentInput(newInputValue)
                            }}

                            
                            sx={autocompleteStyle}
                            popupIcon={<KeyboardArrowRightRoundedIcon />}
                            PopperComponent={(props) => (
                                <Popper {...props} sx={popperFromAutocompleteStyle} />
                            )}
                            PaperComponent={(props) => (
                                <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                            )}
                            freeSolo forcePopupIcon
                            
                            options={subdivisionCardModel.parentOptions || []}

                            isOptionEqualToValue={(option:any, value:any) => option === value}
                            getOptionLabel={(option:any) => option.name || option}
                            noOptionsText={'Не найдено'}

                            renderOption={(props, option:any, { inputValue }) => {
                                const matches = match(option.name, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.name, matches);
                                return (
                                    <li {...props} key={option.id}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        color: part.highlight
                                                            ? '#009FDA'
                                                            : 'none',
                                                    }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='filled'
                                    sx={[
                                        textFieldFilledStyle, 
                                        {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                    ]}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ 
                                        ...params.InputProps, 
                                        disableUnderline: true,
                                    }}
                                    label='Вышестоящее подразделение'
                                    placeholder='Вышестоящее подразделение'
                                    autoComplete='off'
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl>
                        <Autocomplete 
                            onChange={(_, newStatus) =>
                               subdivisionCardModel.handleSetStatus(newStatus.key)
                            }

                            sx={autocompleteStyle}
                            popupIcon={<KeyboardArrowRightRoundedIcon />}
                            PopperComponent={(props) => (
                                <Popper {...props} sx={[popperFromAutocompleteStyle, {minWidth: '312px'}]} />
                            )}
                            PaperComponent={(props) => (
                                <Paper elevation={2} {...props} sx={[paperFromAutocompleteStyle, {marginTop: '-66px'}]} />
                            )}
                            disableClearable

                            options={Object.entries(statusLabelType).map(entry => {
                                const [key, value] = entry;
                                return {key, value}
                            })}
                            isOptionEqualToValue={(option, value) => option.key === value.key}
                            getOptionLabel={(option) => option.value}
                            noOptionsText={'Не найдено'}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='filled'
                                    sx={[textFieldFilledStyle, {
                                        '& .MuiInputBase-root': {cursor: 'pointer'},
                                        '& input': {cursor: 'pointer'}
                                    }]}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ 
                                        ...params.InputProps, 
                                        disableUnderline: true,
                                    }}

                                    inputProps={{
                                        ...params.inputProps, 
                                        readOnly: true 
                                    }}

                                    label='Статус'
                                    placeholder='Неизвестный'
                                    autoComplete='off'
                                />
                            )}
                        />
                    </FormControl>

                    <Button 
                        variant='contained'
                        sx={blueContainedBigButtonStyle}
                        onClick={() => {subdivisionCardModel.handleSaveSubdivision()}}
                        disabled={!subdivisionCardModel.validateSubdivisionData()}>
                        Сохранить
                    </Button>
 
            </FormGroup>

        </Card>
    ) 
    : null
}