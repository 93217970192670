import { Box } from '@mui/material'
import { type FC } from 'react'
import { styles } from './styles'
import type { SidebarMenuProps } from './types'
import { MenuLink } from './components/MenuLink'
import type { MenuLinkProps } from './components/MenuLink/types'
import { AnalysisIcon } from '../../icons/AnalysisIcon'
import { HelpIcon } from '../../icons/HelpIcon'
import { MedExamIcon } from '../../icons/MedExamIcon'
import { OrganizationIcon } from '../../icons/OrganizationIcon'
import { TechInspectIcon } from '../../icons/TechInspectIcon'
import { WaybillIcon } from '../../icons/WaybillIcon'
import { MainIcon } from '../../icons/MainIcon'

const MENU_ITEMS: any[] = [
  {
    title: 'Главная',
    url: '/',
    icon: <MainIcon />,
  },
  {
    title: 'ЭПЛ',
    icon: <WaybillIcon />,
    subLinks: [
      {
        title: 'Путевые листы',
        url: '/waybill',
      },
      {
        title: 'Справочники',
        url: '/dictionaries',
      },
      {
        title: 'Заявки',
        //url: '/applications',
      },
      {
        title: 'Инциденты',
        //url: '/incidents',
      },
      {
        title: 'Отчёты',
        url: '/analysis',
      },
    ],
  },
  {
    title: 'Медосмотры',
    icon: <MedExamIcon />,
    subLinks: [
      {
        title: 'Медосмотры',
        //url: '/med-exams',
      },
      {
        title: 'График смен',
        //url: '/schedule',
      },
      {
        title: 'Мои Документы',
        //url: '/documents',
      },
      {
        title: 'Отчёты',
        //url: '/med-reports',
      },
    ],
  },
  {
    title: 'Техосмотры',
    icon: <TechInspectIcon />,
    //url: '/tech-inspect',
  },
  {
    /* Divider */
  },
  {
    title: 'Аналитика',
    icon: <AnalysisIcon />,
    //url: '/analytics', //'/analisys-main', 
  },
  {
    title: 'Мои Организации',
    icon: <OrganizationIcon />,
    url: '/organisations',
  },
  {
    title: 'Помощь',
    icon: <HelpIcon />,
    //url: '/help',
  },
]

  const urls = ['/analysis', '/dictionaries', '/waybill']
  const onClick = (e:any, url:string) => {
    if (urls.find((e)=>e===url)) {
      e.preventDefault()
      const at = localStorage.getItem('accessToken')
      const rt = localStorage.getItem('refreshToken')
      const textEncoder = new TextEncoder()
      const ate = textEncoder.encode(at ?? '').toString().replaceAll(',', '.')
      const rte = textEncoder.encode(rt ?? '').toString().replaceAll(',', '.')
      window.location.assign('https://epl.test1.tetron.cloud' + url + `?&ate=${ate}&rte=${rte}`)
    }
  }

export const SidebarMenu: FC<SidebarMenuProps> = (props) => {
  const { collapse, setCollapse } = props

  const renderItems = MENU_ITEMS.map((item, index) => (
    <MenuLink
      url={item.url}
      title={item.title}
      icon={item.icon}
      subLinks={item.subLinks}
      collapse={collapse}
      setCollapse={setCollapse}
      key={index}
      onClick={onClick}
    />
  ))

  return <Box sx={styles.root}>{renderItems}</Box>
}
