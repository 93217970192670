import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AccData } from './OrganisationAccounts-types';

interface AccountsState {
    bankAccounts: AccData[];
}

const initialState: AccountsState = {
    bankAccounts: [],
};

export const accSlice = createSlice({
    name: 'acc',
    initialState,
    reducers: {
        setAccounts(state, action: PayloadAction<AccData[]>) {
            state.bankAccounts = action.payload;
        },
        clearAccounts(state) {
            state.bankAccounts = [];
        },
    },
});

export const { setAccounts, clearAccounts } = accSlice.actions;

export default accSlice.reducer;
