import { Box, Button, Modal, Toolbar, Typography } from '@mui/material';
import { BankAccountsTable } from './components/BankAccountsTable';
import { useBankAccountsInfo } from './OrganisationAccountsInfoModel';
import {
    blueContainedButtonStyle,
    whiteOutlinedButtonStyle,
} from '../../../../styles/MaterialCustomStyles';
import { AccountCard } from './modules/BankAccountCardModule/BankAccountCard';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export const OrganisationAccountsInfo = () => {
    const bankAccountsInfoModel = useBankAccountsInfo();

    return (
        <Box
            sx={{
                position: 'relative',
            }}>
            <Toolbar
                sx={{
                    backgroundColor: 'white',
                    padding: '24px',
                    justifyContent: 'space-between',
                    display: 'flex',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        columnGap: '22px',
                        alignItems: 'center',
                        width: '483px',
                    }}>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                        }}
                        component={'h3'}>
                        Банковские счета
                    </Typography>
                    <Button
                        onClick={() => bankAccountsInfoModel.openModal()}
                        variant='contained'
                        disableElevation
                        sx={blueContainedButtonStyle}>
                        + Добавить счет
                    </Button>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        columnGap: '16px',
                        alignItems: 'center',
                    }}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            // navigate('/organisations/info')
                        }}
                        disableRipple
                        variant='outlined'
                        sx={whiteOutlinedButtonStyle}>
                        Редактировать
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            // navigate('/organisations/info')
                        }}
                        disableRipple
                        variant='outlined'
                        endIcon={<KeyboardArrowDownRoundedIcon />}
                        sx={{
                            ...whiteOutlinedButtonStyle,
                            padding: '5px 12px',
                            '& .MuiButton-endIcon': {
                                marginLeft: '2px',
                                marginRight: '-7px',
                                transform: 'rotate(-90deg)',
                                fontSize: '17px',
                            },
                        }}>
                        Еще
                    </Button>
                </Box>
            </Toolbar>

            {bankAccountsInfoModel.isSuccessOpen && (
                <Modal
                    open={bankAccountsInfoModel.isSuccessOpen}
                    aria-labelledby='modal-title'
                    aria-describedby='modal-description'>
                    <Box sx={bankAccountsInfoModel.modalBoxStyle}>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'>
                            Данные успешно внесены!
                        </Typography>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='95'
                            height='94'
                            viewBox='0 0 95 94'
                            fill='none'>
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M47.1042 94C73.0616 94 94.1042 72.9574 94.1042 47C94.1042 21.0426 73.0616 0 47.1042 0C21.1469 0 0.104248 21.0426 0.104248 47C0.104248 72.9574 21.1469 94 47.1042 94ZM73.3781 38.0239C75.7213 35.6807 75.7213 31.8818 73.3781 29.5386C71.035 27.1955 67.236 27.1955 64.8929 29.5386L42.3308 52.1007L30.05 39.8199C27.7069 37.4767 23.9079 37.4767 21.5647 39.8199C19.2216 42.163 19.2216 45.962 21.5647 48.3051L38.0882 64.8286L42.3308 69.0712L46.5735 64.8286L73.3781 38.0239Z'
                                fill='#33C441'
                            />
                        </svg>
                    </Box>
                </Modal>
            )}

            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '16px 24px 24px 24px',
                }}>
                {bankAccountsInfoModel.isModalOpen && (
                    <AccountCard
                        isOpen={bankAccountsInfoModel.isModalOpen}
                        onClose={() => {
                            bankAccountsInfoModel.closeModal();
                        }}
                        onSubmit={bankAccountsInfoModel.handleCreateAccount}
                        selectedAcc={bankAccountsInfoModel.selectedAcc}
                        onEdit={bankAccountsInfoModel.handleEditAccount}
                    />
                )}
                <BankAccountsTable
                    bankAccounts={bankAccountsInfoModel.bankAccounts}
                    onChangeMain={bankAccountsInfoModel.changeMainAccount}
                    onClickAcc={bankAccountsInfoModel.onClickAccRow}
                />
            </Box>
        </Box>
    );
};
