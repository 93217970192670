import { Box, Button, Typography } from "@mui/material"
import { blueContainedButtonStyle } from "../../../../styles/MaterialCustomStyles"
import { OrganisationsTable } from "../../components/OrganisationsTable/OrganisationsTable"
import { useEmployeesModel } from "./OrganisationEmployeesModel"
import { EmployeesToolbar } from "./components/EmployeesToolBar"
import { OrganisationsTabsLabels } from "../OrganisationAllInfoModule/OrganisationAllInfo-types"
import { SucceedWindow } from "../../components/SucceedWindow/SucceedWindow"
import { EmployeesTable } from "./modules/EmployeesTable/EmployeesTable"
import { EmployeeCard } from "./modules/EmployeeCard/EmployeeCard"
import { CheckDocumentsDrawer } from "./modules/drawers/CheckDocumentsDrawer/CheckDocumentsDrawers"



export const OrganisationEmployees = (props: {
    //companyId: number
    tabValue: OrganisationsTabsLabels
    setTab: (tab: OrganisationsTabsLabels) => void
}) => {

    const employeesModel = useEmployeesModel();
    
    return (
        <Box 
            sx={{
                position: 'relative',
                minHeight:'80vh',
            }}>
                        
            <EmployeesToolbar
                handleOpenModal={employeesModel.handleOpenModal} 
            />

            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '24px',
                }}>

                    <EmployeesTable
                        // setDocuments={employeesModel.setModalOpen} 

                        handleSetEditEmployeeData={employeesModel.handleSetEditEmployeeData}
                    />
 
                    <EmployeeCard 
                        modal = {employeesModel.isModalOpen}
                        setModal = {employeesModel.setModalOpen}
                        setTab = {props.setTab}
                        editEmployeeData={employeesModel.editEmployeeData}
                    />
                   
            </Box>
        </Box>
    )

}