import { useEffect, useState } from "react"
import { MainInfoTabsLabels } from "./OrganisationMainInfo-types"
import { createCompanyRequest, editCompanyRequest } from "../../../../services/CompanyService"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux"
import { useLocation, useParams } from "react-router-dom"
import { InfoData } from "../../Organisation-types"
import { debounce } from "lodash"
import { getDadataCompaniesByIDRequest, getDadataCompaniesRequest } from "../../../../services/DadataService"
import { DadataCompany } from "./OrganisationMainInfo-types"


export const useOrganisationMainInfoModel = () => {

    const { id } = useParams();
    const {
        currentOrganisation
    } = useAppSelector(state => state.orgReducer)

    const dispatch = useAppDispatch();

    const [tabValue, setTab] = useState<MainInfoTabsLabels>(MainInfoTabsLabels.MAIN)
    const [selectedCompany, setSelectedCompany] = useState<DadataCompany>()
    const [mainInfoData, setMainInfoData] = useState<InfoData>({
        inn: null,
        kpp: null,
        ogrn: null,
        short_title: null,
        title: null,
    })

    const [dadataCompanies, setDadataCompanies] = useState([])
    const [options, setOptions] = useState<any>([])
    const [isValid, setValid] = useState<boolean>(false)


    useEffect(() => {
        !!id && setMainInfoData(()=>currentOrganisation)
    }, [currentOrganisation])


    useEffect(() => {
        console.log(dadataCompanies)
        const currentOptions = dadataCompanies.map((el: DadataCompany) => {
            return {
                ...el,
                label: el.value
            }
        })
        // const currentOptions:any = []
        console.log('current options')
        console.log(currentOptions)
        setOptions(()=>currentOptions)
    }, [dadataCompanies])


    useEffect(() => {
        setValid(validateData())
    }, [mainInfoData])


    useEffect(() => {
        selectedCompany &&
            setMainInfoData({
                inn: selectedCompany.data.inn,
                kpp: selectedCompany.data.kpp,
                ogrn: selectedCompany.data.ogrn,
                title: selectedCompany.value,
                short_title: selectedCompany.value,
            })
    }, [selectedCompany])


    const createCompany = async (data: InfoData) => {
        console.log(data)
        const response = await createCompanyRequest(data)
        console.log(response);
    }

    const editCompany = async (data: InfoData) => {
        const response = await editCompanyRequest({ ...data, id: id })
        console.log(response);
    }

    const getSuggestions = async (name: string) => {
        try {
            const response = await getDadataCompaniesRequest(name);
            setDadataCompanies(()=>response.data.suggestions)
        } catch (error) {
        }
    };

    const getSuggestionsByID = async (inn: string) => {
        try {
            const response = await getDadataCompaniesByIDRequest(inn);
            console.log('by id')
            console.log(response.data)
            setDadataCompanies(()=>response.data.suggestions)
        } catch (error) {
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, value: MainInfoTabsLabels) => {
        setTab(value)
    }


    // function debounce(func: any, timeout = 15000) {
    //     let timer: any;
    //     return (...args: any) => {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => { func.apply(self, args); }, timeout);
    //     };
    // }
    

    const validateData = () => {
        return Boolean(
            mainInfoData.title &&
            mainInfoData.inn &&
            mainInfoData.kpp &&
            mainInfoData.ogrn
        )
    }


    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(event)
        setMainInfoData({ ...mainInfoData, title: event?.target?.value })
        console.log(mainInfoData.title)
        getSuggestions(event.target.value)
    };

    const handleAutocompleteTitleChange = (title: string) => {
        setMainInfoData({ ...mainInfoData, title: title })
        getSuggestions(title)
    };
    const handleINNChange = (inn: string) => {
        setMainInfoData({ ...mainInfoData, inn: inn})
        getSuggestionsByID(inn)
    };
    const handleShortTitleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMainInfoData({ ...mainInfoData, short_title: event.target.value });
    };
    const handleinnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMainInfoData({ ...mainInfoData, inn: event.target.value });
    }
    const handleKPPChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMainInfoData({ ...mainInfoData, kpp: event.target.value });
    }
    const handleOGRNChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMainInfoData({ ...mainInfoData, ogrn: event.target.value });
    }
    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMainInfoData({ ...mainInfoData, status: event.target.value });
    }

    const debounceChangeTest = debounce(handleTitleChange, 300);

    return {
        tabValue,
        mainInfoData,
        inEditMode: !!id,
        handleTabChange,
        handleTitleChange,
        handleinnChange,
        handleKPPChange,
        handleOGRNChange,
        handleStatusChange,
        handleShortTitleChange,
        handleINNChange,
        createCompany,
        editCompany,
        selectedCompany,
        setSelectedCompany,
        debounceChangeTest,
        dadataCompanies,
        options,
        isValid,
        getSuggestionsByID,
        handleAutocompleteTitleChange
    }
}