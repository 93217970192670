import {$api} from "../api";


interface getServicesRequestData{
    page?:number,
    perPage?:number,
    sectionName?:string
} 

export const getServicesRequest=(data?:getServicesRequestData)=>{
    return  $api.get(`/v1/products/${data?.page ? `?page=${data?.page}` : ''}${data?.perPage ? `&per_page=${data.perPage}` : ''}`, {data})
}
