import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Tab, TextField, Typography } from "@mui/material";
import 'swiper/css/bundle';
import {useOrganisationAllInfoModel } from "./OrganisationAllInfoModel";
import { OrganisationsTabsLabels } from "./OrganisationAllInfo-types";
import { tabStyle } from "../../../../styles/MaterialCustomStyles";
import { OrganisationMainInfo } from "../OrganisationMainInfoModule/OrganisationMainInfo";
import { OrganisationAccountsInfo } from './../OrganisationAccountsInfoModule/OrganisationAccountsInfo';
import { OrganisationEmployees } from "../OrganisationEmployeesModule/OrganisationEmployees";
import { OrganisationSubdivisions } from "../OrganisationSubdivisionsModule/OrganisationSubdivisions";



export const OrganisationAllInfo=()=>{

    const organisationModel = useOrganisationAllInfoModel()
    
    return(
        <div style={{ minHeight: '100vh' }}>
            <Box sx={{ backgroundColor: "white", padding: '20px', position: 'relative' }}>
               <Typography
               sx={{
                fontSize:'30px',
                fontWeight:'500'
               }}
               component={'h2'}>{organisationModel.currentOrganisation?.short_title}</Typography>
            </Box>


            <TabContext value={organisationModel.tabValue}>
                <TabList 
                  sx={{
                    backgroundColor: 'white', '& .MuiTabs-indicator': {backgroundColor: '#009FDA'},
                    pointerEvents: organisationModel.currentOrganisation.id ? 'auto' : 'none',
                  }}
                  onChange={organisationModel.handleTabChange}>
                    <Tab sx={tabStyle} label={OrganisationsTabsLabels.MAIN_INFO} value={OrganisationsTabsLabels.MAIN_INFO} />
                    <Tab sx={tabStyle} label={OrganisationsTabsLabels.BANK_ACCS} value={OrganisationsTabsLabels.BANK_ACCS} />
                    <Tab sx={tabStyle} label={OrganisationsTabsLabels.EMPLOYEES} value={OrganisationsTabsLabels.EMPLOYEES} />
                    <Tab sx={tabStyle} label={OrganisationsTabsLabels.SUBDIVISIONS} value={OrganisationsTabsLabels.SUBDIVISIONS} />
                </TabList>

                <Box sx={{ backgroundColor: 'white', margin: '24px' }}>
                    <TabPanel sx={{ padding: 0 }} value={OrganisationsTabsLabels.MAIN_INFO}>
                      <OrganisationMainInfo/>
                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={OrganisationsTabsLabels.BANK_ACCS}>
                        <OrganisationAccountsInfo />
                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={OrganisationsTabsLabels.SUBDIVISIONS}>
                        <OrganisationSubdivisions 
                            tabValue={organisationModel.tabValue} 
                            setTab={organisationModel.setTab}
                        />
                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={OrganisationsTabsLabels.EMPLOYEES}>

                        <Box
                        >
                             <OrganisationEmployees 
                                tabValue={organisationModel.tabValue} 
                                setTab={organisationModel.setTab}
                            />
                        </Box>
                    </TabPanel>
                </Box>

            </TabContext>
        </div>
    )
}