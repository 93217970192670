import { useEffect, useState } from "react"
import { OrganisationsTabsLabels } from "../../../OrganisationAllInfoModule/OrganisationAllInfo-types"
import { Contact, Employee, Position, Product, Role, productWithRoles, productsOptions, rolesInputs, rolesOptions } from "../../OrganisationEmployees-types"
import { phoneMaskValidate } from "../../../../../../utils"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks/redux"
import { createEmployeeRequest, createPositionRequest, editEmployeeRequest, editSubdivisionRequest, getEmployeesRequest, getPositionsRequest, getProductsRequest, getRolesRequest } from "../../../../../../services/EmployeeService"
import { getSubdivisionsRequest } from "../../../../../../services/SubdivisionService"
import { statusType } from "../../../../Organisation-types"
import { Subdivision } from "../../../OrganisationSubdivisionsModule/OrganisationSubdivision-types"
import { clearEmployeeBuffer, setEmployeeBuffer, setEmployees } from "../../OrganisationEmployeesSlice"
import { setBearer, setSubdivisionBuffer } from "../../../OrganisationSubdivisionsModule/OrganisationSubdivisionsSlice"
import { execPath } from "process"
import { ContactType } from "../../OrganisationEmployees-enums"
import { $api } from "../../../../../../api"
import { getCompaniesRequest } from "../../../../../../services/CompanyService"



export interface EmployeeCardModelProps {
    modal: boolean
    setModal: (value: boolean) => void
    setTab: (tab: OrganisationsTabsLabels) => void
    editEmployeeData: Employee | null
}

export const useEmployeeCardModel = (props: EmployeeCardModelProps) => {

    const { id } = useParams();

    // const [role, setRole] = useState<string>('')
    // useEffect(()=>{
    //   $api.get(`/v1/user/me`).then((response)=>{setRole(response.data.role); console.log('ROLE =', response)})
    //   getCompaniesRequest().then((response)=>{setRole(response.data.role); console.log('ROLE =', response)})
    // },[])

    const [isNewPosition, setIsNewPosition] = useState<boolean>(false)

    const [typingTimer2, setTypingTimer2] = useState<any>()
    const typingListener2 = (inputValue: any, typingPause: number, callback: () => void) => {
        clearTimeout(typingTimer2)
        if (inputValue) {
            setTypingTimer2(setTimeout(callback, typingPause))
        }
    }

    const checkNewPosition = async (input: string) => {
      //if (role === 'ADMIN') {
        typingListener2(input, 1600, async () => {
          const responce: Position[] = await getPositions(input)
          if (responce) {
            setIsNewPosition(Boolean(
                !responce?.find((position) => (position.name?.toLowerCase() === input.toLowerCase()))
            ))
          }
        })
      //}
    }

    const handleAddPosition = async () => {
      const response = await createPositionRequest(Number(id), {name: positionInput})
      handleSetPositionId(response.data.id)
      setPositionInput(response.data.name)
    }

    const { 
        employeeBuffer,
    } = useAppSelector(state => state.emplReducer)
    const dispatch = useAppDispatch();

    const [tabValue, setTabValue] = useState<string>('MAIN')

    useEffect(() => {
        (async () => {

            if (employeeData?.positionId) {
                setSelectedPosition(await getPosition(employeeData?.positionId))
            } else {
                setSelectedPosition(undefined)
            }
            if (employeeData?.departmentId) {
                setSelectedSubdivision(await getSubdivision(employeeData?.departmentId))
            } else {
                setSelectedSubdivision(undefined)
            }
            if (subdivisionName) {
                setSelectedSubdivision(await getSubdivisions(subdivisionName))
            }

            if (employeeBuffer) {
                await getBufferData()
            }
        })()
    }, [])

    const [selectedPosition, setSelectedPosition] = useState<Position>()
    const [selectedSubdivision, setSelectedSubdivision] = useState<Subdivision>()
    const [subdivisionName, setSubdivisionName] = useState<string>()

    useEffect(() => {
        if (props.editEmployeeData) {
            (async () => {
                props.setModal(true)

                let employee = props.editEmployeeData
    
                employee = {...employee, positionId: employee?.position?.id}
                employee = {...employee, departmentId: employee?.department?.id}
        
                if (!employee?.roles) {
                    delete employee?.roles
                } else {
                    setEmployeeProducts(employee.roles)
                    setProductInputs(employee.roles.map((product:any)=>product.name))
                    setRoleInputs(employee.roles.map((product:any)=>({inputs: product.roles.map((role:any)=>role.name)})))
                    setProductOptions(employee.roles.map(()=>({})))
                    setRoleOptions(employee.roles.map(()=>({})))
                    let rolesId:number[] = []
                    employee.roles.forEach((product:any)=>{
                        product.roles.forEach((role:any) => {
                            if (role.id !== 0) {
                                rolesId = rolesId.concat(role.id)
                            }
                        })
                    })
                    employee = {...employee, roles: rolesId}
                }
                if (!employee?.departmentId) {
                    delete employee?.departmentId
                }
                if (!employee?.positionId) {
                    delete employee?.positionId
                }
        
        
                setFio((
                    (employee?.lastname || '') + (employee?.lastname ? ' ' : '') + 
                    (employee?.firstname || '') + (employee?.firstname ? ' ' : '') + 
                    (employee?.middlename || '')))
        
                if (employee?.position!) {
                    setSelectedPosition(employee?.position)
                } else {
                    setSelectedPosition(undefined)
                }
        
                if (employee?.department!) {
                    setSelectedSubdivision(employee?.department)
                } else {
                    setSelectedSubdivision(undefined)
                }
        
        
                delete employee.position
                delete employee.department
        
        
                employee.is_new = false
        
                console.log(employee)
                setEmployeeData(employee)
            })()
        }
    }, [props.editEmployeeData])

    


    const handleCloseModal = () => {
        // очистить все поля //                !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        setEmployeeData({
            contacts: [{type: ContactType.PHONE, use_to_notify: true, value: ''}],
            service_number: getServiceNumber(7),
            status: statusType.DRAFT
        })
        setFio('')
        setSelectedPosition(undefined)
        setSelectedSubdivision(undefined)
        setSubdivisionName(undefined)
        setEmployeeProducts([{product: {id: 0, name: ''}, roles: [{id: 0, name: ''}]}])
        setProductInputs([''])
        setProductOptions([{}])
        setRoleInputs([{inputs: ['']}])
        setRoleOptions([{}])
        setTabValue('MAIN')
        setSubdivisionParentId(0)
        setSugestEditSubdivision(false)

        setPositionInput('')
        setPositionOptions([])
        setSubdivisionInput('')
        setSubdivisionOptions([])
        setSubdivisionParentInput('')
        setSubdivisionParentOptions([])

        props.setModal(false)
    }


    const getEmployees = async () => {
        try {
            const response = await getEmployeesRequest(Number(id))
            console.log(response)
            dispatch(setEmployees(response.data.data))
        } catch(e) {
            console.error(e)
        }
    }

    const getPositions = async (search: string) => {
        try {
            if (search) {
                const response = await getPositionsRequest(Number(id), {search})
                console.log(response)
                return (response.data.data)
            }
        } catch(e) {
            console.error(e)
        }
    }

    const getPosition = async (id: number) => {
        try {
            if (id) {
                const response = await getPositionsRequest(Number(id), {id})
                console.log(response)
                return (response.data.data[0])
            }
        } catch(e) {
            console.error(e)
        }
    }


    const getSubdivisions = async (search: string) => {
        try {
            if (search) {
                const response = await getSubdivisionsRequest(Number(id), {search})
                console.log(response)
                return (response.data.data)
            }
        } catch(e) {
            console.error(e)
        }
    }

    const getSubdivision = async (id: number) => {
        try {
            if (id) {
                const response = await getSubdivisionsRequest(Number(id), {id})
                console.log(response)
                return (response.data.data[0])
            }
        } catch(e) {
            console.error(e)
        }
    }


    const getProducts = async (search: string) => {
        try {
            if (search) {
                const response = await getProductsRequest({search})
                console.log(response)
                return (response.data.data)
            }
        } catch(e) {
            console.error(e)
        }
    }

    const getRoles = async (search: string, productId: number,) => {
        try {
            if (search && productId) {
                const response = await getRolesRequest(Number(id), productId, employeeData?.positionId!, {search})
                console.log(response)
                return (response.data.data)
            }
        } catch(e) {
            console.error(e)
        }
    }






    const [isNewSubdivision, setIsNewSubdivision] = useState<boolean>(false)

    const [typingTimer, setTypingTimer] = useState<any>()

    const typingListener = (inputValue: any, typingPause: number, callback: () => void) => {
        clearTimeout(typingTimer)
        if (inputValue) {
            setTypingTimer(setTimeout(callback, typingPause))
        }
    }

    const checkNewSubdivision = async (input: string) => {
        typingListener(input, 1600, async () => {
            const responce: Subdivision[] = await getSubdivisions(input)
            if (responce) {
                setIsNewSubdivision(Boolean(
                    !responce?.find((subdivision) => (subdivision.name?.toLowerCase() === input.toLowerCase()))
                ))
            }
        })
    }


    // only for suggest user to edit subdivision
    const [subdivisionParentId, setSubdivisionParentId] = useState<number>(0)
    const [suggestEditSubdivision, setSugestEditSubdivision] = useState<boolean>(false)

    const handleSetSubdivisionParentId = (id: number) => {
        setSubdivisionParentId(id)
    }

    
    const handleAddSubdivision = () => {
        // set bearer for SubdivisionCard
        dispatch(setBearer(OrganisationsTabsLabels.EMPLOYEES))
        // set subdivision data
        dispatch(setSubdivisionBuffer({name: subdivisionInput}))
        // fullfilled buffer
        dispatch(setEmployeeBuffer({
            lastname: (employeeData?.lastname || '').trim(),
            firstname: (employeeData?.firstname || '').trim(),
            middlename: (employeeData?.middlename || '').trim(),
            service_number: employeeData?.service_number,
            positionId: employeeData?.positionId,
            departmentId: employeeData?.departmentId,
            contacts: employeeData?.contacts,
            status: employeeData?.status,
            roles: employeeData?.roles,

            productsBuffer: employeeProducts,

            departmentName:  subdivisionInput
        }))

        console.log(employeeBuffer)

        // go to SubdivisionCard's tab
        props.setTab(OrganisationsTabsLabels.SUBDIVISIONS)
    }

    const getBufferData = async () => {

        setFio((
            (employeeBuffer?.lastname || '') + (employeeBuffer?.lastname ? ' ' : '') + 
            (employeeBuffer?.firstname || '') + (employeeBuffer?.firstname ? ' ' : '') + 
            (employeeBuffer?.middlename || '')))

        setEmployeeProducts(employeeBuffer?.productsBuffer!)
        setSubdivisionName(employeeBuffer?.departmentName!)
        

        if (employeeBuffer?.positionId!) {
            const responce = await getPosition(employeeBuffer?.positionId!)
            setSelectedPosition(responce)
        } else {
            setSelectedPosition(undefined)
        }

        let subdivisionId
        if (employeeBuffer?.departmentName!) {
            const responce = await getSubdivisions(employeeBuffer?.departmentName!)
            setSelectedSubdivision(responce[0])

            //handleSetSubdivisionId(responce[0]?.id)
            subdivisionId = responce[0]?.id

            if (!responce[0]?.parent_id) {
                setSugestEditSubdivision(true)
            }
        }


        setEmployeeData({
            ...employeeData,
            lastname: employeeBuffer?.lastname,
            firstname: employeeBuffer?.firstname,
            middlename: employeeBuffer?.middlename,
            positionId: employeeBuffer?.positionId,
            contacts: employeeBuffer?.contacts,
            departmentId: subdivisionId,
            service_number: employeeBuffer?.service_number,
            status: employeeBuffer?.status,
            roles: employeeBuffer?.roles
        })


        dispatch(clearEmployeeBuffer()) 

        props.setModal(true)
    }



    const getEmployeeTableData = (employee: Employee) => {
        let newEmployee = employee


        // TODO: Взять данные из таблицы и преобразовать их в данные для карточки
        // delete employeeData
        //setEmployeeData(...employeeData, positionId: employeeData.position.id)
        // и т.п.

        setEmployeeData(newEmployee)
    }



    const getServiceNumber = (size: number) => {
        let max = 9, min = 0, result = ""
        for (let i = 0; i < size; i++) {
            result += (Math.floor(Math.random() * (max - min + 1) + min)).toString()
        }
        return result
    }



    const [employeeData, setEmployeeData] = useState<Employee | null>({
        contacts: [{type: ContactType.PHONE, use_to_notify: true, value: ''}],
        service_number: getServiceNumber(7),
        status: statusType.DRAFT
    })

    const [fio, setFio] = useState<string>('')

    const handleSetFIO = (value: string) => {
        value = value.replace(/\s\s+/g, ' ')
        value = value.charAt(0).toUpperCase() + value.slice(1)
        if (value[value.length-2] === ' ') {
            value = value.slice(0, value.length-1) + value.charAt(value.length-1).toUpperCase()
        }
        if(
            value.match(/^([^0-9]*)$/) && 
            value[0] !== ' ' &&
            value.split(" ").length-1 < 3
        ) {
            setFio(value)
            const resultArray = value.split(' ')
            console.log(resultArray)
            // handleSetLastName(resultArray[0] ?? '')
            // handleSetFirstName(resultArray[1] ?? '')
            // handleSetMiddleName(resultArray[2] ?? '')
            setEmployeeData({
              ...employeeData, 
              lastname: resultArray[0] ?? '',
              firstname: resultArray[1] ?? '',
              middlename: resultArray[2] ?? ''
          });
        }
    }

    const handleSetLastName = (lastName: string) => {
        if (lastName) {
            setEmployeeData({
                ...employeeData, lastname: lastName
            });
        } else {
            delete employeeData?.lastname
        }
    }

    const handleSetFirstName = (firstName: string) => {
        if (firstName) {
            setEmployeeData({
                ...employeeData, firstname: firstName
            });
        } else {
            delete employeeData?.firstname
        }
    }

    const handleSetMiddleName = (middleName: string) => {
        if (middleName) {
            setEmployeeData({
                ...employeeData, middlename: middleName
            });
        } else {
            delete employeeData?.middlename
        }
    }

    const handleSetPositionId = (positionId: number) => {
        if (positionId) {
            setEmployeeData({
                ...employeeData, positionId: positionId
            });
        } else {
            delete employeeData?.positionId
        }
    }

    const handleAddContact = () => {
        if (employeeData?.contacts?.length! < 4) {
            const newArr = employeeData?.contacts?.concat(
                {
                    type: (employeeData?.contacts.length % 2 === 0 ? ContactType.PHONE : ContactType.EMAIL), 
                    use_to_notify: false, 
                    value: ''
                }
            )
            setEmployeeData({
                ...employeeData, contacts: newArr
            });
        }
    }


    const handleSetContacts = (contacts: Contact[]) => {
        if (contacts) {
            setEmployeeData({
                ...employeeData, contacts: contacts
            });
        } else {
            delete employeeData?.contacts
        }
    }

    const [isNewContact, setIsNewContact] = useState<boolean>(false)
    const handleSetContactValue = (index: number, value: string) => {
        setIsNewContact(true)
        //temporary solution
        if (employeeData?.contacts![index].type === ContactType.PHONE) {
            value = (value === '+7' || value === '+7 ' || value === '') ? '' : 
                (value === '7' || value === '8') ? '+7 (' :
                    '+7 ' + (value.length === 1 ? '(' + value : '') + 
                    phoneMaskValidate(value.substring(3, value.length))
        } else 
        if (employeeData?.contacts![index].type === ContactType.EMAIL){
            console.log(value.match(/^[A-Za-z0-9!#$%&\'*+-/=?^_`{|}~]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+[A-Za-z]$/) ? true : false)
        }
        //console.log(value.replace(/[-()+ ]/g, ''))

        const newArr = [...employeeData?.contacts!]
        newArr[index] = {...newArr[index], value: value}

        //setContacts(newArr)
        setEmployeeData({
            ...employeeData, contacts: newArr
        });
    }

    const handleSetContactNotify = (index: number, use_to_notify: boolean) => {
        setIsNewContact(true)
        const newArr = [...employeeData?.contacts!]
        newArr.forEach((element, i) => {
            (i === index) ?
                newArr[i] = {...newArr[i], use_to_notify: true}
            :
                newArr[i] = {...newArr[i], use_to_notify: false}
        });
        
        //setContacts(newArr)
        setEmployeeData({
            ...employeeData, contacts: newArr
        });
    }

    const handleSetContactType = (index: number, type: ContactType) => {
        setIsNewContact(true)
        const newArr = [...employeeData?.contacts!]
        newArr[index] = {...newArr[index], type: type, value: ''}

        //setContacts(newArr)
        setEmployeeData({
            ...employeeData, contacts: newArr
        });
    }


    const handleSetSubdivisionId = (subdivisionId: number) => {
        if (subdivisionId) {
            setEmployeeData({
                ...employeeData, departmentId: subdivisionId
            });
        } else {
            delete employeeData?.departmentId
        }
    }


    const handleSetServiceNumber = (serviceNumber: string) => {
        if (serviceNumber) {
            setEmployeeData({
                ...employeeData, service_number: serviceNumber
            });
        } else {
            delete employeeData?.service_number
        }
    };

    const handleSetStatus = (status: string) => {
        if (status) {
            setEmployeeData({
                ...employeeData, status: status
            });
        } else {
            delete employeeData?.status
        }
    };

    const handleSetRoles = (roles: number[]) => {
        if (roles?.length) {
            setEmployeeData({
                ...employeeData, roles: roles
            });
        } else {
            delete employeeData?.roles
        }
    }


    const [employeeProducts, setEmployeeProducts] = useState<productWithRoles[]>(
        [{
            product: {id: 0, name: ''}, 
            roles: [{id: 0, name: ''}],
        }]
    )
    const [productInputs, setProductInputs] = useState<string[]>([''])
    const [productOptions, setProductOptions] = useState<productsOptions[]>([{}])
    const [roleInputs, setRoleInputs] = useState<rolesInputs[]>([{inputs: ['']}])
    const [roleOptions, setRoleOptions] = useState<rolesOptions[]>([{}])

    const handleAddEmployeeProduct = () => {
        setEmployeeProducts([...employeeProducts, {
            product: {id: 0, name: ''}, 
            roles: [{id: 0, name: ''}],
        }])

        setProductInputs([...productInputs, ''])
        setProductOptions([...productOptions, {}])
        setRoleInputs([...roleInputs, {inputs: ['']}])
        setRoleOptions([...roleOptions, {}])
    }

    const handleAddProductRole = (index: number) => {
        const newArr = [...employeeProducts]
        newArr[index].roles = newArr[index].roles.concat({id: 0, name: ''})
        setEmployeeProducts(newArr)
    }

    const handleSetEmployeeProduct = (index: number, product: Product) => {
        let newArr = [...employeeProducts]
        
        let employeeProduct = {...newArr[index]}
        let emplProduct = {...employeeProduct.product}
        emplProduct = {id:product.id,name:product.name}

        employeeProduct.product = emplProduct
        newArr[index] = employeeProduct

        setEmployeeProducts([...newArr])
    }

    const handleSetProductRole = (pIndex: number, rIndex: number, role: Role) => {
        let newArr = [...employeeProducts]

        let employeeProduct = {...newArr[pIndex]}
        let employeeRoles = [...employeeProduct.roles]
        let employeeRole = {...employeeRoles[rIndex]}
        employeeRole = {id:role.id,name:role.name}

        employeeRoles[rIndex] = employeeRole
        employeeProduct.roles = employeeRoles
        newArr[pIndex] = employeeProduct
        
        setEmployeeProducts([...newArr])
    }

    const handleSetProductInput = (index: number, input: string) => {
        const newArr = [...productInputs]
        newArr[index] = input
        setProductInputs(newArr)
    }

    const handleSetRoleInput = (pIndex: number, rIndex: number, input: string) => {
        const newArr = [...roleInputs]
        newArr[pIndex].inputs[rIndex] = input
        setRoleInputs(newArr)
    }

    const handleSetProductOptions = (index: number, options: Product[]) => {
        const newArr = [...productOptions]
        newArr[index].options = options
        setProductOptions(newArr)
    }

    const handleSetRoleOptions = (index: number, options: Role[]) => {
        const newArr = [...roleOptions]
        newArr[index].options = options
        setRoleOptions(newArr)
    }



    // Алгоритм для перебора всех ролей и взятия их id (если id !== 0)
    // +
    // useState + алгоритм для создания опций для всех продуктов и ролей по input search



    useEffect(() => {
        (async () => {
            productInputs.forEach(async (input, index) => {
                if (input) {
                    const responce: Product[] = await getProducts(input || '')
                    handleSetProductOptions(index, responce)
                    console.log('products')
                    console.log(responce)
                }
            })
        })()
    }, [
        productInputs
    ])

    useEffect(() => {
        (async () => {
            let newRoles: number[] = []
            employeeProducts.forEach(async (product, pIndex) => {
                roleInputs[pIndex].inputs.forEach(async (input, rIndex) => {
                    if (input) {
                    const responce: Product[] = await getRoles(input || '', product.product.id)
                    handleSetRoleOptions(pIndex, responce)
                    console.log('roles')
                    console.log(responce)
                }})
                product.roles.forEach((role, index) => {
                    if (role.id !== 0) {
                        newRoles = newRoles.concat(role.id)
                    }
                })
            })
            handleSetRoles(newRoles)
        })()
    }, [
        roleInputs, employeeData?.departmentId, employeeData?.positionId
    ])

    




    const [positionInput, setPositionInput] = useState<string>('')
    const [openPositionSuggestions, setOpenPositionSuggestions] = useState<boolean>(false)
    const [positionOptions, setPositionOptions] = useState<Position[]>([])

    useEffect(() => {
        (async () => {
            if (positionInput) {
                setPositionOptions(await getPositions(positionInput))
            } else {
                setPositionOptions([])
            }
        })()
    }, [positionInput])

    const [subdivisionInput, setSubdivisionInput] = useState<string>('')
    const [openSubdivisionSuggestions, setOpenSubdivisionSuggestions] = useState<boolean>(false)
    const [subdivisionOptions, setSubdivisionOptions] = useState<Subdivision[]>([])

    useEffect(() => {
        (async () => {
            if (subdivisionInput) {
                setSubdivisionOptions(await getSubdivisions(subdivisionInput))
            } else {
                setSubdivisionOptions([])
            }
            //checkNewSubdivision(subdivisionInput)
        })()
    }, [subdivisionInput])

    const [subdivisionParentInput, setSubdivisionParentInput] = useState<string>('')
    const [openSubdivisionParentSuggestions, setOpenSubdivisionParentSuggestions] = useState<boolean>(false)
    const [subdivisionParentOptions, setSubdivisionParentOptions] = useState<Subdivision[]>([])

    useEffect(() => {
        (async () => {
            setSubdivisionParentOptions(await getSubdivisions(subdivisionParentInput))
        })()
    }, [subdivisionParentInput])





    const validateEmployeeData = () => {
        return Boolean(
            employeeData?.lastname && 
            employeeData?.firstname && 
            employeeData?.middlename && 
            //positionId && 
            employeeData?.contacts && 
            !employeeData?.contacts.find(contact => {
                if (contact.type === ContactType.PHONE) {
                    return contact.value.length < 18
                } else 
                if (contact.type === ContactType.EMAIL) {
                    return !contact.value
                        .match(/^[A-Za-z0-9!#$%&\'*+-/=?^_`{|}~]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+[A-Za-z]$/) 
                        ? true : false
                }
            }) &&
            //subdivisionId &&
            employeeData?.service_number &&
            //!roles.find(role => (
            //    role === 0
            //)) &&
            employeeData?.status
        )
    }


    const [isRequestSucceed, setRequestSucceed] = useState<boolean>(false)

    const handleSaveEmployee = async () => {
        try {

            console.log('ZALUPA')
            console.log(employeeData)

            // temporary solution 
            if ((employeeData?.contacts) && (!isNewContact)) {
                delete employeeData?.contacts
            }
            if (!employeeData?.roles) {
                delete employeeData?.roles
            }
            if(!employeeData?.products) {
                delete employeeData?.products
            }
            if (!employeeData?.departmentId) {
                delete employeeData?.departmentId
            }
            if (!employeeData?.positionId) {
                delete employeeData?.positionId
            }

            // if (employeeData?.departmentId) {
            //     console.log('ggggggggggggggggggggggggggggggggggggggggg')
            //     setEmployeeData({
            //         ...employeeData,
            //         status: statusType.CONFIRMED
            //     })
            // }

            console.log(employeeData)
            console.log(selectedSubdivision)

            let response
            if (employeeData?.id) {
                response = await editEmployeeRequest(Number(id), employeeData)
            }
            else {
                response = await createEmployeeRequest(Number(id), employeeData!)
            }
            if (employeeData?.departmentId) {
                if (subdivisionParentId) {
                    console.log('subdivisionParentId ON')
                    const rsp = await editSubdivisionRequest(Number(id), 
                        {id: employeeData?.departmentId, parent_id: subdivisionParentId, status: statusType.CONFIRMED, is_new: false}
                    )
                    console.log(rsp)
                } else {
                    console.log('subdivisionParentId OFF')
                    const rsp = await editSubdivisionRequest(Number(id), 
                        {id: employeeData?.departmentId, status: statusType.CONFIRMED, is_new: false}
                    )
                    console.log(rsp)
                }
            }
            console.log(response)

            handleCloseModal()
            setRequestSucceed(true)
            setTimeout(() => {
                setRequestSucceed(false)
            }, 1200)

            getEmployees() // Вынести в главную модель.
        } catch(e) {
            console.error(e)
        }
    }



    useEffect(() => {
        console.log('employeeData')
        console.log(employeeData)
    }, [employeeData])

    useEffect(() => {
        console.log('employeeBuffer')
        console.log(employeeBuffer)
    }, [employeeBuffer])



    return {
        selectedPosition,
        selectedSubdivision,

        handleCloseModal,

        tabValue,
        setTabValue,

        isNewSubdivision,
        setIsNewSubdivision,

        employeeData,
        fio,
        handleSetFIO,
        
        handleSetPositionId,
        getPosition,

        handleAddContact,
        handleSetContactNotify,
        handleSetContactValue,
        handleSetContactType,

        handleSetSubdivisionId,
        getSubdivision,

        checkNewSubdivision,
        suggestEditSubdivision,

        handleSetSubdivisionParentId, setSubdivisionParentId,

        employeeProducts,
        handleAddEmployeeProduct,
        handleAddProductRole,
        handleSetEmployeeProduct,
        handleSetProductRole,
        //handleSetEmployeeProductInput,
        //handleSetRoleInput,
        productInputs,
        roleInputs,
        productOptions,
        roleOptions,
        handleSetProductInput,
        handleSetRoleInput,

        positionInput,
        setPositionInput,
        positionOptions,

        subdivisionInput,
        setSubdivisionInput,
        subdivisionOptions,

        subdivisionParentInput,
        setSubdivisionParentInput,
        subdivisionParentOptions,

        handleSaveEmployee,
        validateEmployeeData,

        handleAddSubdivision,
        subdivisionName,

        isRequestSucceed,

        checkNewPosition,
        isNewPosition,
        setIsNewPosition,
        handleAddPosition,
    }
}