import {PayloadAction, createSlice} from "@reduxjs/toolkit"
import { Subdivision, SubdivisionBuffer } from "./OrganisationSubdivision-types"
import { OrganisationsTabsLabels } from "../OrganisationAllInfoModule/OrganisationAllInfo-types"


interface SubdivisionState {
    subdivisions: Subdivision[]
    //subdivisionData: Subdivision | null
    bearer: OrganisationsTabsLabels | null

    subdivisionBuffer: SubdivisionBuffer
}

const initialState: SubdivisionState = {
    subdivisions: [],
    //subdivisionData: null,
    bearer: null,

    subdivisionBuffer: {
        name: '',
        status: '',
        parent_id: 0,
    },
}


export const subdivSlice = createSlice({
    name:'subdiv',
    initialState,
    reducers:{
        setSubdivisions(state, action:PayloadAction<Subdivision[]>) {
            state.subdivisions = action.payload
        },
        clearSubdivisions(state) {
            state.subdivisions = []
        },
        // setSubdivisionData(state, action:PayloadAction<Subdivision>) {
        //     state.subdivisionData = action.payload
        // },
        // clearSubdivisionData(state) {
        //     state.subdivisionData = null
        // },
        setBearer(state, action:PayloadAction<OrganisationsTabsLabels>) {
            state.bearer = action.payload
        },
        clearBearer(state) {
            state.bearer = null
        },


        setSubdivisionBuffer(state, action:PayloadAction<SubdivisionBuffer>) {
            state.subdivisionBuffer = action.payload
        },
        clearSubdivisionBuffer(state) {
            state.subdivisionBuffer = {
                name: '',
                status: '',
                parent_id: 0,
            }
        },

    },
})

export const {
    setSubdivisions,
    clearSubdivisions,
    // setSubdivisionData,
    // clearSubdivisionData,
    setBearer,
    clearBearer,
    
    setSubdivisionBuffer,
    clearSubdivisionBuffer,

} = subdivSlice.actions

export default subdivSlice.reducer