import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createEmployeeRequest, editEmployeeRequest, editSubdivisionRequest, getEmployeeRequest, getPositionsRequest, getProductsRequest, getRolesRequest } from "../../../../services/EmployeeService";
import { getSubdivisionsRequest } from "../../../../services/SubdivisionService";
import { clearEmployeeBuffer, clearEmployees, setEmployeeBuffer, setEmployeeData, setEmployees } from "./OrganisationEmployeesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux";
import { Contact,  Employee, Position, Product, Role, productWithRoles,  } from "./OrganisationEmployees-types";
import { usePasswordInpModel } from "../../../../components/passwordInput/passwordInputModel";
import { statusType } from "../../Organisation-types";
import { OrganisationsTabsLabels } from "../OrganisationAllInfoModule/OrganisationAllInfo-types";

import { property } from "lodash";
import { phoneMaskValidate } from "../../../../utils";
import { ContactType } from "./OrganisationEmployees-enums";

export const useEmployeesModel = () => {

    const { id } = useParams();


    const dispatch = useAppDispatch();
    const {employees, employeeData} = useAppSelector(state=>state.emplReducer) 

    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }


    const handleSetEditEmployeeData = async (employee: Employee) => {

        // employee = {...employee, positionId: employee.position?.id}
        // employee = {...employee, departmentId: employee.department?.id}
        // delete employee.position
        // delete employee.department

        const response = await getEmployeeRequest(Number(id), employee.id)

        const products = response.data.roles.map((role:any)=>(role.product)).filter((elem:any, index:any, self:any)=>(index === self.indexOf(elem)))
        const employeeProducts:productWithRoles[] = products.map((product:any)=>( 
            {
                product: product,
                roles: response.data.roles.filter((role:any)=>(role.product.id===product.id)).map((role:any)=>({id: role.id, name: role.name}))
            }
        ))
        let contacts = employee.contacts
        if (employee.contacts) {
            const newArr = [...employee.contacts].map((contact) => contact?.type === ContactType.PHONE ? {...contact, value: '+' + contact.value.substring(0,1) + 
            ' (' + contact.value.substring(1,4) + ') ' + contact.value.substring(4,7) + '-' + contact.value.substring(7,9) + '-' + contact.value.substring(9)}
            : contact)
            contacts = newArr
        }
        setEditEmployeeData({...employee, contacts: contacts, roles: employeeProducts})
        dispatch(setEmployeeData({...employee, contacts: contacts, roles: employeeProducts}))
    }

    useEffect(()=>{

      dispatch(setEmployeeData(employees.find((item:Employee)=> item.id == employeeData?.id)))  
    }, [employees])


    const [editEmployeeData, setEditEmployeeData] = useState<Employee | null>(null)
    
    useEffect(()=>{
        if(!isModalOpen){
            setEditEmployeeData(null)
            dispatch(setEmployeeData(undefined))
        } 
    },[isModalOpen])



    return {
        isModalOpen,
        setModalOpen,
        handleOpenModal,

        handleSetEditEmployeeData,
        editEmployeeData,
    }
}