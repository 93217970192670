import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSubdivisionsRequest } from "../../../../../../services/SubdivisionService";
import { useAppSelector, useAppDispatch } from "../../../../../../store/hooks/redux";
import { clearSubdivisions, setSubdivisions } from "../../OrganisationSubdivisionsSlice";

export const useSubdivisionsTableModel = () => {

    const { id } = useParams();

    const { subdivisions} = useAppSelector(state => state.subdivReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(id) {
            getSubdivisions()
        } else {
            dispatch(clearSubdivisions())
        }
    }, [setSubdivisions])

    const getSubdivisions = async () => {
        try {
            const response = await getSubdivisionsRequest(Number(id))
            console.log(response)
            dispatch(setSubdivisions(response.data.data))
        } catch(e) {
            console.error(e)
        }
    }

    return {
        subdivisions
    }
}