import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Card from "@mui/material/Card";
import Toolbar from '@mui/material/Toolbar';
import { CardContent } from "@mui/material";
import { ServiceMiniCardItem } from "../../components/RecomendedServiceItem/RecomendedServiceItem";
import { useEffect, useState } from "react";
import { ServiceDataInt } from "../../services-types";


interface ServiceCardProps{
    data?: ServiceDataInt
    isOpen:boolean,
    onClose():void
}

export const ServiceCard = ({isOpen, onClose, data}:ServiceCardProps) => {

    useEffect(()=>{
        console.log(isOpen)
    }, [isOpen])


if(!isOpen){
    return null
} else  return (
        <Card
            sx={{
                maxWidth: 392, width: '100%', height: '620px', overflowY:'visible', marginLeft: '30px', marginTop: '30px',
                backgroundColor: '#EEEEEE', cursor: 'pointer', position:'fixed', zIndex:10,
              top:40,
              right:25,
            }}
        >
            <CardContent>
                <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' , alignItems:'center'}}>
                    <Typography variant="h5"  sx={{ fontSize: 20, fontWeight: 500 }}>Медицина</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box>
                    <Swiper
                        // @ts-ignore 
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        modules={[Navigation, Pagination]}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '266px' }}>Slide 1</div></SwiperSlide>
                        <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '266px' }}>Slide 2</div></SwiperSlide>
                        <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '266px' }}>Slide 3</div></SwiperSlide>
                        <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '266px' }}>Slide 4</div></SwiperSlide>
                    </Swiper>
                </Box>
                <Box sx={{ marginTop: '16px' }}>
                    <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 700 }} component="div">
                        {data?.name ? data.name : 'Название сервисов'}
                       
                    </Typography>
                    <Typography variant='caption' sx={{ fontSize: 12, fontWeight: '700' }} color="text.secondary">
                        Разработчик: {data?.developer ? data.developer : 'разработчик' }
                     
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Rating name="read-only" value={Number(data?.rating)} readOnly />
                        <Typography >
                            {data?.rating}
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: '16px' }}>
                        <Button sx={{
                            backgroundColor: 'white',
                            border: '2px solid #1890FF',
                            color: '#1890FF',
                            fontWeight: '600',
                            fontSize: '14px'
                        }} fullWidth={true} variant='outlined' size="small">{data?.price} Р</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: '16px' }}>
                    <Typography variant="subtitle1" lineHeight={'18px'}>
                      {data?.description ? data.description : '  С помощью этого виджета новые заявки будут быстро и автоматически распределены по менеджерам, учитывая график работы и загруженность каждого сотрудника.'} 
                    </Typography>
                    <Typography sx={{ marginTop: '7px', color: '#009FDA' }}>Подробнее</Typography>
                </Box>
                <Accordion
                    sx={{
                        backgroundColor: '#EEEEEE',
                        border: 'none',
                        boxShadow: 'none',
                        '&::before': {
                            display: 'none'
                        }
                    }
                    }
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{padding:'0px'}}
                    >
                        <Typography sx={{fontSize:'16px', fontWeight:600}}>Вам так же могут понравиться</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Swiper
                            // @ts-ignore 
                            slidesPerView={1.5}
                        >
                            <SwiperSlide>
                                <ServiceMiniCardItem />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ServiceMiniCardItem />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ServiceMiniCardItem />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ServiceMiniCardItem />
                            </SwiperSlide>
                        </Swiper>

                    </AccordionDetails>
                </Accordion>
            </CardContent>


        </Card>
    )
}