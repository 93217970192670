import { useEffect } from 'react';
import { useState } from 'react';
import { getBanksRequest } from '../../../../../../services/BankAccountService';
import {
    AccData,
    BankData,
    EditQueryData,
    NewAccData,
    statuses,
    statusLabels,
} from '../../OrganisationAccounts-types';
import { alpha } from '@mui/material';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useAppDispatch } from '../../../../../../store/hooks/redux';

export const useBankAccountCard = (
    onSubmit: (newAccount: NewAccData) => Promise<void>,
    selectedAcc: AccData | null,
    onEdit: (newData: EditQueryData) => Promise<void>
) => {
    const [banks, setBanks] = useState<BankData[]>([]);
    const [selectedBank, setSelectedBank] = useState<string | undefined>(
        // selectedAcc ? selectedAcc.name :
        undefined
    );
    const [userInput, setUserInput] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string>(
        // selectedAcc ? selectedAcc.number :
        ''
    );
    const [status, setStatus] = useState<string>(
        // selectedAcc ? selectedAcc.status :
        ''
    );

    const accStyle = {
        width: 314,
        color: '#8E8E8E',
        borderRadius: '4px',
        '& .MuiFilledInput-root': {
            height: '67px',
            overflow: 'hidden',
            borderRadius: '4px',
            backgroundColor: 'white',
            border: '1px solid #8E8E8E',
            color: '#000',
            fontSize: '16px',
            fontWeight: '400',
            transition: 'box-shadow linear 0.1s, border 0.1s ease-out',

            '& .MuiAutocomplete-popupIndicator': {
                color: '#787878',
                transform: 'rotate(-90deg)',
            },

            '&:hover': {
                boxShadow: `${alpha('#009FDA', 1)} 0 0 0 1px`,
                borderColor: '#009FDA',
                backgroundColor: 'white',
            },
            '&.Mui-focused': {
                boxShadow: `${alpha('#009FDA', 1)} 0 0 0 1px`,
                borderColor: '#009FDA',
                backgroundColor: 'white',
                '& input': {
                    transform: 'translate(0px, -9px)',
                    transition: '0.1s',
                },
                '& .MuiAutocomplete-popupIndicatorOpen': {
                    color: '#00000040',
                    transform: 'rotate(-180deg) scale(0.85)',
                },
            },
            '& input::placeholder': {
                backgroundColor: 'none',
                color: '#8e8e8e',
                opacity: 1,
            },
        },
        '& .MuiFormLabel-root': {
            color: '#8e8e8e',
            opacity: 1,
            marginLeft: '1px',
            '&.Mui-focused': {
                display: 'none',
            },
        },
        '& .Mui-focused': {
            backgroundColor: '#E6F7FF',
        },
        '& :not(focused)': {
            transition: 'all 0.1s ease-out',
        },
    };

    const numberStyle = {
        width: 314,
        height: '67px',
        color: '#8E8E8E',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        '& :disabled': {
            backgroundColor: '#ffffff',
            '& :hover': {
                backgroundColor: '#ffffff',
            },
        },
        '& .MuiFilledInput-root': {
            color: '#000',
            border: '1px solid #8E8E8E',
            borderRadius: '4px',
            backgroundColor: 'white',
            height: '67px',
            fontSize: '16px',
            fontWeight: '400',
            transition: 'box-shadow linear 0.1s, border 0.1s ease-out',

            '&:hover': {
                boxShadow: `${alpha('#009FDA', 1)} 0 0 0 1px`,
                borderColor: '#009FDA',
                backgroundColor: 'white',
            },
            '&.Mui-focused': {
                boxShadow: `${alpha('#009FDA', 1)} 0 0 0 1px`,
                borderColor: '#009FDA',
                backgroundColor: 'white',
                color: '#000',
                '& input': {
                    transform: 'translate(0px, -9px)',
                    transition: '0.1s',
                },
            },
            '& input::placeholder': {
                backgroundColor: 'none',
                color: '#8e8e8e',
                opacity: 1,
            },
            '&.Mui-disabled:hover': {
                boxShadow: 'none',
                borderColor: '#8E8E8E',
            },
        },

        '& .MuiFilledInput-root.Mui-disabled': {
            backgroundColor: '#ffffff',

            '& :hover': {
                backgroundColor: '#ffffff',
            },
        },

        '& .MuiFormLabel-root': {
            color: '#8e8e8e',
            opacity: 1,
            marginLeft: '1px',
            '&.Mui-focused': {
                display: 'none',
            },
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: '#8e8e8e',
            opacity: 1,
            marginLeft: '1px',
            '&.Mui-focused': {
                display: 'none',
            },
        },
        '& .MuiInputBase-input': {
            color: '#000',
            '&::placeholder': {
                color: '#8e8e8e',
            },
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#000',
            '&::placeholder': {
                color: '#8e8e8e',
                '-webkit-text-fill-color': 'initial',
            },
        },
        '& :not(focused)': {
            transition: 'all 0.1s ease-out',
        },
    };

    const statuses = [
        { name: 'Действует', value: 'CONFIRMED' },
        { name: 'Черновик', value: 'DRAFT' },
        { name: 'Удален', value: 'DELETED' },
    ];

    const statusStyle = {
        ...numberStyle,
        ...accStyle,
        '& .Mui-disabled:hover': {
            boxShadow: 'none',
            borderColor: '#8E8E8E',
        },
        '& :not(focused)': {
            transition: 'all 0.1s ease-out',
        },
    };

    const CustomButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'inherit',
        color: '#ffffff',
        padding: '5px 16px',
        fontSize: '20px',
        fontWeight: 600,
        height: '48px',
        backgroundColor: '#009FDA',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#00739C',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:disabled': {
            boxShadow: 'none',
            color: '#FFFFFF',
            backgroundColor: '#8E8E8E',
        },
    });

    useEffect(() => {
        console.log('selectedBank: ' + selectedBank);
    }, [selectedBank]);

    // useEffect(() => {
    //     console.log(selectedAcc);
    //     console.log(selectedBank);
    //     console.log(accountNumber);
    //     console.log(status);
    // }, [selectedAcc]);

    // useEffect(() => {
    //     console.log(selectedBank);
    //     console.log(accountNumber);
    //     console.log(status);
    // }, [selectedBank, accountNumber, status]);

    useEffect(() => {
        fetchBanks(userInput);
    }, [userInput]);

    const fetchBanks = async (bankName: string) => {
        try {
            const response = await getBanksRequest(bankName);
            setBanks(response.data.suggestions);
        } catch (error) {
            console.error('Error fetching banks: ', error);
        }
    };

    // const diffKeys = (o1: AccData, o2: EditQueryData) => {
    //     if (o1.name !== selectedBank) {
    //         o2.data.name = selectedBank;
    //         o2.data.bank_name = selectedBank;
    //     }
    //     if (o1.number !== accountNumber) {
    //         o2.data.number = accountNumber;
    //     }
    //     if (o1.status !== status) {
    //         o2.data.status = status;
    //     }
    //     return o2;
    // };

    const handleSubmit = () => {
        if (selectedBank && accountNumber && status) {
            // if (selectedAcc) {
            //     const newData: EditQueryData = {
            //         companyId: selectedAcc.companyId,
            //         id: selectedAcc.id,
            //         data: {},
            //     };
            //     newData.data = diffKeys(selectedAcc, newData).data;

            //     console.log(newData);
            //     onEdit(newData);
            // } else {
            const newAccount: NewAccData = {
                number: accountNumber,
                bank_name: selectedBank,
                name: selectedBank,
                status: status,
                is_main: false,
            };
            console.log(newAccount);
            onSubmit(newAccount);
            // }
        }
    };

    return {
        banks,
        selectedAcc,
        selectedBank,
        setSelectedBank,
        userInput,
        setUserInput,
        accountNumber,
        setAccountNumber,
        status,
        setStatus,
        accStyle,
        numberStyle,
        statusStyle,
        statuses,
        CustomButton,
        handleSubmit,
    };
};
