import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { tableHeaderText } from '../../../../styles/MaterialCustomStyles';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';


const getStatusStyle = (status: string) => {
    const statusStyle = {
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'lowercase',
        border: '1px solid',
        borderRadius: '2px',
        padding: '1px 8px',
        width: 'max-content',
        display: 'inline-block',
    };

    const confirmedStyle = {
        ...statusStyle,
        color: '#52C41A',
        borderColor: '#B7EB8F',
        backgroundColor: '#F6FFED',
    };

    const draftStyle = {
        ...statusStyle,
        color: '#2F54EB',
        borderColor: '#ADC6FF',
        backgroundColor: '#F0F5FF',
    };

    const deletedtStyle = {
        ...statusStyle,
        color: '#F5222D',
        borderColor: '#FFA39E',
        backgroundColor: '#FFF1F0',
    };

    switch (status) {
        case 'CONFIRMED':
            return <Box sx={confirmedStyle}>Подтвержден</Box>
        case 'DRAFT':
            return <Box sx={draftStyle}>Черновик</Box>
        case 'DELETED':
            return <Box sx={deletedtStyle}>Удален</Box>
    }
}

export const OrganisationsTable = (props: any) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ maxWidth: 650, width: '100%' }} aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#FAFAFA',
                        }}
                    >
                        <TableCell
                            sx={
                                tableHeaderText
                            }
                        >
                            <Box fontWeight={'fontWeightMedium'}>
                                Название
                            </Box>

                            {/* <Typography sx={{fontWeight:'600',}}>Название</Typography> */}
                        </TableCell>
                        <TableCell
                            sx={
                                tableHeaderText
                            }
                            align="right">Статус</TableCell>
                        <TableCell
                            sx={
                                tableHeaderText
                            }
                            align="right">Действие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.organisations.map((row: any) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.short_title}
                            </TableCell>
                            <TableCell align="right">{getStatusStyle(row.status)}</TableCell>
                            <TableCell onClick={() => {
                                
                            }} align="right">
                                <Link to={`/organisations/editInfo/${row.id}`}>Подробнее</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}