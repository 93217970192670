export const linkStyle = {
    fontSize: "14px",
    fontWeight: '400',
    color: '#009FDA',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline'
    }
}
export const inlineBox = {
    marginTop: '23px',
    marginBottom: '23px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
}
export const authTabStyle = {
    textTransform: 'none',
    color: 'white',
    fontSize: 16,
    fontWeight: 700,

}

// const inlineBox={
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '100%',
//     marginTop: '14px',
//     marginBottom: '26px'
// };

export const textFieldStyle = {
    "&.MuiTextField-root": {
        padding: 0,
        borderRadius: '4px',
        // backgroundColor: model.isErrorHidden ? 'white' : '#FFBBBB',
        // border: model.isErrorHidden ? 'none' : '2px solid red',
        width: 360,
        height: 67,
    }
}

export const tabPanelStyle = {
    padding: '24px 0px 24px 0px',
}

export const buttonStyle={
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
    height: '48px',
    backgroundColor: '#1890FF'
}
export const registrationButtonStyle ={
    borderWidth: 2,
    borderColor: '#1890FF',
    color: 'white',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 700,
    height: 48
} 