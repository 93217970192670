import { $api } from "../api"
import { Subdivision } from "../pages/Organisations/modules/OrganisationSubdivisionsModule/OrganisationSubdivision-types"


export interface getSubdivisionsRequestParams {
    id?: number,
    search?: string, 
    per_page?: number, 
    page?: number
}

export const getSubdivisionsRequest=(
    companyId: number, 
    params?: getSubdivisionsRequestParams
    ) => {
    return $api.get(`/v1/user/company/${companyId}/department`, {params})
}


export const createSubdivisionRequest=(companyId: number, data: Subdivision) => {
    return $api.post(`/v1/user/company/${companyId}/department`, data)
}

export const editSubdivisionRequest=(companyId: number, data: Subdivision) => {
    return $api.patch(`/v1/user/company/${companyId}/department/${data.id}`, data)
}