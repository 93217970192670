import React from 'react';
import {Auth} from "./modules/Auth/Auth";
import Logo from "../../assets/Logo.png"
import {useLoginModel} from "./LoginModel";
import Registration from "./modules/Registration/Registration";
import Recovery from "./modules/Recovery/Recovery";




function LoginPage() {

const loginModel = useLoginModel()

  return (
    <div className="login" style={
        {
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            background: `rgba(30, 30, 30, 0.95)`,
            minHeight:`100vh`,
            paddingTop:'139px',
            color:'white',
        }}>

        <img style={{ width:"fit-content", marginBottom:'75px'}} src={Logo} alt=""/>

        {
            (loginModel.isNeedToAuth)
            ? <Auth fullFilledData = {
                loginModel.fullfilledData} goToReg={loginModel.renderReg} goToPasRec = {loginModel.renderPasRec}/>
                : loginModel.isNeedToReg
                    ? <Registration inviteCode = {loginModel.inviteCode} renderAuth={loginModel.setAuthOnRegistrationSuccess}/>
                    : loginModel.isNeedToPasRecovery && <Recovery renderAuth={loginModel.setAuthOnRecoverySuccess}/>
        }
    </div>
  );
}

export default LoginPage;
