import { Box } from '@mui/material';
import React from 'react'
import { useSubdivisionsModel } from './OrganisationSubdivisionsModel';
import { SubdivisionToolBar } from './components/SubdivisionsToolBar';
import { SubdivisionCard } from './modules/SubdivisionCard/SubdivisionCard';
import { OrganisationsTabsLabels } from '../OrganisationAllInfoModule/OrganisationAllInfo-types';
import { SucceedWindow } from '../../components/SucceedWindow/SucceedWindow';
import { SubdivisionsTable } from './modules/SubdivisionsTable/SubdivisionsTable';

export const OrganisationSubdivisions = (OrganisationSubdivisionsProps: {
    tabValue: OrganisationsTabsLabels
    setTab: (tab: OrganisationsTabsLabels) => void
}) => {

    const subdivisionsModel = useSubdivisionsModel({
        tabValue: OrganisationSubdivisionsProps.tabValue,
        setTab: OrganisationSubdivisionsProps.setTab
    });

    return (
        <Box 
            sx={{
                position: 'relative',
                minHeight:'80vh',
            }}>
            
            {/* <SucceedWindow open={subdivisionsModel.isRequestSucceed} /> */}
            
            <SubdivisionToolBar 
                handleOpenModal={subdivisionsModel.handleOpenModal} 
                handleSetHierarchy={subdivisionsModel.handleSetHierarchy}
            />

            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '24px',
                }}>


                <SubdivisionsTable 
                    hierarchy={subdivisionsModel.hierarchy} 
                />

                <SubdivisionCard 
                    modal={subdivisionsModel.isModalOpen}
                    setModal={subdivisionsModel.setModalOpen}
                    setTab={OrganisationSubdivisionsProps.setTab}
                />

            </Box>
        </Box>
    );
}