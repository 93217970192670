import { Box } from "@mui/material";
import { entryLabelType, statusLabelType, statusType } from "../Organisation-types";

export const getEntryStyle = (is_new: boolean) => {
    const entryStyle = {
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'lowercase',
        border: '1px solid',
        borderRadius: '2px',
        padding: '1px 8px',
        width: 'max-content',
        display:'inline-block',

        color: '#13C2C2',
        borderColor: '#87E8DE',
        backgroundColor: '#E6FFFB',

        float: 'right'
    }

    return is_new ? <Box sx={entryStyle}> {entryLabelType.NEW} </Box>  : ''
}

export const getStatusStyle = (status: string) => {
    const statusStyle = {
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'lowercase',
        border: '1px solid',
        borderRadius: '2px',
        padding: '1px 8px',
        width: 'max-content',
        display:'inline-block',
    };

    const confirmedStyle = {
        ...statusStyle,
        color: '#52C41A',
        borderColor: '#B7EB8F',
        backgroundColor: '#F6FFED',
    };

    const draftStyle = {
        ...statusStyle,
        color: '#2F54EB',
        borderColor: '#ADC6FF',
        backgroundColor: '#F0F5FF',
    };

    const deletedtStyle = {
        ...statusStyle,
        color: '#F5222D',
        borderColor: '#FFA39E',
        backgroundColor: '#FFF1F0',
    };

    switch(status) {
        case statusType.CONFIRMED:
            return <Box sx={confirmedStyle}>{statusLabelType.CONFIRMED}</Box>
        case statusType.DRAFT:
            return <Box sx={draftStyle}>{statusLabelType.DRAFT}</Box>
        case statusType.DELETED:
            return <Box sx={deletedtStyle}>{statusLabelType.DELETED}</Box>
    }
}