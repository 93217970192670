import { Box, Button, Drawer, IconButton, Stack, Typography } from "@mui/material"
import { Employee } from "../../../OrganisationEmployees-types"
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {useCheckDocumentsModel } from "./CheckDocumentsModel";
import { DocumentAccordion } from "../../DocumentAccordion/DocumentAccordion";
import { ReactComponent as CloseIcon } from '../../../../../../../assets/icon/Close.svg';
import { DOC_TYPES } from "../../../OrganisationEmployees-enums";
interface ICheckDocumentsDrawerProps {
    open: boolean,
    onClose(): void,
}
export const drawerBoxStyle = {
    // height:'100%',
    width: '362px',
    backgroundColor: '#EEEEEE',
    position: 'relative'
}

export const CheckDocumentsDrawer = ({ open, onClose }: ICheckDocumentsDrawerProps) => {


    const {documentsObj, employeeData} = useCheckDocumentsModel();

    console.log(open)
    console.log(employeeData)
    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            sx={{
                width: '362px',
                //backgroundColor:'#EEEEEE',
            }}
        >
            <Box
                sx={{ ...drawerBoxStyle, backgroundColor: '#EEEEEE', height: '100%' }}
                role="presentation"
            //padding={3}         
            >
                <Box sx={{ position: 'sticky', top: '0px', zIndex: '99', backgroundColor: '#EEEEEE', padding: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', }} component={'h1'}>Проверка документов</Typography>
                        <IconButton onClick={onClose} color="secondary" aria-label="add an alarm"
                            sx={{ '&.MuiButtonBase-root': { borderRadius: '4px' }, '&:hover': { backgroundColor: '#FFFFFF' } }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ padding: '0px 24px', backgroundColor: '#EEEEEE' }}>

                    <Stack gap={'24px'}>
                       <DocumentAccordion employeeId={employeeData?.id} documentData={documentsObj?.edCertificateDocument} label={DOC_TYPES.EDUCATION_CERTIFICATE}></DocumentAccordion>
                       <DocumentAccordion employeeId={employeeData?.id} documentData={documentsObj?.accrCertificateDocument} label={DOC_TYPES.ACCREDIATATION_CERTIFICATE}></DocumentAccordion>
                       <DocumentAccordion employeeId={employeeData?.id} documentData={documentsObj?.edDiplomaDocument} label={DOC_TYPES.EDUCATION_DIPLOMA}></DocumentAccordion>
                       <DocumentAccordion employeeId={employeeData?.id} documentData={documentsObj?.qualCertificateDocument} label={DOC_TYPES.QUALIFICATION_CERTIFICATE}></DocumentAccordion>
                    </Stack>

                </Box>





            </Box>
        </Drawer>
    )
}