export const SuccessIcon = (props: any) => {
    return (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='94'
    height='94'
    viewBox='0 0 95 94'
    fill='none'
    {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.1042 94C73.0616 94 94.1042 72.9574 94.1042 47C94.1042 21.0426 73.0616 0 47.1042 0C21.1469 0 0.104248 21.0426 0.104248 47C0.104248 72.9574 21.1469 94 47.1042 94ZM73.3781 38.0239C75.7213 35.6807 75.7213 31.8818 73.3781 29.5386C71.035 27.1955 67.236 27.1955 64.8929 29.5386L42.3308 52.1007L30.05 39.8199C27.7069 37.4767 23.9079 37.4767 21.5647 39.8199C19.2216 42.163 19.2216 45.962 21.5647 48.3051L38.0882 64.8286L42.3308 69.0712L46.5735 64.8286L73.3781 38.0239Z'
            fill='#33C441'
        />
    </svg>
    )
}