import { useEffect, useState } from "react"
import { Employee } from "../../../OrganisationEmployees-types"
import { useAppSelector } from "../../../../../../../store/hooks/redux"
import { DOC_STATUSES, DOC_TYPES } from "../../../OrganisationEmployees-enums"



interface IDocument {
    id?: number,
    registry_number?: string,
    url: string[],
    expires_at?: any,
    issued_at?: any,
    validity_period?: number | null,
    type: DOC_TYPES,
    status?: string,
    document_status?: DOC_STATUSES,
}


interface IDocumentsObj {
    edCertificateDocument?: IDocument,
    edDiplomaDocument?: IDocument,
    accrCertificateDocument?: IDocument,
    qualCertificateDocument?: IDocument,
}


export const useCheckDocumentsModel = () => {

    const [documentsObj, setDocuments] = useState<IDocumentsObj>()
    const {employeeData}= useAppSelector(state=>state.emplReducer)
    useEffect(() => {
        !!employeeData?.documents && employeeData.documents.data !== false &&
            setDocuments(
                {
                    edCertificateDocument: employeeData.documents.data?.find((document: any) => document.type === DOC_TYPES.EDUCATION_CERTIFICATE),
                    edDiplomaDocument: employeeData.documents.data?.find((document: any) => document.type === DOC_TYPES.EDUCATION_DIPLOMA),
                    accrCertificateDocument: employeeData.documents.data?.find((document: any) => document.type === DOC_TYPES.ACCREDIATATION_CERTIFICATE),
                    qualCertificateDocument: employeeData.documents.data?.find((document: any) => document.type === DOC_TYPES.QUALIFICATION_CERTIFICATE),
                }
            )
    }, [employeeData])


    return {
        documentsObj,
        employeeData,
    }
}