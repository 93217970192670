import { Box, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { EmployeesTableModelProps, TableBodyCell, TableHeadCell, tableBodyRow, tableHeadRow, useEmployeesTableModel } from "./EmployeesTableModel";
import { tableHeaderText } from "../../../../../../styles/MaterialCustomStyles";
import { getEntryStyle, getStatusStyle } from "../../../../utils";
import { Employee } from "../../OrganisationEmployees-types";
import { CheckDocumentsDrawer } from "../drawers/CheckDocumentsDrawer/CheckDocumentsDrawers";
import { NavLink, useLocation } from "react-router-dom";

export const EmployeesTable = (props: EmployeesTableModelProps) => {

    const employeesTableModel = useEmployeesTableModel(props)

    const location = useLocation()

    return (
        // <TableContainer sx={{maxWidth: '1100px', width:'100%'}}>
        //     <Table aria-label="simple table">
        //         <TableHead>
        //             <TableRow 
        //                 sx={{backgroundColor:'#FAFAFA'}}>

        //                 <TableCell 
        //                     sx={{
        //                         fontSize: '14px',
        //                         fontWeight: 600,
        //                         textAlign: 'left',
        //                     }} 
        //                     align="right">
        //                     Фамилия Имя Отчество
        //                 </TableCell>

        //                 <TableCell 
        //                     sx={{
        //                         fontSize: '14px',
        //                         fontWeight: 600,
        //                         textAlign: 'left',
        //                     }} 
        //                     align="right">
        //                     Должность
        //                 </TableCell>

        //                 <TableCell 
        //                     sx={{
        //                         fontSize: '14px',
        //                         fontWeight: 600,
        //                         textAlign: 'left',
        //                     }} 
        //                     align="right">
        //                     Табельный номер
        //                 </TableCell>

        //                 <TableCell 
        //                     sx={{
        //                         fontSize: '14px',
        //                         fontWeight: 600,
        //                         textAlign: 'left',
        //                     }} 
        //                     align="right">
        //                     Подразделение
        //                 </TableCell>

        //                 <TableCell 
        //                     sx={{
        //                         fontSize: '14px',
        //                         fontWeight: 600,
        //                         textAlign: 'left',
        //                     }} 
        //                     align="right">
        //                     Статус
        //                 </TableCell>

        //             </TableRow>
        //         </TableHead>

        //         <TableBody>
        //             {employeesTableModel.employees.map((employee) => (
        //                 <TableRow
        //                 onClick={() => {
        //                     props.handleSetEditEmployeeData(employee)
        //                 }}
        //                 key={employee.id}
        //                 hover 
        //                 sx={{ 
        //                     cursor: 'pointer' 
        //                 }}>

        //                     <TableCell 
        //                         component="th" 
        //                         scope="row"
        //                         sx={{
        //                             minWidth: '290px',
        //                         }}>
        //                         {employee.lastname} {employee.firstname} {employee.middlename} 
        //                         {getEntryStyle(/*employee.is_new*/ employee.is_new ? employee.is_new : false)}
        //                     </TableCell>

        //                     <TableCell
        //                         component="th"
        //                         scope="row"
        //                         sx={{
        //                             minWidth: '199px'
        //                         }}>
        //                         {employee.position?.name || '-'}
        //                     </TableCell>

        //                     <TableCell
        //                         component="th"
        //                         scope="row"
        //                         sx={{
        //                             minWidth: '175px'
        //                         }}>
        //                         {employee.service_number}
        //                     </TableCell>

        //                     <TableCell
        //                         component="th"
        //                         scope="row"
        //                         sx={{
        //                             minWidth: '167px'
        //                         }}>
        //                         {employee.department?.name || '-'}
        //                     </TableCell> 

        //                     <TableCell 
        //                         sx={{
        //                             minWidth: '207px'
        //                         }}>
        //                         {getStatusStyle(employee?.status!)}
        //                     </TableCell>

        //                 </TableRow>
        //             ))}
        //         </TableBody>
        //     </Table>
        // </TableContainer>
        <Stack
            sx={{
                gap: '16px',
                '*::-webkit-scrollbar': {
                    width: '12px',
                    height: '12px',
                  },
                  '*::-webkit-scrollbar-track:vertical': {
                    backgroundColor: '#EEEEEE',
                    borderBottom: '2px solid white'
                  },
                  '*::-webkit-scrollbar-track:horizontal': {
                    backgroundColor: '#EEEEEE',
                    borderRight: '2px solid white',
                  },
                  '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#BDBDBD',
                    border: '2px solid transparent',
                    //borderRadius: '10px / 4px',
                    borderRadius: '5px/5px',
                    backgroundClip: 'content-box',
                  },
                  '*::-webkit-scrollbar-corner': {
                    backgroundColor: '#EEEEEE',
                    borderRadius: '2px',
                  },
            }}
        >
            <TableContainer
                sx={{
                // maxHeight: '630px',
                width: '100%',
                height: '604px',
                position: 'relative',
                borderRadius: '4px 0px 0px 0px',
                backgroundColor: '#FAFAFA',
                }}
            >
                <Table sx={{ position: 'relative' }}>
                <TableHead>
                    <TableRow sx={{ ...tableHeadRow }}>
                    {employeesTableModel.columns.map((col) => (
                        <TableHeadCell key={col.id} id={col.id} name={col.name} visible={col.visible}/>
                    ))}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!employeesTableModel.employees ? employeesTableModel.employees.map((row: any) => (
                    <TableRow key={row.id} style={{ cursor: 'pointer' }} onClick={() => {  }}
                        sx={{ ...tableBodyRow }}
                        hover={true} 
                        //selected={pickedReportData?.id === row.id}
                    >
                        {employeesTableModel.columns.map((col) => (
                        <TableBodyCell id={col.id} value={col.value(row)} visible={col.visible} onClick={col.onClick} fullValue={row}/>
                        ))}
                    </TableRow>
                    )) : <></>}

                </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={employeesTableModel.pageQty}
                    page={employeesTableModel.page}
                    onChange={(_, num) => employeesTableModel.setPage(num)}
                    variant="outlined" shape="rounded"
                    sx={{
                        //marginY: 3, marginX: "auto",
                        '& .Mui-selected': {
                            backgroundColor: '#009FDA !important',
                            color: 'white !important'
                        },
                        '& .Mui-selected:hover': {
                            backgroundColor: 'rgb(0,159,218) !important',
                            color: 'white !important'
                        },
                    }}
                    renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            component={NavLink}
                            to={`${location.pathname}`}
                            // to={'/organisations'}
                        />
                    )}
                />
            </Box>

            <CheckDocumentsDrawer
                     open={employeesTableModel.isCheckDocumentsOpen}
                    // onOpen={()=>employeesModel.setModalOpen(true)}
                    onClose={()=>{
                        employeesTableModel.setCheckDocumentsOpen(false)
                    }}/>
        </Stack>        
    );
}