import { Box, Collapse, Divider, Stack, Typography } from '@mui/material'
import { styles } from './styles'
import type { MenuLinkProps } from './types'
import { type FC, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded'

export const MenuLink: FC<MenuLinkProps> = (props) => {
  const { title, icon, url = '', subLinks, collapse, setCollapse, onClick } = props

  const location = useLocation()

  const [open, setOpen] = useState<boolean>(
    !!subLinks &&
      subLinks.some((e) => e.url && location.pathname.includes(e.url))
  )
  const [onLink, setLink] = useState<boolean>(false)

  useEffect(() => {
    setLink(
      !!subLinks &&
        subLinks.some((e) => e.url && location.pathname.includes(e.url))
    )
  }, [location])

  const openCollapse = () => {
    setOpen((prev) => (collapse ? true : !prev))
    setCollapse && setCollapse(false)
  }

  return (
    <Box sx={styles.root}>
      {!title && !icon && !url && !subLinks ? (
        <Divider variant="middle" />
      ) : (
        <>
          {subLinks ? (
            <>
              <Box
                onClick={openCollapse}
                className={
                  'menu-item major-link sub-links ' +
                  `${onLink || open ? 'active' : ''}`
                }
              >
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack direction={'row'} gap={'16px'}>
                    <Box className="title-icon">{icon}</Box>
                    <Typography sx={{ opacity: collapse ? '0' : '1' }}>
                      {title}
                    </Typography>
                  </Stack>
                  <ChevronRightRounded
                    className="collapse-icon"
                    sx={{
                      transform: open ? 'rotate(90deg)' : 'none',
                      scale: collapse ? '0' : '1',
                      opacity: collapse ? '0' : '1',
                    }}
                  />
                </Stack>
              </Box>
              <Collapse
                in={open && !collapse}
                className={`collapse-${open ? 'entered' : 'hidden'}`}
              >
                {subLinks.map((item, index) => (
                  <MenuLink title={item.title} url={item.url} key={index} onClick={(e) => onClick(e, item.url)}/>
                ))}
              </Collapse>
            </>
          ) : (
            <NavLink
              to={url || 'javascript:;'}
              className={
                'menu-item ' +
                `${icon ? 'major-link ' : 'minor-link '}` +
                `${url ? '' : 'no-url'}`
              }
              onClick={(e) => onClick(e, url)}
            >
              <Stack direction={'row'} gap={'16px'}>
                <Box className="title-icon">{icon}</Box>
                <Typography sx={{ opacity: collapse ? '0' : '1' }}>
                  {title}
                </Typography>
              </Stack>
            </NavLink>
          )}
        </>
      )}
    </Box>
  )
}
