import { Box, Stack } from "@mui/material"
import FsLightbox from "fslightbox-react";
import { useState } from "react";



export const DocumentPhotoGroup = ({ urls }: any) => {

    const [toggler, setToggler] = useState(false)
    console.log('урлы')
    console.log(urls)
    const images = urls ? urls.map((url: string) => {
        return <img src={url}></img>
    })
        : []

    const image = urls ? <img src={urls[1]} /> : []
    console.log(image)
    return (
        <Box>
            <FsLightbox
                toggler={toggler}
                sources={images}
            />
            <Stack direction={'row'} gap={2}>
                {
                    !!urls
                        ? urls.map((url: string) => {
                            return <Box sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                },
                                filter:'blur(1px)'
                            }}>
                                <img
                                    style={{ borderRadius: '4px' }}
                                    onClick={() => setToggler(!toggler)}
                                    src={url} width={'86px'} height={'86px'} />
                            </Box>

                        })
                        : <></>
                }

            </Stack>
        </Box>
    )

}