import { useEffect, useState } from "react"
import { editSubdivisionRequest } from "../../../../../../services/EmployeeService"
import { createSubdivisionRequest, getSubdivisionsRequest, getSubdivisionsRequestParams } from "../../../../../../services/SubdivisionService"
import { useAppSelector, useAppDispatch } from "../../../../../../store/hooks/redux"
import { Subdivision } from "../../OrganisationSubdivision-types"
import { clearBearer, setSubdivisions } from "../../OrganisationSubdivisionsSlice"
import { useParams } from "react-router-dom"
import { OrganisationsTabsLabels } from "../../../OrganisationAllInfoModule/OrganisationAllInfo-types"
import { setEmployeeBuffer } from "../../../OrganisationEmployeesModule/OrganisationEmployeesSlice"


export interface SubdivisionCardModelProps {
    modal: boolean
    setModal: (value: boolean) => void
    setTab: (tab: OrganisationsTabsLabels) => void
}

export const useSubdivisionCardModel = (props: SubdivisionCardModelProps) => {

    const { /*subdivisionData,*/ bearer, subdivisionBuffer } = useAppSelector(state => state.subdivReducer)
    const { employeeBuffer } = useAppSelector(state => state.emplReducer)
    const dispatch = useAppDispatch();

    const { id } = useParams();

    // useEffect(() => {
    //     // if (subdivisionBuffer) {

    //     // }    
    //     //setSubdivisionData(subdivisionBuffer)
    // }, [])

    // useEffect(() => {
    //     //console.log(subdivisionTransferBuffer)
    //     // getBufferData()
    //     // getTransferBufferData()
        
    //     //dispatch(setSubdivisionBuffer(subdivisionData))
    // }, [
    //     //setSubdivisionData2
    // ])

    useEffect(() => {
        if (bearer) {
            props.setModal(true)
            console.log(subdivisionBuffer?.name!)
            handleSetName(subdivisionBuffer?.name!)
        }
    }, [])



    const handleCloseModal = () => {
        setNameInput('')
        setParentInput('')
        setSubdivisionData(null)

        props.setModal(false)
    }



    const getSubdivisions = async (search?: string) => {
        try {
            if (search) {
                const response = await getSubdivisionsRequest(Number(id), {search: search})
                return (response.data.data)
            } else {
                const response = await getSubdivisionsRequest(Number(id))
                dispatch(setSubdivisions(response.data.data))
            }
            // Нужно запрашивать последнюю страницу, чтобы показывать последнюю запись
        } catch(e) {
            console.error(e)
        }
    }


    const [subdivisionData, setSubdivisionData] = useState<Subdivision | null>({})
    // Переименуй иначе или замени им SubdivisonBuffer

    //TODO: RENAME subdivisionData2 TO subdivisionData WHEN subdivisionData WILL BE RANAMED TO subdivisionBuffer
    const handleSetName = (name: string) => {
        if (name) {
            setSubdivisionData({ 
                ...subdivisionData, 
                name: name.replace(/\s\s+/g, ' ')//.trim()
            });
        } else {
            delete subdivisionData?.name
        }
    };

    const handleSetStatus = (status: string) => {
        if (status) {
            setSubdivisionData({ 
                ...subdivisionData, status: status
            });
        } else {
            delete subdivisionData?.status
        }
    };

    const handleSetParentId = (parent_id: number) => {
        if (parent_id) {
            setSubdivisionData({
                ...subdivisionData, parent_id: parent_id
            });
        } else {
            delete subdivisionData?.parent_id
        }
    }

    const validateSubdivisionData = () => {
        return Boolean(
            subdivisionData?.name &&
            subdivisionData?.status &&
            !nameOptions?.find(subdivision => (
                subdivision.name?.toLowerCase() === subdivisionData.name?.toLowerCase()
            ))
        )
    }

    const [isRequestSucceed, setRequestSucceed] = useState<boolean>(false)

    const handleSaveSubdivision = async () => {
        try {
            let response
            if (subdivisionData?.id) {
                response = await editSubdivisionRequest(Number(id), subdivisionData)
            }
            else {
                response = await createSubdivisionRequest(Number(id), subdivisionData!)
                if (bearer) { 
                    //dispatch(clearBearer())
                    dispatch(setEmployeeBuffer({
                        ...employeeBuffer,
                        departmentName: subdivisionData?.name
                    }))

                    props.setTab(bearer)
                }
            }
            console.log(response)

            handleCloseModal()
            setRequestSucceed(true)
            setTimeout(() => {
                setRequestSucceed(false)
            }, 1200)

            getSubdivisions()
        } catch(e) {
            console.error(e)
        }
    }


    const [nameInput, setNameInput] = useState<string>('')
    const [openNameSuggestions, setOpenNameSuggestions] = useState<boolean>(false)
    const [nameOptions, setNameOptions] = useState<Subdivision[]>([])

    const [parentInput, setParentInput] = useState<string>('')
    const [openParentSuggestions, setOpenParentSuggestions] = useState<boolean>(false)
    const [parentOptions, setParentOptions] = useState<Subdivision[]>([])

    useEffect(() => {
        (async () => {
            handleSetName(nameInput)
            setNameOptions(await getSubdivisions(nameInput))
            
        })()
    }, [nameInput])

    useEffect(() => {
        (async () => {
            setParentOptions(await getSubdivisions(parentInput))
        })()
    }, [parentInput])



    useEffect(() => {
        if (bearer) {
            dispatch(setEmployeeBuffer({
                ...employeeBuffer,
                departmentName: subdivisionData?.name
            }))

        }
    }, [subdivisionData])
    
    return {
        handleCloseModal,

        subdivisionData,
        handleSetName,
        handleSetParentId,
        handleSetStatus,

        validateSubdivisionData,
        handleSaveSubdivision,

        nameInput, setNameInput,
        openNameSuggestions, setOpenNameSuggestions,
        nameOptions, setNameOptions,

        parentInput, setParentInput,
        openParentSuggestions, setOpenParentSuggestions,
        parentOptions, setParentOptions,

        isRequestSucceed
    }
}
