export interface AccData {
    id: number;
    companyId: number;
    number: string;
    bank_name: string;
    name: string;
    status: string;
    is_main: boolean | null;
}

export interface NewAccData {
    number: string;
    bank_name: string;
    name: string;
    status: string;
    is_main: boolean | null;
}

export interface EditQueryData {
    id: number;
    companyId: number;
    data: {
        number?: string;
        bank_name?: string;
        name?: string;
        status?: string;
        is_main?: boolean | null;
    };
}

export interface BankData {
    data: any;
    unrestricted_value: string;
    value: string;
}

export enum statuses {
    'confirmed' = 'CONFIRMED',
    'draft' = 'DRAFT',
    'deleted' = 'DELETED',
}

export enum statusLabels {
    'confirmed' = 'Действует',
    'draft' = 'Черновик',
    'deleted' = 'Удален',
}
