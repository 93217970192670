export const MainIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect x="2.75" y="2.75" width="14.5" height="14.5" rx="3.25"
          stroke="currentColor"
          strokeWidth="1.5"  
          fillOpacity="0"
        />
        <rect x="9.25" y="3" width="1.5" height="14" fill="currentColor"/>
        <rect x="3" y="9" width="1.5" height="7.36496" transform="rotate(-89.5775 3 9)" fill="currentColor"/>
        <rect x="10.0039" y="12.4453" width="1.5" height="7.36496" transform="rotate(-89.5775 10.0039 12.4453)" fill="currentColor" />
      </g>
    </svg>
  )
}