export enum MainInfoTabsLabels{
    MAIN='Общие данные',
    ADDRESS = 'Адреса, телефоны'
}

export interface DadataCompany {
    data: any,
    label: string,
    unrestricted_value: string,
    value: string
}