import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const textFieldOutlineMultilineStyle = {
    backgroundColor: 'white',
    borderRadius: '4px',

    '&.MuiInputLabel-root.Mui-focused': {

        visibility: 'hidden !important',
    },

    '& .MuiInputBase-root': {

        height: '86px',
        paddingTop: '40px',
        paddingRight: '0px',
        paddingBottom: '0px',

        '*::-webkit-scrollbar': {
            width: '10px',
            //height: '10px',
        },
        // '*::-webkit-scrollbar-button': {
        //   //border: '1px solid blue',
        //   backgroundColor: '#8E8E8E',
        //   // //display: 'block',
        //   // border: '1px solid black',
        //   // borderRight: '4px solid black',
        //   // borderLeft: '4px solid black',

        //   //borderRadius: '10px/10px 10px 0px 0px',
        //   borderRadius: '4px',
        //   height: '10px',
        // },
        '*::-webkit-scrollbar-track': {
            //backgroundColor: '#EEEEEE',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#8E8E8E',
            border: '1px solid transparent',
            borderRadius: '12px / 12px',
            backgroundClip: 'content-box',
        },
    },
    '& .MuiInputBase-input': {

        height: '50px !important',
        marginLeft: '-2px',
        paddingRight: '50px',

        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18px',

        "&.Mui-disabled": {
            WebkitTextFillColor: "#000000",
        },
    },

    '& .MuiFormLabel-root': {
        pointerEvents: 'none',
        userSelect: 'none',

        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16px',

        marginTop: '4px',
        marginLeft: '-2px',

        '&.Mui-focused': {
            color: '#787878'
        },
        '&.Mui-disabled': {
            color: '#787878'
        },
    },


    '& .MuiOutlinedInput-root': {
        opacity: 1,
        '& fieldset': {
            borderColor: '#8E8E8E',
        },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        opacity: 1,
        '& fieldset': {
            borderColor: '#8E8E8E',
        },
        '&:hover': {
            '& fieldset': {
                border: '1px solid #8E8E8E',
            },
        }
    },

    '& .MuiOutlinedInput-root:hover': {
        opacity: 1,
        '& fieldset': {
            border: '2px solid #A5E5FD',
        },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
        opacity: 1,
        '& fieldset': {
            border: '2px solid #009FDA',
        },
    },

}



interface CommentInputProps{
    name:string,
    control:any,
    label:string,
    rules?:any,
    isDisabled?:boolean
}

export const CommentInput = ({ name, control, label, rules, isDisabled }: CommentInputProps) => {




  useEffect(()=>{

  })
  return (
    <Controller
    rules={rules}
      name={name}
      control={control ? control : null}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <Box>

            
        <TextField
        sx={ {...textFieldOutlineMultilineStyle,
           borderColor:error ? '#DC4C4C' : '#8E8E8E',
           backgroundColor:error ? '#FEEAEA' : 'white',
          } }
        multiline
          rows={4}
          // helperText={required ? 'Обязательно укажите причины' : null}
          size="small"
        //   error={!!required}
          onChange={onChange}
          value={value}
          fullWidth
        //   helperText = {error ? error.message : null}
          error={!!error}
          label={label}
          variant="outlined"
          disabled={isDisabled}
          InputLabelProps={{
            shrink: false,
            // sx:{
            //   '&.Mui-focused':{
            //     visibility:'hidden',
            //   }
            // }
          }}
        />
        {error ? <Typography color={'red'} fontSize={''}>{error.message}</Typography> : <></>}
         </Box>  
      )}
    />
  );
};
