import { Autocomplete, Box, Button, Card, CardActions, CardContent, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Modal, Paper, Popper, Radio, RadioGroup, Select, SvgIcon, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, TooltipProps, Typography, alpha, inputLabelClasses, styled, textFieldClasses, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { tab } from '@testing-library/user-event/dist/tab';
import { divide } from 'lodash';
import { EmployeeCardModelProps, useEmployeeCardModel } from './EmployeeCardModel';
import { CustomTooltip } from './components/CustomTooltip';
import { blueContainedBigButtonStyle, tabStyle, textFieldFilledStyle, autocompleteStyle, popperFromAutocompleteStyle, paperFromAutocompleteStyle, switchVerticalStyle } from '../../../../../../styles/MaterialCustomStyles';
import { statusLabelType } from '../../../../Organisation-types';
import { SucceedWindow } from '../../../../components/SucceedWindow/SucceedWindow';
import { ContactType } from '../../OrganisationEmployees-enums';

export const EmployeeCard = (props: EmployeeCardModelProps) => {

    const employeeCardModel = useEmployeeCardModel(props)

    //const [openNameSuggestions, setOpenNameSuggestions] = useState<boolean>(false)
    //const [inputNameValue, setInputNameValue] = useState<string>('')

    // const [tabValue, setTabValue] = useState<string>('MAIN')

    // const [roleOptions, setRoleOptions] = useState<Role[]>([])

    // useEffect(() => {

    //     const uniq = (arr: Role[]) => {
    //         const unique: Role[] = [];
    //         for (const item of arr) {
    //         const duplicate = unique.find(
    //             (obj) => obj.id === item.id && obj.name === item.name
    //         );
    //             if (!duplicate) {
    //                 unique.push(item);
    //             }
    //         }
    //         return unique
    //     }


    //     (async () => {
    //         if(
    //             Number(EmployeeCardProps.id) &&
    //             EmployeeCardProps.positionId && 
    //             (EmployeeCardProps.employeeProductsFirst[0].id ||
    //             EmployeeCardProps.employeeProducts.find((product) => 
    //                 product.id
    //             ))
    //         ) {

    //             let employeeArray: Role[] = []
    //             if (EmployeeCardProps.employeeProductsFirst[0].id !== 0) {
    //                 employeeArray = employeeArray.concat(EmployeeCardProps.employeeProductsFirst)
    //             }
    //             employeeArray = employeeArray.concat(EmployeeCardProps.employeeProducts)

    //             let optionArray: Role[] = []

    //             console.log(employeeArray)

    //             employeeArray.map(async (product) => {
    //                 const response = await getRolesRequest(
    //                     Number(EmployeeCardProps.id), 
    //                     EmployeeCardProps.positionId, 
    //                     product.id
    //                 )
    //                 optionArray = uniq(optionArray.concat(response.data.data))
    //                 setRoleOptions(optionArray)
    //             })
    //         } else {
    //             setRoleOptions([])
    //         }

    //       })();
    // }, [
    //     EmployeeCardProps.handleSetPositionId,
    //     EmployeeCardProps.handleSetEmployeeProductsFirst,
    //     EmployeeCardProps.handleSetEmployeeProducts,
    // ])


    if (employeeCardModel.isRequestSucceed) {
        return <SucceedWindow open={employeeCardModel.isRequestSucceed} />
    }

    return props.modal ?
        (
            <Card
                sx={{
                    width: '362px',
                    //height: '786px',
                    height: '800px',
                    padding: '24px',
                    overflowY: 'auto',
                    backgroundColor: '#EEEEEE',
                    position: 'fixed',
                    zIndex: 10,
                    top: 70,
                    right: 24,
                }}>


                {/* <Modal
                    //open={EmployeeCardProps.isNewSubdivision}
                    open={true}
                    >
                    <Box sx={newSubdivisionWindow}>
                        <Typography
                            variant='h6'
                            component='h2'>
                            Данные успешно внесены!
                        </Typography>
                    </Box>
                </Modal> */}

                {employeeCardModel.isNewSubdivision ? 
                    <Box
                    onClick={() => {
                        employeeCardModel.setIsNewSubdivision(false)
                    }}
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        zIndex: '2',
                        position: 'absolute',
                        margin: '-24px',
                        minHeight: '100%',
                        //height: 'inherit',
                        width: '100%',
                    }}>
                    <Box 
                        sx={{
                            position: 'absolute',
                            backgroundColor: '#FFFFFF',
                            width: '314px',
                            height: '166px',
                            padding: '24px',
                            gap: '24px',
                            top: '35%',
                            left: '7%',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '2px',
                        }}>
                        <Box 
                            sx={{
                                display: 'flex'
                            }}>
                            <Box
                                sx={{
                                    marginRight: '16px'
                                }}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3175_70667)">
                                    <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" 
                                        fill="#FFC53D"
                                    />
                                    <path d="M9.82104 15.3214C9.82104 15.634 9.94522 15.9338 10.1662 16.1548C10.3873 16.3758 10.687 16.5 10.9996 16.5C11.3122 16.5 11.612 16.3758 11.833 16.1548C12.054 15.9338 12.1782 15.634 12.1782 15.3214C12.1782 15.0089 12.054 14.7091 11.833 14.4881C11.612 14.267 11.3122 14.1429 10.9996 14.1429C10.687 14.1429 10.3873 14.267 10.1662 14.4881C9.94522 14.7091 9.82104 15.0089 9.82104 15.3214ZM10.4103 12.5714H11.5889C11.6969 12.5714 11.7853 12.483 11.7853 12.375V5.69643C11.7853 5.58839 11.6969 5.5 11.5889 5.5H10.4103C10.3023 5.5 10.2139 5.58839 10.2139 5.69643V12.375C10.2139 12.483 10.3023 12.5714 10.4103 12.5714Z" 
                                        fill="#FFC53D"
                                    />
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_3175_70667">
                                    <rect width="22" height="22" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </Box>
                            <Box
                                sx={{
                                    //textAlign: 'center',
                                    //marginRight: '-15px',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                    color: '#222222',
                                }}>
                                Данного подразделения нет в Справочнике
                            </Box>
                        </Box>
                        <Button 
                            variant='contained'
                            sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}
                            onClick={() => {
                                employeeCardModel.handleAddSubdivision()
                            }}
                            >
                            <AddIcon /> Добавить данные
                        </Button>
                    </Box>
                </Box>
                : null}

                {employeeCardModel.isNewPosition ? 
                  <Box
                    onClick={() => {
                        employeeCardModel.setIsNewPosition(false)
                    }}
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        zIndex: '2',
                        position: 'absolute',
                        margin: '-24px',
                        minHeight: '100%',
                        //height: 'inherit',
                        width: '100%',
                    }}>
                    <Box 
                        sx={{
                            position: 'absolute',
                            backgroundColor: '#FFFFFF',
                            width: '314px',
                            height: '166px',
                            padding: '24px',
                            gap: '24px',
                            top: '35%',
                            left: '7%',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '2px',
                        }}>
                        <Box 
                            sx={{
                                display: 'flex'
                            }}>
                            <Box
                                sx={{
                                    marginRight: '16px'
                                }}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3175_70667)">
                                    <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" 
                                        fill="#FFC53D"
                                    />
                                    <path d="M9.82104 15.3214C9.82104 15.634 9.94522 15.9338 10.1662 16.1548C10.3873 16.3758 10.687 16.5 10.9996 16.5C11.3122 16.5 11.612 16.3758 11.833 16.1548C12.054 15.9338 12.1782 15.634 12.1782 15.3214C12.1782 15.0089 12.054 14.7091 11.833 14.4881C11.612 14.267 11.3122 14.1429 10.9996 14.1429C10.687 14.1429 10.3873 14.267 10.1662 14.4881C9.94522 14.7091 9.82104 15.0089 9.82104 15.3214ZM10.4103 12.5714H11.5889C11.6969 12.5714 11.7853 12.483 11.7853 12.375V5.69643C11.7853 5.58839 11.6969 5.5 11.5889 5.5H10.4103C10.3023 5.5 10.2139 5.58839 10.2139 5.69643V12.375C10.2139 12.483 10.3023 12.5714 10.4103 12.5714Z" 
                                        fill="#FFC53D"
                                    />
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_3175_70667">
                                    <rect width="22" height="22" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </Box>
                            <Box
                                sx={{
                                    //textAlign: 'center',
                                    //marginRight: '-15px',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                    color: '#222222',
                                }}>
                                Данной должности нет в Справочнике
                            </Box>
                        </Box>
                        <Button 
                            variant='contained'
                            sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}
                            onClick={() => {
                                employeeCardModel.handleAddPosition()
                            }}
                            >
                            <AddIcon /> Добавить данные
                        </Button>
                    </Box>
                </Box>
                : null}
                
                <TabContext value={employeeCardModel.tabValue}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '25px'
                    }}>

                        <TabList 
                            sx={{'& .MuiTabs-indicator': {backgroundColor: '#009FDA'}}} 
                            onChange={(_, value) => employeeCardModel.setTabValue(value)} 
                        >
                            <Tab sx={[tabStyle]} label='Основное' value={'MAIN'} />
                            <Tab sx={tabStyle} label='Роли' value={'ROLES'} />
                        </TabList>

                    <IconButton onClick={employeeCardModel.handleCloseModal}>
                        <CloseIcon sx={{color: '#3E3E3E', scale: '1.2'}}/>
                    </IconButton>
                </Box>

                <Box sx={{margin: '-24px' }}>
                    <TabPanel value={'MAIN'}>
                        <FormGroup
                        sx={{
                            rowGap: '24px',
                            maxWidth: '314px',
                        }}>

                        <FormControl>
                            <TextField
                                onChange={(event) => {
                                    employeeCardModel.handleSetFIO(event.target.value)
                                }}

                                // defaultValue={(
                                //     EmployeeCardModel.lastName &&
                                //     EmployeeCardProps.firstName &&
                                //     EmployeeCardProps.middleName 
                                //     ?
                                //     EmployeeCardProps.lastName ?  EmployeeCardProps.lastName + ' ' : '' +
                                //     EmployeeCardProps.firstName + ' ' +
                                //     EmployeeCardProps.middleName
                                //     : ''
                                // )}
                                
                                //defaultValue={employeeCardModel.fio}

                                value={employeeCardModel.fio}

                                variant='filled'
                                sx={textFieldFilledStyle}
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}

                                label='Полное Ф.И.О.'
                                placeholder='Фамилия Имя Отчество'
                                autoComplete='off'
                            />
                        </FormControl>

                            <FormControl>
                                <Autocomplete 
                                    onChange={(_, newPosition: any) => {
                                        employeeCardModel.handleSetPositionId(newPosition?.id!)
                                    }}

                                    value={employeeCardModel.selectedPosition}
                                    
                                    freeSolo forcePopupIcon

                                    onInputChange={(_, newInputValue) => {
                                        employeeCardModel.checkNewPosition(newInputValue)
                                        employeeCardModel.setPositionInput(newInputValue)
                                        if (newInputValue === '') {
                                            employeeCardModel.handleSetPositionId(0)
                                        }
                                    }}
                                    inputValue={employeeCardModel.positionInput || employeeCardModel.selectedPosition?.name || ''}
                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={popperFromAutocompleteStyle} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    
                                    options={employeeCardModel.positionOptions || []}
                                    
                                    isOptionEqualToValue={(option:any, value:any) => option.name === value.name}
                                    getOptionLabel={(option) => option.name || ''}
                                    noOptionsText={'Не найдено'} 

                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Должность'
                                            placeholder='Название должности'
                                            autoComplete='off'
                                        />
                                    )}
                                />
                            </FormControl>


                            <FormControl
                                    sx={{
                                        backgroundColor: '#D9D9D9', 
                                        borderRadius: '8px',
                                        padding: '12px 11px',
                                        maxWidth: '314px',  
                                        rowGap: '12px',
                                    }}>

                                    <Button 
                                        variant='contained'
                                        sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}
                                        onClick={() => {employeeCardModel.handleAddContact()}}
                                        //disabled={!SubdivisionCardProps.validateEmployeeData()} // Если достигнут максимум?
                                        >
                                        <AddIcon /> Добавить данные
                                    </Button>
                                        

                                    <RadioGroup
                                        onChange={(event) => 
                                            employeeCardModel.handleSetContactNotify(Number(event.target.value), true)
                                        }

                                        defaultValue={employeeCardModel.employeeData?.contacts?.find(contact => 
                                            contact.use_to_notify === true
                                        )?.id || 0}
                                        // value={EmployeeCardProps.contacts.find(contact => 
                                        //     contact.use_to_notify === true
                                        // )?.id || 0}

                                        name="contacts"
                                        sx={{
                                            rowGap: '12px',
                                        }}>

                                        {employeeCardModel.employeeData?.contacts?.map((contact, index) => {
                                            return (
                                                <Box 
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}>
                                                <TextField
                                                    onChange={(event) => employeeCardModel.handleSetContactValue(index, event.target.value)}
                                                    // onClick={(event) => {
                                                    //     if (event.detail === 2) { // double ckick
                                                    //         console.log(index + ' double ckick')
                                                    //     }
                                                    // }}
                                                    
                                                    value={employeeCardModel.employeeData?.contacts![index].value}
                                                    //defaultValue={contact.value || ''}

                                                    variant='filled'
                                                    sx={[textFieldFilledStyle, {
                                                        width: '260px', 
                                                        marginRight: '9px',
                                                        '& .MuiFormLabel-root': {
                                                            pointerEvents: 'inherit',
                                                            cursor: 'text',
                                                        },

                                                    }]}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{ 
                                                        disableUnderline: true, 
                                                        endAdornment: (
                                                            contact.use_to_notify ?
                                                            <CustomTooltip
                                                                title="используется для рассылки" 
                                                                >
                                                                <InfoOutlinedIcon
                                                                    sx={[
                                                                        {scale: '0.8', color: '#8E8E8E', marginRight: '-3px', cursor: 'help'},
                                                                        {color: (contact.use_to_notify ? '#009FDA' : '#8E8E8E')}
                                                                    ]}
                                                                />
                                                            </CustomTooltip>
                                                            :
                                                            <InfoOutlinedIcon
                                                                sx={[
                                                                    {scale: '0.8', color: '#8E8E8E', marginRight: '-3px'},
                                                                    {color: (contact.use_to_notify ? '#009FDA' : '#8E8E8E')}
                                                                ]}
                                                            />
                                                        )
                                                    }}
                                                    label={
                                                                <span>
                                                                    <span onClick={() => 
                                                                        employeeCardModel.handleSetContactType(index, ContactType.PHONE)
                                                                    }
                                                                    style={{
                                                                        cursor: contact.type === ContactType.PHONE ? 'text' : 'pointer',
                                                                        color: contact.type === ContactType.PHONE ? '#009FDA' : '#8E8E8E',
                                                                        textDecoration: contact.type === ContactType.PHONE ? 'none' : 'underline',
                                                                    }}>
                                                                        Номер телефона
                                                                    </span>
                                                                    <span> / </span>
                                                                    <span onClick={() => 
                                                                        employeeCardModel.handleSetContactType(index, ContactType.EMAIL)
                                                                    }
                                                                    style={{
                                                                        cursor: contact.type === ContactType.EMAIL ? 'text' : 'pointer',
                                                                        color: contact.type === ContactType.EMAIL ? '#009FDA' : '#8E8E8E',
                                                                        textDecoration: contact.type === ContactType.EMAIL ? 'none' : 'underline',
                                                                    }}>
                                                                        Email
                                                                    </span>
                                                                </span>
                                                    }
                                                    placeholder={contact.type === ContactType.PHONE ? '+7 (999) 432-43-21' : 'cbeywiof@post.ru'}
                                                    
                                                    autoComplete='off'
                                                />
                                                <FormControlLabel 
                                                    value={index}
                                                    label="" 
                                                    control={
                                                        <Radio 
                                                            checked={contact?.use_to_notify}

                                                            disableRipple 
                                                            icon={<Switch sx={switchVerticalStyle} />} 
                                                            checkedIcon={<Switch sx={switchVerticalStyle} checked />} 
                                                        />
                                                    }
                                                />
                                            </Box>
                                            )
                                            
                                        })}
                                    </RadioGroup>
                                </FormControl>

                                <FormControl>
                                <Autocomplete 
                                    onChange={(_, newSubdivision: any) => {
                                        employeeCardModel.handleSetSubdivisionId(newSubdivision?.id!)
                                        //employeeCardModel.setSelectedSubdivision(newSubdivision)
                                    }}

                                    onInputChange={(_, newInputValue) => {
                                        employeeCardModel.checkNewSubdivision(newInputValue)
                                        //employeeCardModel.checkNewSubdivision(newInputValue)
                                        employeeCardModel.setSubdivisionInput(newInputValue)
                                        if (newInputValue === '') {
                                            employeeCardModel.handleSetSubdivisionId(0)
                                        }
                                    }}
                                    inputValue={
                                        //employeeCardModel.subdivisionName ||
                                        employeeCardModel.subdivisionInput ||
                                        employeeCardModel.selectedSubdivision?.name || ''
                                    }

                                    // defaultValue={EmployeeCardProps.subdivisions.find((subdivision) => 
                                    //     EmployeeCardProps.subdivisionId === subdivision.id
                                    // ) || null}
                                    value={employeeCardModel.selectedSubdivision} /////////////////////////////////////////


                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={popperFromAutocompleteStyle} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    freeSolo forcePopupIcon
                                    
                                    options={employeeCardModel.subdivisionOptions || []}

                                    isOptionEqualToValue={(option:any, value:any) => option === value}
                                    getOptionLabel={(option: any) => option.name || ''}
                                    noOptionsText={'Не найдено'}

                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        )
                                    }}
                                    renderInput={(params) => (
                                        <TextField

                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Подразделение'
                                            placeholder='Название подразделения'
                                            autoComplete='off'
                                        />
                                    )}
                                />
                            </FormControl>

                            {employeeCardModel.suggestEditSubdivision ? 
                                <FormControl>
                                    <Autocomplete 
                                        onChange={(_, newParent: any) => {
                                            employeeCardModel.handleSetSubdivisionParentId(newParent?.id!)
                                        }}

                                        onInputChange={(_, newInputValue) => {
                                            employeeCardModel.setSubdivisionParentInput(newInputValue)
                                            if (newInputValue === '') {
                                                employeeCardModel.handleSetSubdivisionParentId(0)
                                            }
                                        }}
                                        inputValue={employeeCardModel.subdivisionParentInput}

                                        freeSolo forcePopupIcon

                                        // value={EmployeeCardProps.subdivisions.find((subdivision) => 
                                        //     EmployeeCardProps.departmentParentId === subdivision.id
                                        // ) || null}
                                        
                                        sx={autocompleteStyle}
                                        popupIcon={<KeyboardArrowRightRoundedIcon />}
                                        PopperComponent={(props) => (
                                            <Popper {...props} sx={popperFromAutocompleteStyle} />
                                        )}
                                        PaperComponent={(props) => (
                                            <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                        )}
                                        
                                        options={employeeCardModel.subdivisionParentOptions || []}

                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option.name || ''}
                                        noOptionsText={'Не найдено'}
            
                                        renderOption={(props, option, { inputValue }) => {
                                            const matches = match(option.name, inputValue, {
                                                insideWords: true,
                                            });
                                            const parts = parse(option.name, matches);
                                            return (
                                                <li {...props} key={option.id}>
                                                    <div>
                                                        {parts.map((part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    color: part.highlight
                                                                        ? '#009FDA'
                                                                        : 'none',
                                                                }}>
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant='filled'
                                                sx={[
                                                    textFieldFilledStyle, 
                                                    {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                                ]}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ 
                                                    ...params.InputProps, 
                                                    disableUnderline: true,
                                                }}
                                                label='Вышестоящее подразделение'
                                                placeholder='Вышестоящее подразделение'
                                                autoComplete='off'
                                            />
                                        )}
                                    />
                                </FormControl>
                                : 
                                ''
                            }

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <FormControl>
                                    <TextField
                                        //onChange={(event) => EmployeeCardProps.handleSetServiceNumber(event.target.value)}

                                        //defaultValue={EmployeeCardProps.service_number || ''}
                                        value={employeeCardModel.employeeData?.service_number || ''}

                                        variant='filled'
                                        sx={[textFieldFilledStyle, {width: '146px', pointerEvents: 'none'}]}
                                        InputProps={{ disableUnderline: true, readOnly: true }}
                                        InputLabelProps={{ shrink: true }}

                                        label='Табельный номер'
                                        placeholder='Номер'
                                        autoComplete='off'
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        value={
                                            employeeCardModel.employeeData?.status! === 'DRAFT' ? 'Черновик' : 
                                            employeeCardModel.employeeData?.status! === 'CONFIRMED' ? 'Действует' :
                                            employeeCardModel.employeeData?.status! === 'DELETED' ? 'Удалён' : 'Неизвестен'
                                        }

                                        variant='filled'
                                        sx={[textFieldFilledStyle, {width: '146px', pointerEvents: 'none'}]}
                                        InputProps={{ disableUnderline: true, readOnly: true }}
                                        InputLabelProps={{ shrink: true }}

                                        label='Статус'
                                        placeholder='Статус'
                                        autoComplete='off'
                                    />
                                </FormControl>
                            </Box>

                            <Button 
                                variant='contained'
                                sx={blueContainedBigButtonStyle}
                                onClick={() => {employeeCardModel.handleSaveEmployee()}}
                                disabled={!employeeCardModel.validateEmployeeData()}
                                >
                                Сохранить
                            </Button>
                            
                        </FormGroup>
                    </TabPanel>
                    
                    <TabPanel value={'ROLES'}>

                    {/* <FormGroup
                        sx={{
                            rowGap: '24px',
                            maxWidth: '314px',
                        }}>

                        <FormControl>
                                <Autocomplete 
                                    onChange={(_, newProduct) =>
                                        EmployeeCardProps.handleSetEmployeeProductsFirst(newProduct?.id!, newProduct?.name!)
                                    }


                                    defaultValue={EmployeeCardProps.employeeProductsFirst[0] || null}
                                    // value={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}
                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={popperFromAutocompleteStyle} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    
                                    options={EmployeeCardProps.products.map(
                                        (product) => product
                                    )}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText={'Не найдено'}

                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Продукт'
                                            placeholder='Выберите продукт'
                                            autoComplete='off'
                                        />
                                    )}
                                />
                            </FormControl>

                        
                        <FormControl
                            sx={{
                                backgroundColor: '#D9D9D9', 
                                borderRadius: '8px',
                                padding: '12px 11px',
                                maxWidth: '314px', 
                                rowGap: '12px',
                            }}>

                            <Button 
                                variant='contained'
                                sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}
                                onClick={() => {employeeCardModel.handleAddEmployeeRoles()}}
                                //disabled={!SubdivisionCardProps.validateEmployeeData()} // Если достигнут максимум
                                >
                                <AddIcon /> Добавить роль
                            </Button>
                            
                            {EmployeeCardProps.employeeRoles.map((role, index) => (
                                <FormControl
                                    key={index}>
                                <Autocomplete 
                                    key={index}

                                    onChange={(_, newRole) =>
                                        EmployeeCardProps.handleSetEmployeeRoles(index, newRole?.id!, newRole?.name!)
                                    }
                                    defaultValue={EmployeeCardProps.employeeRoles[index] || null}

                                    // defaultValue={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}
                                    // value={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}
                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={[popperFromAutocompleteStyle, {minWidth: '274px'}]} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    
                                    options={roleOptions.map(
                                        (role) => role
                                    )}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText={'Не найдено'}

                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Роль'
                                            placeholder='Добавить роль'
                                            autoComplete='off'
                                        />
                                    )}
                                />
                            </FormControl>
                            ))}


                        </FormControl>

                        <FormControl
                            sx={{
                                backgroundColor: '#D9D9D9', 
                                borderRadius: '8px',
                                padding: '12px 11px',
                                maxWidth: '314px',  
                                rowGap: '12px',
                            }}>

                            <Button 
                                variant='contained'
                                sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}
                                onClick={() => {EmployeeCardProps.handleAddEmployeeProducts()}}
                                //disabled={!SubdivisionCardProps.validateEmployeeData()} // Если достигнут максимум
                                >
                                <AddIcon /> Добавить продукт
                            </Button>

                            
                            {EmployeeCardProps.employeeProducts.map((product, index) => (
                                <FormControl
                                    key={index}>
                                <Autocomplete 
                                    onChange={(_, newProduct) =>
                                        EmployeeCardProps.handleSetEmployeeProducts(index, newProduct?.id!, newProduct?.name!)
                                    }

                                    defaultValue={EmployeeCardProps.employeeProducts[index] || null}
                                    // value={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}
                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={popperFromAutocompleteStyle} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    
                                    options={EmployeeCardProps.products.map(
                                        (product) => product
                                    )}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText={'Не найдено'}

                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Продукт'
                                            placeholder='Выберите продукт'
                                            autoComplete='off'
                                        />
                                    )}
                                />
                            </FormControl>
                            ))}


                        </FormControl>

                        <Button 
                                variant='contained'
                                sx={blueContainedBigButtonStyle}
                                //onClick={() => {employeeCardModel.handleCreateEmployee()}}
                                //disabled={!employeeCardModel.validateEmployeeData()} // Если достигнут максимум ////////////////////////////////////////////
                                >
                                Сохранить
                        </Button>

                    </FormGroup> */}
                    <FormGroup
                        sx={{
                            rowGap: '24px',
                            maxWidth: '314px',
                        }}>

                            {employeeCardModel.employeeProducts.map((product, pIndex) => (
                                <FormControl
                                    key={pIndex}>
                                <Autocomplete 
                                    onChange={(_, newProduct:any) => {
                                        //EmployeeCardProps.handleSetEmployeeProducts(pIndex, newProduct?.id!, newProduct?.name!)
                                        employeeCardModel.handleSetEmployeeProduct(pIndex, newProduct)
                                    }}

                                    //defaultValue={EmployeeCardProps.employeeProducts[index] || null}

                                    // value={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}


                                    freeSolo forcePopupIcon

                                    onInputChange={(_, newInputValue) => {
                                        employeeCardModel.handleSetProductInput(pIndex, newInputValue)
                                        //console.log(employeeCardModel.employeeProducts)
                                        if (newInputValue === '') {
                                            employeeCardModel.handleSetEmployeeProduct(pIndex, {id: 0, name: ''})
                                        }
                                    }}
                                    //inputValue={employeeCardModel?.productInputs[pIndex] || ''}
                                    inputValue={
                                        employeeCardModel?.productInputs[pIndex] ||
                                        employeeCardModel.employeeProducts[pIndex].product.name || ''
                                    }
                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={[popperFromAutocompleteStyle, {minWidth: '274px'}]} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    
                                    options={employeeCardModel?.productOptions[pIndex]?.options || []}

                                    isOptionEqualToValue={(option:any, value:any) => option.name === value.name}
                                    getOptionLabel={(option:any) => option?.name || ''}
                                    noOptionsText={'Не найдено'}

                                    renderOption={(props, option: any, { inputValue }) => {
                                        const matches = match(option?.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}},

                                                {marginBottom: '24px'},
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Продукт'
                                            placeholder='Выберите продукт'
                                            autoComplete='off'
                                        />
                                    )}
                                />

                        <FormControl
                            sx={{
                                backgroundColor: '#D9D9D9', 
                                borderRadius: '8px',
                                padding: '12px 11px',
                                maxWidth: '314px', 
                                rowGap: '12px',
                            }}>

                            <Button 
                                variant='contained'
                                sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}

                                onClick={() => {employeeCardModel.handleAddProductRole(pIndex)}}

                                //disabled={!SubdivisionCardProps.validateEmployeeData()} // Если достигнут максимум
                                >
                                <AddIcon /> Добавить роль
                            </Button>
                            
                            {employeeCardModel.employeeProducts[pIndex].roles.map((role, rIndex) => (
                                <FormControl
                                    key={rIndex}>
                                <Autocomplete 
                                    key={rIndex}

                                    onChange={(_, newRole:any) => {
                                        employeeCardModel.handleSetProductRole(pIndex, rIndex, newRole)
                                    }}
                                    onInputChange={(_, newInputValue) => {
                                        employeeCardModel.handleSetRoleInput(pIndex, rIndex, newInputValue)
                                        if (newInputValue === '') {
                                            employeeCardModel.handleSetProductRole(pIndex, rIndex, {id: 0, name: ''})
                                        }
                                    }}
                                    //inputValue={employeeCardModel?.roleInputs[pIndex]?.inputs[rIndex] || ''}
                                    inputValue={
                                        employeeCardModel?.roleInputs[pIndex]?.inputs[rIndex] ||
                                        employeeCardModel.employeeProducts[pIndex].roles[rIndex].name || ''
                                    }

                                    freeSolo forcePopupIcon

                                    //defaultValue={EmployeeCardProps.employeeRoles[index] || null}

                                    // defaultValue={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}
                                    // value={EmployeeCardProps.positions.find(position => 
                                    //     position.id === EmployeeCardProps.positionId
                                    // ) || null}
                                    
                                    sx={autocompleteStyle}
                                    popupIcon={<KeyboardArrowRightRoundedIcon />}
                                    PopperComponent={(props) => (
                                        <Popper {...props} sx={[popperFromAutocompleteStyle, {minWidth: '274px'}]} />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper elevation={2} {...props} sx={paperFromAutocompleteStyle} />
                                    )}
                                    
                                    options={employeeCardModel?.roleOptions[pIndex]?.options || []}

                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option:any) => option?.name || ''}
                                    noOptionsText={'Не найдено'}

                                    renderOption={(props, option:any, { inputValue }) => {
                                        const matches = match(option?.name, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name, matches);
                                        return (
                                            <li {...props} key={option.id}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#009FDA'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                            sx={[
                                                textFieldFilledStyle, 
                                                {'& .MuiAutocomplete-clearIndicator': {display: 'none'}}
                                            ]}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ 
                                                ...params.InputProps, 
                                                disableUnderline: true,
                                            }}
                                            label='Роль'
                                            placeholder='Добавить роль'
                                            autoComplete='off'
                                        />
                                    )}
                                />
                            </FormControl>
                            ))}

                            </FormControl>

                            </FormControl>
                            ))}

                            <Button 
                                variant='contained'
                                sx={[blueContainedBigButtonStyle, {fontSize: '16px'}]}
                                onClick={() => {employeeCardModel.handleAddEmployeeProduct()}}
                                //disabled={!SubdivisionCardProps.validateEmployeeData()} // Если достигнут максимум
                                >
                                <AddIcon /> Добавить продукт
                            </Button>

                            <Button 
                                variant='contained'
                                sx={blueContainedBigButtonStyle}
                                onClick={() => {employeeCardModel.handleSaveEmployee()}}
                                disabled={!employeeCardModel.validateEmployeeData()}
                                >
                                Сохранить
                            </Button>

                        </FormGroup>
                    </TabPanel>
                </Box>
                
                
                </TabContext>                 
            </Card>
        ) : null
}