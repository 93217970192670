import { combineReducers, configureStore} from "@reduxjs/toolkit";
import logReducer from '../pages/Login/LoginSlice';
import  orgReducer  from "../pages/Organisations/OrganisationsSlice";
import emplReducer from '../pages/Organisations/modules/OrganisationEmployeesModule/OrganisationEmployeesSlice'
import subdivReducer from '../pages/Organisations/modules/OrganisationSubdivisionsModule/OrganisationSubdivisionsSlice'
import accReducer from '../pages/Organisations/modules/OrganisationAccountsInfoModule/OrganisationAccountsSlice'

const rootReducer = combineReducers({
    logReducer,
    orgReducer,
    emplReducer,
    
    subdivReducer,

    accReducer,
})

export const setupStore = ()=>{
    return configureStore({
        reducer: rootReducer
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']