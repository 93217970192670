export const styles = {
  root: {
    '--transition': '300ms ease-out',
    '.menu-item': {
      display: 'block',
      padding: '8px 16px',
      color: '#FFFFFF',
      textDecoration: 'none',
      transition: 'var(--transition)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      userSelect: 'none',
      cursor: 'pointer',
    },
    '& .MuiTypography-root': {
      transition: 'var(--transition)',
    },
    '& .MuiDivider-root': {
      backgroundColor: '#8E8E8E',
      borderRadius: '2px',
      height: '1px',
      margin: '10px 12px 10px 12px',
      width: 'calc(100% - 24px)',
    },
    '& .MuiCollapse-root': {
      paddingRight: '3px',
      backgroundColor: '#3E3E3E',
      boxShadow: '-3px 0px 0px 0px #009FDA inset',
      transition: 'var(--transition)',
    },
    '.no-url': {
      pointerEvents: 'none',
    },
    '.sub-links': {
      '-webkit-user-drag': 'none',
    },
    '.title-icon': {
      display: 'flex',
      alignItems: 'center',
    },
    '.collapse-icon': {
      color: '#787878',
      transition: 'var(--transition)',
    },
    '.major-link': {
      '&:hover': {
        backgroundColor: '#3E3E3E',
      },
      '&.active': {
        color: '#009FDA',
        backgroundColor: '#E6F7FF',
        boxShadow: '-3px 0px 0px 0px #009FDA inset',
      },
    },
    '.minor-link': {
      paddingLeft: '36px',
      backgroundColor: '#3E3E3E',
      '&:hover': {
        backgroundColor: '#5E5E5E',
      },
      '&.active': {
        color: '#FFFFFF',
        backgroundColor: '#00719A',
      },
    },
    '.collapse-entered': {
      opacity: '1',
    },
    '.collapse-hidden': {
      opacity: '0',
    },
  },
}
