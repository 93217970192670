import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useServicesModel } from "./ServicesModel"
import { ServiceDataInt, ServicesTabLabels, ServicesTabValues } from "./services-types";
import { Box, Button, Tab, TextField, Pagination, PaginationItem } from "@mui/material";
import { ServiceCardItem } from "./components/ServiceCardItem/ServiceCardItem";
import { MuiTab } from "../../ui/Tab/MuiTab";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper/modules'
import 'swiper/css/bundle';
import { useEffect, useRef } from "react";
import { ServiceCard } from "./modules/ServiceCard/ServiceCard";
import { NavLink } from "react-router-dom";


export const ServicesPage = () => {

    const tabStyle = {
        textTransform: 'none',
        fontSize: 20,
        fontWeight: 700,
    }
    const servicesWrapStyle = {
        display: 'flex',
        maxWidth: 'inherit',
        flexWrap: 'wrap',
        paddingBottom: '24px',
    }

    const servicesModel = useServicesModel();

    console.log(typeof ServicesTabLabels.TELEPH)
    return (



        <div style={{ minHeight: '100vh' }}>
            <Box sx={{ backgroundColor: "white", padding: '20px',  position: 'relative' }}>
                <Box sx={{ marginTop: '20px' }}>
                    <TextField
                        id="standard-helperText"
                        placeholder='Введите текст'
                        select={false}
                        variant='outlined'
                        type={"text"}

                        size='small'
                        inputProps={
                            {
                                disableUnderline: true,
                                sx: {
                                    width: '440px',
                                    borderTopRightRadius: '0px',
                                    borderBottomRightRadius: '0px'
                                }
                            }}

                    ></TextField>
                    <Button
                        variant="outlined"
                        sx={{
                            height: '40px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            color: '#8E8E8E',
                            // border:'2px solid #D9D9D9'
                        }}
                    >Найти</Button>
                </Box>
            </Box>


            <TabContext value={servicesModel.tabValue}>
                <TabList sx={{ backgroundColor: 'white' }} onChange={servicesModel.handleTabChange}>
                    <Tab sx={tabStyle} label={ServicesTabLabels.MY_SERVICES} value={ServicesTabLabels.MY_SERVICES} />
                    <Tab sx={tabStyle} label={ServicesTabLabels.MED} value={ServicesTabLabels.MED} />
                    <Tab sx={tabStyle} label={ServicesTabLabels.EPL} value={ServicesTabLabels.EPL} />
                    <Tab sx={tabStyle} label={ServicesTabLabels.DOCS} value={ServicesTabLabels.DOCS} />
                    <Tab sx={tabStyle} label={ServicesTabLabels.TELEPH} value={ServicesTabLabels.TELEPH} />
                </TabList>

                {servicesModel.isModalOpen
                    ? <ServiceCard
                        data={servicesModel.modalData}
                        isOpen={servicesModel.isModalOpen}
                        onClose={() => { servicesModel.closeModal() }} />
                    : null}

                <Box sx={{ backgroundColor: 'white', margin: '24px', marginBottom:'0px', }}>
                    <TabPanel sx={{ padding: 0 }} value={ServicesTabLabels.MY_SERVICES}>
                        <Box
                            sx={servicesWrapStyle}
                        >
                            {servicesModel.services && servicesModel.services.map((element: ServiceDataInt, i) => {
                                const data = {
                                    name: element.name,
                                    developer: element.developer,
                                    id: element.id,
                                    previewPic: element.previewPic,
                                    detailPics: element.detailPics,
                                    price: element.price,
                                    rating: element.rating,
                                    description: element.description
                                }
                                return <ServiceCardItem action={() => {

                                    servicesModel.setModalData(data)
                                    servicesModel.openModal();
                                }
                                } miniCardData={data} />
                            })}
                        </Box>

                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={ServicesTabLabels.MED}>
                        <Box sx={{ padding: '24px' }}>
                            <Swiper
                                // spaceBetween={50}
                                // @ts-ignore 
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                modules={[Navigation, Scrollbar, A11y]}
                                navigation
                                scrollbar={{ draggable: true }}
                                init={false}
                            >
                                <SwiperSlide>
                                    <div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 1</div>
                                </SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 2</div></SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 3</div></SwiperSlide>
                            </Swiper>
                        </Box>
                        <Box
                            sx={servicesWrapStyle}
                        >
                            {servicesModel.services && servicesModel.services.map((element: ServiceDataInt, i) => {
                                const data = {
                                    name: element.name,
                                    developer: element.developer,
                                    id: element.id,
                                    previewPic: element.previewPic,
                                    detailPics: element.detailPics,
                                    price: element.price,
                                    rating: element.rating,
                                    description: element.description
                                }
                                return <ServiceCardItem action={() => {

                                    servicesModel.setModalData(data)
                                    servicesModel.openModal();
                                }
                                } miniCardData={data} />
                            })}
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={ServicesTabLabels.EPL}>
                        <Box sx={{ padding: '24px' }}>
                            <Swiper
                                // spaceBetween={50}
                                // @ts-ignore 
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                modules={[Navigation, Scrollbar, A11y]}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                init={false}
                            >
                                <SwiperSlide>
                                    <div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 1</div>
                                </SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 2</div></SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 3</div></SwiperSlide>
                            </Swiper>
                        </Box>
                        <Box
                            sx={servicesWrapStyle}
                        >
                            {servicesModel.services && servicesModel.services.map((element: ServiceDataInt, i) => {
                                const data = {
                                    name: element.name,
                                    developer: element.developer,
                                    id: element.id,
                                    previewPic: element.previewPic,
                                    detailPics: element.detailPics,
                                    price: element.price,
                                    rating: element.rating,
                                    description: element.description
                                }
                                return <ServiceCardItem action={() => {

                                    servicesModel.setModalData(data)
                                    servicesModel.openModal();
                                }
                                } miniCardData={data} />
                            })}
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={ServicesTabLabels.DOCS}>
                        <Box sx={{ padding: '24px' }}>
                            <Swiper
                                // spaceBetween={50}
                                // @ts-ignore 
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                modules={[Navigation, Scrollbar, A11y]}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                init={false}
                            >
                                <SwiperSlide>
                                    <div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 1</div>
                                </SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 2</div></SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 3</div></SwiperSlide>
                            </Swiper>
                        </Box>
                        <Box
                            sx={servicesWrapStyle}
                        >
                            {servicesModel.services && servicesModel.services.map((element: ServiceDataInt, i) => {
                                const data = {
                                    name: element.name,
                                    developer: element.developer,
                                    id: element.id,
                                    previewPic: element.previewPic,
                                    detailPics: element.detailPics,
                                    price: element.price,
                                    rating: element.rating,
                                    description: element.description
                                }
                                return <ServiceCardItem action={() => {

                                    servicesModel.setModalData(data)
                                    servicesModel.openModal();
                                }
                                } miniCardData={data} />
                            })}
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ padding: 0 }} value={ServicesTabLabels.TELEPH}>
                        <Box sx={{ padding: '24px' }}>
                            <Swiper
                                // spaceBetween={50}
                                // @ts-ignore 
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                modules={[Navigation, Scrollbar, A11y]}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                init={false}
                            >
                                <SwiperSlide>
                                    <div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 1</div>
                                </SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 2</div></SwiperSlide>
                                <SwiperSlide><div style={{ backgroundColor: "#DFDFDF", height: '200px' }}>Slide 3</div></SwiperSlide>
                            </Swiper>
                        </Box>
                        <Box
                            sx={servicesWrapStyle}
                        >
                            {servicesModel.services && servicesModel.services.map((element: ServiceDataInt, i) => {
                                const data = {
                                    name: element.name,
                                    developer: element.developer,
                                    id: element.id,
                                    previewPic: element.previewPic,
                                    detailPics: element.detailPics,
                                    price: element.price,
                                    rating: element.rating,
                                    description: element.description
                                }
                                return <ServiceCardItem action={() => {

                                    servicesModel.setModalData(data)
                                    servicesModel.openModal();
                                }
                                } miniCardData={data} />
                            })}
                        </Box>
                    </TabPanel>
                    <Box sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#FAFAFA',
                height:'64px'
            }} width={'100%'}>
            <Pagination
                    count={servicesModel.pageQty}
                    page={servicesModel.page}
                    onChange={(_, num) => servicesModel.setPage(num)}
                    showFirstButton
                    showLastButton
                    variant="outlined" shape="rounded"
                    sx={{ marginY: 3, marginX: "auto" }}
                    renderItem={(item) => (
                        <PaginationItem
                            component={NavLink}
                            to={`/services/${item.page}`}
                            // to={'/organisations'}
                            {...item}
                        />
                    )}
                />
            </Box>
                </Box>
            </TabContext>
           
           
        </div>
    )
}