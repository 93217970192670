import React from 'react'
import { Box, Button, FormControlLabel, FormControlLabelProps, Menu, MenuItem, Radio, RadioGroup, Toolbar, Typography, styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { blueContainedButtonStyle, whiteOutlinedButtonStyle } from '../../../../../styles/MaterialCustomStyles';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';


export const EmployeesToolbar = (EmployeesToolbarBarProps: {
    handleOpenModal: () => void,
}) => {

    return (
        <Toolbar 
            sx={{
                padding: '16px 24px', 
                justifyContent: 'space-between'
            }}>
            <Box 
                sx={{
                    display: 'flex',
                    columnGap: '24px'
                }}>
                <Typography 
                    component={'h2'} 
                    sx={{
                        fontSize:'20px',
                        fontWeight:'500'
                    }}>
                    Список сотрудников
                </Typography>

                <Button 
                    onClick={() => EmployeesToolbarBarProps.handleOpenModal()}
                    variant="contained"  
                    sx={
                        blueContainedButtonStyle
                    }>
                    <AddIcon /> Добавить сотрудника
                </Button>
            </Box>


            <Box 
                sx={{
                    display: 'flex',
                    columnGap: '16px'
                }}>
                <Button 
                    onClick={() => {}}
                    sx={
                        whiteOutlinedButtonStyle
                    }>
                    Редактировать
                </Button>
                
                <Button 
                    onClick={() => {}}
                    sx={{
                        ...whiteOutlinedButtonStyle,
                        padding: '5px 12px',
                        '& .MuiButton-endIcon': {
                            marginLeft: '2px',
                            marginRight: '-7px',
                            fontSize: '17px',
                        },
                    }}
                    endIcon={<KeyboardArrowRightRoundedIcon />}>
                    Ещё
                </Button>
                
            </Box>
      </Toolbar>
    )
}