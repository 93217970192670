import { $api } from "../api"


export const getDadataCompaniesRequest=(query:any)=>{
    console.log(query)
    return $api.get(
        `/v1/third-party/suggestions${query ? `?query=${query}` : ''}`
    );
}

export const getDadataCompaniesByIDRequest=(query:any)=>{
    console.log(query)
    return $api.get(
        `/v1/third-party/findById${query ? `?query=${query}` : ''}`
    );
}