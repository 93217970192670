
export const phoneMaskValidate=(num:string)=>{
  const value = num.replace(/\D+/g, "");
const numberLength = 10;

let result='';
for (let i = 0; i < value.length && i < numberLength; i++) {
  switch (i) {
    case 0:
      result += '(';
      break;
    case 3:
      result += ") ";
      break;
    case 6:
      result += "-";
      break;
    case 8:
      result += "-";
      break;
    default:
      break;
  }
  result += value[i];
}
return result;
}

export const parseLocationPathName=(pathName:string)=>{
  let parsed = pathName.split('/');
  parsed.shift();
  return parsed;
}

export const getPathLinks=(paths:string[])=>{
let pathLinks:any =[];
paths.forEach((el:string, key:number)=>{
    switch(el){
        case '':{
            pathLinks.push({
                label:'Главная',
                to:'' 
            })
            break;
        }
        case 'organisations':{
            pathLinks.push({
                label:'Мои организации',
                to:'organisations' 
            })
            break;
        }
        case 'services':{
            pathLinks.push({
                label:'Каталог сервисов',
                to:'services' 
            })
            break;
        }
        case 'analytic':{
            pathLinks.push({
                label:'Аналитика',
                to:'analytic' 
            })
            break;
        }
        case 'info':{
            pathLinks.push({
                label:'Добавление',
                to:'organisations/info' 
            })
            break;
        }
        case 'editInfo':{
            pathLinks.push({
                label:'Редактирование',
            })
            break;
        }
        default:{
            pathLinks.push({
                label:el,
            })
            break;
        }
    }
})
return pathLinks; 
}