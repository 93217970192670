import { $api } from '../api';
import {
    AccData,
    EditQueryData,
    NewAccData,
} from '../pages/Organisations/modules/OrganisationAccountsInfoModule/OrganisationAccounts-types';

export const getAccountsRequest = (companyId: number) => {
    return $api.get(`/v1/user/company/${companyId}/account`, {
        data: companyId,
    });
};

export const getBanksRequest = (query: string) => {
    return $api.get(
        `/v1/third-party/suggestions${query ? `?query=${query}` : ''}`
    );
};

export const createAccountRequest = (
    companyId: number,
    newAccount: NewAccData
) => {
    return $api.post(`/v1/user/company/${companyId}/account`, newAccount);
};

export const setMainAccountRequest = (data: AccData) => {
    return $api.patch(`/v1/user/company/${data.companyId}/account/${data.id}`, {
        is_main: !data.is_main,
    });
};

export const editAccountRequest = (data: EditQueryData) => {
    return $api.patch(
        `/v1/user/company/${data.companyId}/account/${data.id}`,
        data.data
    );
};
