export const styles = {
  root: {
    '.iconButton': {
      position: 'abolute',
      padding: '18px',
      borderRadius: '4px',
      transition: '300ms ease-out',
      '&:hover': {
        borderRadius: '4px',
        backgroundColor: '#565656',
        transition: '300ms ease-out',
      },
    },
    '.icon': {
      position: 'absolute',
    },
    '.mainicon': {
      position: 'absolute',
      transition: '300ms ease-out',
    },
    '.mainicon-collapse': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: '300ms ease-out',
    },
    '.swapicon': {
      position: 'absolute',
      transform: 'scaleX(-1)',
      visibility: 'hidden',
      opacity: '0',
      transition: '300ms ease-out',
    },
    '.swapicon-collapse': {
      position: 'absolute',
      transform: 'scaleX(-1)',
      visibility: 'visible',
      opacity: '1',
      transition: '300ms ease-out',
    },
  },
}
