import { AxiosRequestConfig } from "axios"
import { $api } from "../api"
import {  Employee, Position } from "../pages/Organisations/modules/OrganisationEmployeesModule/OrganisationEmployees-types"
import { DOC_STATUSES } from "../pages/Organisations/modules/OrganisationEmployeesModule/OrganisationEmployees-enums"


export const getEmployeesRequest = (companyId: number, query?: any) => {
    return $api.get(`/v1/user/company/${companyId}/employee/` + `?query=${JSON.stringify(query)}`)
}

export const createEmployeeRequest = (companyId: number, data: Employee) => {
    return $api.post(`/v1/user/company/${companyId}/employee`, data)
}

export const editEmployeeRequest = (companyId: number, data: Employee) => {
    return $api.patch(`/v1/user/company/${companyId}/employee/${data.id}`, data)
}

export interface getPositionsRequestParams {
    id?: number,
    search?: string,
}
export const getPositionsRequest = (
    companyId: number,
    params?: getPositionsRequestParams
    ) => {
    return $api.get(`/v1/user/company/${companyId}/positions/`, {params})
}

export const createPositionRequest = (companyId: number, data: Position) => {
  return $api.post(`/v1/user/position/${companyId}`, data)
}

export interface getProductsRequestParams {
    search: string,
}
export const getProductsRequest = (params?: getProductsRequestParams) => {
    return  $api.get('/v1/products', {params})
}


export const editSubdivisionRequest=(companyId: number, data: any) => {
    return $api.patch(`/v1/user/company/${companyId}/department/${data.id}`, data)
}


export interface IUpdateDocumentEmployeeBody{
        status: DOC_STATUSES,
        comment: string
        is_replaced:boolean
}
export  interface IUpdateDocumentEmployeeArgs{
    companyId:number,
    employeeId:number,
    documentId:number,
    body:IUpdateDocumentEmployeeBody
}

export const updateEmployeeDocumentRequest=({companyId, employeeId, documentId, body}:IUpdateDocumentEmployeeArgs )=>{
    return $api.patch(`/v1/user/company/${companyId}/employee/${employeeId}/document/${documentId}`, body)
}


export interface getRolesRequestParams {
    id?: number,
    search?: string,
}
export const getRolesRequest = (companyId: number, productId: number,  positionId?: number, params?: getRolesRequestParams) => {
    // if (positionId) {
    //     return  $api.get(`/v1/user/roles/${companyId}?productId=${productId}&positionId=${positionId}`, {params})
    // } else {
    //     return  $api.get(`/v1/user/roles/${companyId}?productId=${productId}`, {params})
    // }
    return  $api.get(`/v1/user/roles/${companyId}?productId=${productId}`, {params})
}

export const getEmployeeRequest = (companyId: number, employeeId?: number) => {
    return $api.get(`/v1/user/company/${companyId}/employee/${employeeId}`)
}