import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
    Autocomplete,
    Paper,
    TextField,
    Card,
    CardContent,
    Popper,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
    AccData,
    EditQueryData,
    NewAccData,
} from '../../OrganisationAccounts-types';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useBankAccountCard } from './BankAccountCardModel';

interface ServiceCardProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (newAccount: NewAccData) => Promise<void>;
    selectedAcc: AccData | null;
    onEdit: (newData: EditQueryData) => Promise<void>;
}

export const AccountCard = ({
    isOpen,
    onClose,
    onSubmit,
    selectedAcc,
    onEdit,
}: ServiceCardProps) => {
    const accCardModel = useBankAccountCard(onSubmit, selectedAcc, onEdit);

    if (!isOpen) {
        return null;
    } else
        return (
            <Card
                sx={{
                    maxWidth: 392,
                    height: '620px',
                    overflowY: 'visible',
                    marginLeft: '30px',
                    marginTop: '30px',
                    backgroundColor: '#EEEEEE',
                    position: 'fixed',
                    zIndex: 10,
                    top: 40,
                    right: 25,
                }}>
                <CardContent sx={{ padding: '24px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <Typography
                            variant='h5'
                            sx={{
                                color: '#3E3E3E',
                                fontSize: 20,
                                fontWeight: 500,
                                marginLeft: '22px',
                            }}>
                            Банковский счет
                        </Typography>
                        <IconButton
                            sx={{
                                '&.MuiButtonBase-root.MuiIconButton-root': {
                                    color: '#3E3E3E',
                                },
                            }}
                            onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '16px',
                            paddingTop: '24px',
                        }}>
                        <Autocomplete
                            options={accCardModel.banks.map(
                                (bank) => bank.value
                            )}
                            value={accCardModel.selectedBank}
                            noOptionsText={'Не найдено'}
                            onChange={(_, newBank) =>
                                accCardModel.setSelectedBank(newBank)
                            }
                            onInputChange={(_, newInputValue) =>
                                accCardModel.setUserInput(newInputValue)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='filled'
                                    label='Банк'
                                    placeholder='БИК или название'
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                            sx={accCardModel.accStyle}
                            blurOnSelect
                            disableClearable
                            popupIcon={<KeyboardArrowDownRoundedIcon />}
                            PaperComponent={(props) => (
                                <Paper
                                    elevation={2}
                                    {...props}
                                    sx={{
                                        marginTop: '2px',
                                        padding: '4px, 0px',
                                        '& .MuiAutocomplete-listbox': {
                                            '& .MuiAutocomplete-option': {
                                                '&:hover': {
                                                    backgroundColor: '#E6F7FF',
                                                },
                                            },
                                        },
                                        '&.MuiAutocomplete-option[data-focus="true"]':
                                            {
                                                backgroundColor: 'transparent',
                                            },
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option, matches);
                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        color: part.highlight
                                                            ? '#1890ff'
                                                            : 'none',
                                                    }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />

                        <TextField
                            autoComplete='off'
                            placeholder='Номер счета'
                            label='Расчетный счет'
                            variant='filled'
                            disabled={!accCardModel.selectedBank ? true : false}
                            value={accCardModel.accountNumber}
                            onChange={(e) =>
                                accCardModel.setAccountNumber(e.target.value)
                            }
                            InputProps={{
                                disableUnderline: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                                sx: {
                                    color: '#8E8E8E',
                                },
                            }}
                            sx={{
                                ...accCardModel.numberStyle,
                            }}
                        />

                        <Autocomplete
                            disabled={!accCardModel.selectedBank ? true : false}
                            fullWidth
                            disableClearable
                            forcePopupIcon
                            popupIcon={<KeyboardArrowDownRoundedIcon />}
                            options={accCardModel.statuses.map(
                                (status) => status
                            )}
                            getOptionLabel={(option) =>
                                (option as { name: string }).name || ''
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                            }
                            value={accCardModel.statuses.find((value) =>
                                value.value === accCardModel.status
                                    ? value.value
                                    : ''
                            )}
                            onChange={(_, status) =>
                                accCardModel.setStatus(status.value)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='filled'
                                    label='Статус'
                                    placeholder='Неизвестный'
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                            sx={accCardModel.statusStyle}
                            blurOnSelect={true}
                            PopperComponent={(props) => (
                                <Popper {...props} sx={{ minWidth: '316px' }} />
                            )}
                            PaperComponent={(props) => (
                                <Paper
                                    elevation={2}
                                    {...props}
                                    sx={{
                                        marginTop: '-69px',
                                        padding: '4px, 0px',
                                        '& .MuiAutocomplete-listbox': {
                                            '& .MuiAutocomplete-option': {
                                                '&:hover': {
                                                    backgroundColor: '#E6F7FF',
                                                },
                                            },
                                        },
                                        '& .MuiAutocomplete-option[data-focus="true"]':
                                            {
                                                backgroundColor: '#E6F7FF',
                                            },
                                    }}
                                />
                            )}
                        />

                        <accCardModel.CustomButton
                            variant='contained'
                            disableElevation
                            onClick={(event) => {
                                event.preventDefault();
                                if (accCardModel.accountNumber.length === 20) {
                                    accCardModel.handleSubmit();
                                    console.log(accCardModel.selectedBank);
                                    console.log(accCardModel.accountNumber);
                                    console.log(accCardModel.status);
                                } else {
                                    console.log(
                                        'Длина расчетного счета должна быть 20 символов'
                                    );
                                }
                            }}
                            disabled={
                                !accCardModel.selectedBank ? true : false
                            }>
                            Сохранить
                        </accCardModel.CustomButton>
                    </Box>

                    <Box sx={{ marginTop: '16px' }}></Box>
                </CardContent>
            </Card>
        );
};
