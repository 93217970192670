import { Box, IconButton } from '@mui/material'
import type { SidebarCollapseButtonProps } from './types'
import type { FC } from 'react'
import { styles } from './styles'
import { CollapseIcon } from '../../icons/CollapseIcon'

export const SidebarCollapseButton: FC<SidebarCollapseButtonProps> = (
  props
) => {
  const { collapse, setCollapse } = props

  return (
    <Box sx={styles.root}>
      <IconButton
        onClick={() => setCollapse((prev: boolean) => !prev)}
        className="iconButton"
      >
        <Box className={!collapse ? 'mainicon' : 'mainicon-collapse'}>
          <CollapseIcon />
        </Box>
        <Box className={!collapse ? 'swapicon' : 'swapicon-collapse'}>
          <CollapseIcon />
        </Box>
      </IconButton>
    </Box>
  )
}
