export type servicesTabs = 'my' | 'med' | 'epl' |'docs' | 'teleph'

export  enum ServicesTabLabels{
    MY_SERVICES = 'Мои сервисы',
    MED = 'Медицина',
    EPL = 'ЭПЛ',
    DOCS = 'Работа с документами',
    TELEPH = 'Телефония'
}

export enum ServicesTabValues{
    MY_SERVICES = 'my',
    MED = 'med',
    EPL = 'epl',
    DOCS = 'docs',
    TELEPH = 'teleph'
}

export interface ServiceDataInt{
    id:number,
    name:string,
    rating:string,
    previewPic:string,
    detailPics:string[] | null
    price:string,
    developer:string,
    description?:string
}