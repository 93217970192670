import { useEffect } from "react";
import { getCompaniesRequest } from "../../services/CompanyService";
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux";
import {setOrganisations } from "./OrganisationsSlice";


export const useOrganisationPageModel=()=>{
    const { 
        organisations
    }= useAppSelector(state=> state.orgReducer)
    const dispatch = useAppDispatch();

    useEffect(()=>{
        getCompanies()
    },[])

    const getCompanies= async()=>{
            const response = await getCompaniesRequest()
            console.log('companies')
            console.log(response)
            dispatch(setOrganisations(response.data.data))
    }



    return{
        organisations,
  
    }
}