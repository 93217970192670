import { useLocation } from "react-router-dom"
import { getPathLinks, parseLocationPathName } from "../../utils";
import axios from "axios";
import { useEffect } from "react";

export const useLocationModel=()=>{
    const location = useLocation();

    const paths =  parseLocationPathName(location.pathname)
    console.log(paths)


    const pathLinks = getPathLinks(paths)
    return {
         paths,
         pathLinks
    }
}