import { $api } from "../api"



export const getCompaniesRequest=()=>{
    return $api.get('/v1/user/company')
}

export const createCompanyRequest=(data:any)=>{
    return $api.post('/v1/user/company', data)
}

export const editCompanyRequest=(data:any)=>{
    return $api.patch(`/v1/user/company/${data.id}`, data)
}

