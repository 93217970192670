import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {InfoData } from "./Organisation-types"

interface OrganisationState{
    organisations:InfoData[],
    currentOrganisation:InfoData,
}

const initialState:OrganisationState = {
    organisations:[],
    currentOrganisation:{
        inn:'',
        kpp:'',
        ogrn:'',
        short_title:'',
        title:'',
        id:undefined
    },
}


export const orgSlice = createSlice({
    name:'org',
    initialState,
    reducers:{
        setOrganisations(state, action:PayloadAction<InfoData[]>){
            state.organisations = action.payload
        },
        setCurrentOrganisation(state, action:PayloadAction<InfoData>){
            state.currentOrganisation = action.payload
        },
    },
})

export const {
  setOrganisations,
  setCurrentOrganisation,
} = orgSlice.actions

export default orgSlice.reducer