import { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router"
import { getTetronTokens } from "../../../../services/AuthService"

export const PrivateRoute =({children}:any)=>{

  const [ready, setReady] = useState<boolean>(false)
  useEffect(() => {
    (async () => {
    const urlParam = new URLSearchParams(document.location.search)
    const jwt = urlParam.get('jwt')
    if (jwt) {
      const response = await getTetronTokens(jwt)
      localStorage.setItem('accessToken', response.data.access_token)
      localStorage.setItem('refreshToken', response.data.refresh_token)
      localStorage.setItem('isAuth', 'true')
    }

    const urlParams = new URLSearchParams(window.location.href)
    const ate = urlParams.get('ate') //accessToken
    const rte = urlParams.get('rte') //refreshToken
    const textDecoder = new TextDecoder()
    const at = ate
      ? textDecoder.decode(new Uint8Array(ate.split('.').map((e) => Number(e))))
      : false
    const rt = rte
      ? textDecoder.decode(new Uint8Array(rte.split('.').map((e) => Number(e))))
      : false
    if (at && rt) {
      localStorage.setItem('accessToken', at)
      localStorage.setItem('refreshToken', rt)
      localStorage.setItem('isAuth', 'true')
    }

    setReady(true)
  })()
  }, [])

  const location = useLocation()
  const checkAuth = () => {
    const isAuth = localStorage.getItem("isAuth")
    if(isAuth !== 'true'){
      return <Navigate to='/login' state={{from:location}}></Navigate>
    }
    return children
  }

  // const location = useLocation()
  // const isAuth = localStorage.getItem("isAuth")
  // if(isAuth!=='true'){
  //     return <Navigate to='/login' state={{from:location}}></Navigate>
  // }

    return ready ? checkAuth() : null
}