import {$api} from "../api";
import {AppDispatch} from "../store/store";
import {AxiosResponse} from "axios";






export const  loginRequest=(email?:string, password?:string)=>{
    return  $api.post('/v1/web/auth/login', {email, password})

}

export const logoutRequest=()=>{

        return  $api.post('/v1/web/auth/logout')
}


export const createPasswordRequest=(password:string, confirm:string,)=> {
     return  $api.post('/v1/web/auth/password', {password:password, confirm:confirm})
}

export const phoneLoginRequest=(phone:string, password:string,)=> {
    return $api.post('/v1/web/auth/phone-login', {phone:phone, password:password})
}

export const registerByEmailRequest=(email:string, code:string, invatationId?:string)=> {
        return $api.post('/v1/web/auth/register-by-email', {email:email, code:code, invatationId:invatationId})

}

export const registerByPhoneRequest=(phone:string, code:string, invatationId?:string)=> {
   return  $api.post('/v1/web/auth/register-by-phone', {phone:phone, code:code, invatationId:invatationId})

}

export const  sendConfirmationRequest= async (type:string, value:string,)=> {
    console.log(type, value)
   return  await $api.post('/v1/web/auth/send-confirmation', {type, value})
}

export const refreshRequest= async ()=> {
   return $api.post('/v1/web/auth/refresh')
}

export const getTetronTokens = (jwtToken: string) => {
    return $api.post('/v1/web/auth/tetron-tokens', {token: jwtToken})
}

export default class AuthServ{
    static async sendConfirmationRequest(type:string, value:string) {
        console.log(type, value)
        return $api.post('/v1/web/auth/send-confirmation', {type, value})
    }
}



