import { useLocation } from "react-router-dom"
import { IUpdateDocumentEmployeeArgs, getEmployeesRequest, updateEmployeeDocumentRequest } from "../../../../../../services/EmployeeService"
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks/redux"
import { IDocument } from "../../OrganisationEmployees-types"
import { setEmployees } from "../../OrganisationEmployeesSlice"
import { useDrawerModel } from "../drawers/CheckDocumentsDrawer/ControlledFormModel"
import { useEffect, useMemo, useState } from "react"
import { CHECKING_DOC_STATUSES, DOC_STATUSES } from "../../OrganisationEmployees-enums"


export const useDocumentAccordionModel = ({ documentData, employeeId }: {
    documentData?: IDocument,
    employeeId?: number,
}) => {


    const getCheckingDocumentStatus = (status?: DOC_STATUSES) => {
        if (status === DOC_STATUSES.CHECKED || status === DOC_STATUSES.EXPIRES) {
           return CHECKING_DOC_STATUSES.ACTUAL   
        } else if (status === DOC_STATUSES.ERROR || status === DOC_STATUSES.EXPIRED) {
            return CHECKING_DOC_STATUSES.ERROR
        }  else if (status === DOC_STATUSES.NEW || status === DOC_STATUSES.CHECKING) {
            return CHECKING_DOC_STATUSES.NEW
        } else return status
    }


 const [currentDocumentStatus, setCurrentDocumentStatus] = useState<CHECKING_DOC_STATUSES | string>()

    const dispatch = useAppDispatch()

    const getEmployees = async (id:number) => {
        try {
            const response = await getEmployeesRequest(id)
            console.log(response)
            dispatch(setEmployees(response.data.data))
        } catch(e) {
            console.error(e)
        }
    }

    const defaultValues = {
        comment:' ',
    }
    const {pathname} = useLocation()

    console.log(pathname)
    const { control, reset, handleSubmit, getValues, setValue, setError } = useDrawerModel(defaultValues)

    const { currentOrganisation } = useAppSelector(state => state.orgReducer)

    const onAcceptSubmit = async (formData: any) => {
        console.log(formData)
        // updateMedicDocument({})
        const organisationId = pathname.split('/').reverse()[0]
        if (!!organisationId && !!employeeId && documentData?.id){
           updateMedicDocument({
            companyId: Number(organisationId),
                employeeId: employeeId,
                documentId: documentData.id,
                body: {
                    status: DOC_STATUSES.CHECKED,
                    comment: formData.comment.trim() ? formData.comment : null,
                    is_replaced:false
                }
            }) 
           
            
        }
        
    }


   useMemo(()=>{
    if( !!documentData?.document_status ){
        const status = getCheckingDocumentStatus(documentData.document_status)
        console.log('Статус', status)
        setCurrentDocumentStatus(status)  
    }
   },[ documentData])


    


    const checkComment = () => {
        return getValues('comment').trim()

    }


    const onDeclineSubmit = (formData: any) => {
        console.log(formData)
        console.log(currentOrganisation.id)
        console.log(employeeId)
        console.log(documentData?.id)

        if (formData.comment.trim()) {
            const organisationId = pathname.split('/').reverse()[0]
            if (!!organisationId && !!employeeId && documentData?.id)
                updateMedicDocument({
                    companyId: Number(organisationId),
                    employeeId: employeeId,
                    documentId: documentData.id,
                    body: {
                        status: DOC_STATUSES.ERROR,
                        comment: formData.comment.trim() ? formData.comment : null,
                        is_replaced:false
                    }
                })
        } else {
            setError('comment', {type:'required', message:'Введите комментарий'})
             console.log('error')
            }
    }


    const updateMedicDocument = async ({ companyId, employeeId, documentId, body }: IUpdateDocumentEmployeeArgs) => {
        try {
            const response = await updateEmployeeDocumentRequest({ companyId, employeeId, documentId, body })
            if (response) {
                    getEmployees(companyId)
            } else throw new Error('Ошибка запроса')
        } catch (e) {

        }

    }




    return {
        control,
        reset,
        handleSubmit,
        getValues,
        onAcceptSubmit,
        onDeclineSubmit,
        setValue,
        currentDocumentStatus
    }
}