import { useState } from "react";
import { useForm } from "react-hook-form";


export const useDrawerModel = (defaultValues:any) => {


    const [isOpen, setOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setOpen(open);
    };
    const { handleSubmit, reset, control, setValue, register, getValues, setError } = useForm<any>({
        defaultValues: defaultValues,
    });

    return {
        isOpen,
        toggleDrawer,
        handleSubmit,
        reset,
        control,
        setValue,
        getValues,
        register,
        setError
    }
}