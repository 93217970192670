import { useEffect, useState } from "react"
import { OrganisationsTabsLabels } from "./OrganisationAllInfo-types"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux";
import { InfoData } from "../../Organisation-types";
import { setCurrentOrganisation } from "../../OrganisationsSlice";
import { getCompaniesRequest } from "../../../../services/CompanyService";



export const useOrganisationAllInfoModel = () => {

    const { id } = useParams();

    const {
        organisations,
        currentOrganisation,
    } = useAppSelector(state => state.orgReducer)

    const dispatch = useAppDispatch();

    useEffect(() => {
      (async () => {
        const current = (await getCompaniesRequest()).data.data.find((item: InfoData) => item.id == id)//organisations.find((item: InfoData) => item.id == id)
        console.log('current')
        console.log(current)
        current ? dispatch(setCurrentOrganisation(current)) : dispatch(setCurrentOrganisation(
            {
                inn:null,
                kpp:null,
                ogrn:null,
                short_title:null,
                title:null,
            }
           ));
           
        // current?.id && getAccounts(current.id); 
      })()
    }, [])

    const [tabValue, setTab] = useState<OrganisationsTabsLabels>(OrganisationsTabsLabels.MAIN_INFO)




    const handleTabChange = (event: React.SyntheticEvent, value: OrganisationsTabsLabels) => {
        setTab(value)
    }



    

    return {
        tabValue,
        setTab,
        handleTabChange,
        currentOrganisation,
    }
}

