import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { tableHeaderText } from "../../../../styles/MaterialCustomStyles"


export const AddressesTable =(props:any)=>{
    return(
        <TableContainer component={Paper}>
        <Table sx={{ maxWidth: 650, width:'100%' }} aria-label="simple table">
            <TableHead>
                <TableRow
                    sx={{
                        backgroundColor:'#FAFAFA',
                    }}
                >
                    <TableCell
                    sx={
                       tableHeaderText
                    }
                    >Юридический адрес</TableCell>
                    <TableCell
                     sx={
                        tableHeaderText
                     }
                    align="right">Фактический адрес</TableCell>
                    <TableCell
                     sx={
                        tableHeaderText
                     }
                    align="right">Контакты</TableCell>
                      <TableCell
                     sx={
                        tableHeaderText
                     }
                    align="right">Статус</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* {props.addresses.map((row:any) => (
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.legalAddress}
                        </TableCell>
                        <TableCell align="right">{row.actualAddress}</TableCell>
                        <TableCell align="right">{row.contacts}</TableCell>
                        <TableCell align="right">{row.status}</TableCell>
                    </TableRow>
                ))} */}
            </TableBody>
        </Table>
    </TableContainer>
    )
}