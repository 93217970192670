export interface InfoData {
    id?: number | null,
    title: string | null,
    short_title: string | null,
    inn: string | null,
    kpp: string | null,
    ogrn: string | null,
    status?: string | null,
}

export enum statusType {
    'CONFIRMED' = 'CONFIRMED',
    'DRAFT' = 'DRAFT',
    'DELETED' = 'DELETED',
}

export enum statusLabelType {
    'CONFIRMED' = 'Действует',
    'DRAFT' = 'Черновик',
    'DELETED' = 'Удален',
}

export enum entryLabelType {
    'NEW' = 'Новая'
}