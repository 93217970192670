
import {  IDocument } from '../../OrganisationEmployees-types';
import { Box, Button, Chip, Divider, Stack, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
// import { useDocumentAccordionModel } from '';
import { $api, $file } from '../../../../../../api';
import { useEffect, useState } from 'react';
// import { DocumentPhotoGroup } from '../../components/DocumentPhotoGroup';
import { useDocumentAccordionModel } from './DocumentAccordionModel';
import { CommentInput } from '../../../../../../components/controlled/CommentInput';
import { DocumentPhotoGroup } from '../../components/DocumentPhotoGroup';
import { statuses } from '../../../OrganisationAccountsInfoModule/OrganisationAccounts-types';
import { CHECKING_DOC_STATUSES, DOC_STATUSES, DOC_TYPES } from '../../OrganisationEmployees-enums';





interface DocumentAccordionProps {
    documentData?: IDocument,
    label: string,
    employeeId?: number,
}

export const getDocumentStatusChip = (status: DOC_STATUSES | string | undefined) => {

    const chipStyle = {
        '&.MuiChip-root': {
            borderRadius: '4px',
            border: '1px solid',
            maxHeight: '22px',

            fontFamily: 'Roboto',
            fintWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
        },
        '& .MuiChip-label': {
            padding: '2px 8px',
        }
    }

    switch (status) {
        case DOC_STATUSES.CHECKED:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#EFFFEE', borderColor: '#33C341', color: '#33C341' }} variant="outlined" label={DOC_STATUSES.CHECKED} />
        case DOC_STATUSES.CHECKING:
            return <Chip sx={{ ...chipStyle, background: '#EAF8FF', borderColor: '#009FDA', color: '#009FDA' }} variant="outlined" label={DOC_STATUSES.CHECKING} />
        case DOC_STATUSES.ERROR:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={DOC_STATUSES.ERROR} />
        case DOC_STATUSES.EXPIRED:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={DOC_STATUSES.EXPIRED} />
        case DOC_STATUSES.EXPIRES:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={DOC_STATUSES.EXPIRES} />
        case DOC_STATUSES.NEW:
            return <Chip
                sx={{ ...chipStyle, background: '#F4EBFF', borderColor: '#9747FF', color: '#9747FF' }} variant="outlined" label={DOC_STATUSES.NEW} />
        case DOC_STATUSES.MISSED:
            return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={DOC_STATUSES.MISSED} />
        default:
            return <Chip color='default' sx={{ ...chipStyle }} variant="outlined" label={status} />
    }



}

const getCheckingDocumentStatusChip = (status?: CHECKING_DOC_STATUSES | string) => {
    const chipStyle = {
        '&.MuiChip-root': {
            borderRadius: '4px',
            border: '1px solid',
            maxHeight: '22px',

            fontFamily: 'Roboto',
            fintWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
        },
        '& .MuiChip-label': {
            padding: '2px 8px',
        }
    }
    if (status === CHECKING_DOC_STATUSES.ACTUAL) {
        return <Chip sx={{
            ...chipStyle,
            backgroundColor: '#EFFFEE',
            borderColor: '#33C341',
            color: '#33C341'
        }} variant="outlined" label={CHECKING_DOC_STATUSES.ACTUAL} />

    } else if (status === CHECKING_DOC_STATUSES.ERROR) {
        return <Chip sx={{
            ...chipStyle,
            backgroundColor: '#FFF0DD',
            borderColor: '#F0901E',
            color: '#F0901E'
        }} variant="outlined" label={CHECKING_DOC_STATUSES.ERROR} />

    } else if (status === CHECKING_DOC_STATUSES.NEW) {
        return <Chip sx={{
            ...chipStyle,
            background: '#EAF8FF',
            borderColor: '#509ED6',
            color: '#509ED6'
        }} variant="outlined" label={CHECKING_DOC_STATUSES.NEW} />

    } else  {
        return <Chip sx={{
            ...chipStyle,
        }} variant="outlined" label={status} />

    }
    
}

const getDocumentStatusIcon = (status?: DOC_STATUSES) => {
    switch (status) {
        case DOC_STATUSES.NEW:
            return <ErrorOutlineOutlinedIcon color='info' />
        case DOC_STATUSES.CHECKING:
            return <ErrorOutlineOutlinedIcon color='info' />
        case DOC_STATUSES.ERROR:
            return <ErrorOutlineOutlinedIcon color='warning' />
        case DOC_STATUSES.MISSED:
            return <CancelOutlinedIcon color='warning' />
        case DOC_STATUSES.EXPIRED:
            return <ErrorOutlineOutlinedIcon color='warning' />
        case DOC_STATUSES.EXPIRES:
            return <ErrorOutlineOutlinedIcon color='warning' />
        case DOC_STATUSES.CHECKED:
            return <CheckCircleOutlineOutlinedIcon color='success' />
        case undefined:
            return <CancelOutlinedIcon
                sx={{ color: 'red' }} />
        default: return <ErrorOutlineOutlinedIcon />
    }
}


export const createFileRequest = async (file: any) => {
    return await $api.post(`/v1/files`, file)
}

const getFiles = async (urls: string[]) => {
    const files = await Promise.all(urls.map((url: string) => {
        return $api.get(url)
    }))
    console.log('async files')
    console.log(files)
    return files;
}



const getFileLink = async (file: any) => {
    let formData = new FormData()
    formData.append('file', file)

    try {
        let link = await createFileRequest(formData);
        console.log(link)
        return link
    } catch (error) {
        console.log('ERROR')
        console.log(error)
    }
}

const createLinks = async (urls: string[]) => {
    console.log('urls')
    console.log(urls)
    let links: any = []
    links = urls.map(async (file: any) => {
        const fileLink = getFileLink(file)
        return fileLink
    })
    return await Promise.all(links)
}


const downloadFile = (url: string, filename: string) => {

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const download = async (url?: string) => {
    console.log(url)
    if (!!url) {
        const fileUrl = await $file.get(url);
        console.log(fileUrl)
        const localUrl = URL.createObjectURL(fileUrl.data)
        downloadFile(localUrl, 'photo.png')
    }
}

const getLocalUrls = async (urls?: string[]) => {
    let responses: any = []
    let localUrls: any = []
    responses = urls?.map(async (url: string) => {
        const fileUrl = await $file.get(url);
        console.log(fileUrl)
        return fileUrl
    })
    await Promise.all(responses).then((results) => {
        localUrls = results.map((result) => {
            return URL.createObjectURL(result.data)
        })
    });
    return localUrls
}



const buttonStyle = {
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    height: '48px',
    backgroundColor: '#1890FF',

    boxShadow: 'none'
}




const activeColor = '#009FDA'
export const DocumentAccordion = ({ documentData, label, employeeId }: DocumentAccordionProps) => {

    const { control,
        handleSubmit,
        getValues,
        onAcceptSubmit,
        onDeclineSubmit,
        setValue,
        currentDocumentStatus
    } = useDocumentAccordionModel({ documentData, employeeId });

    const [imageSources, setImageSources] = useState<any>([])


    useEffect(() => {
        (async () => {
            if (documentData) {
                const links = await getLocalUrls(documentData.url)
                console.log(links)
                setImageSources(links)
            }
        })()

    }, [documentData])


    useEffect(() => {
        // console.log('картинки')
        // console.log(imageSources)
    }, [imageSources])




    return (
        <Accordion


            sx={{ borderRadius: '4px' }}>
            <AccordionSummary
                disabled={!documentData}
                expandIcon={<ExpandMoreIcon sx={{
                    marginTop: '12px',
                }} />}
                sx={{
                    alignItems: 'flex-start',
                    "& .Mui-expanded": {
                        marginTop: '12px',
                    },
                    '&.Mui-disabled': {
                        opacity: '1'
                    }
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  {getDocumentStatusIcon(documentData?.document_status)}
                    <Typography sx={{ marginLeft: 1, fontWeight: 400, fontSize: '14px' }}>{label}</Typography>
                </Box>

            </AccordionSummary>
            <Divider></Divider>
            <AccordionDetails>
                <Stack gap={2}>


                    <Box>
                        <Typography sx={{ fontSize: '16px' }}>
                            Статус документа
                        </Typography>

                      {getCheckingDocumentStatusChip(currentDocumentStatus)}

                    </Box>
                    {documentData?.type === DOC_TYPES.EDUCATION_CERTIFICATE
                        || documentData?.type === DOC_TYPES.ACCREDIATATION_CERTIFICATE
                        ? <Box>

                            <Typography sx={{ fontSize: '16px' }}>
                                {documentData?.type === DOC_TYPES.EDUCATION_CERTIFICATE
                                    ? 'Серийный номер'
                                    : documentData?.type === DOC_TYPES.ACCREDIATATION_CERTIFICATE
                                        ? 'Уникальный номер реестровой записи'
                                        : <></>}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {documentData?.registry_number}
                            </Typography>
                        </Box>
                        : <></>}
                    {documentData?.type === DOC_TYPES.EDUCATION_CERTIFICATE
                        ? <Box>
                            <Typography sx={{ fontSize: '16px' }}>
                                Срок действия, лет
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {documentData?.validity_period}
                            </Typography>
                        </Box>

                        : <></>}

                    <Box>
                        <Typography sx={{ fontSize: '16px' }}>
                            Дата начала
                        </Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '500' }} >
                            {new Date(documentData?.issued_at).toLocaleDateString('ru-RU')}
                        </Typography>
                    </Box>
                    {documentData?.type === DOC_TYPES.EDUCATION_CERTIFICATE
                        || documentData?.type === DOC_TYPES.QUALIFICATION_CERTIFICATE
                        ? <Box>
                            <Typography sx={{ fontSize: '16px' }}>
                                Дата окончания
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {new Date(documentData?.expires_at).toLocaleDateString('ru-RU')}
                            </Typography>
                        </Box>
                        : <></>}
                    {documentData?.type === DOC_TYPES.QUALIFICATION_CERTIFICATE
                        ? <></>
                        : <Box>
                            <Typography sx={{ fontSize: '16px' }}>
                                Фотокопии
                            </Typography>
                            <Stack direction={'row'} gap={2} >
                                <DocumentPhotoGroup urls={documentData?.url}></DocumentPhotoGroup>
                            </Stack>


                        </Box>}

                    <Box>
                        <CommentInput
                            control={control}
                            label='Комментарий'
                            name='comment'
                            isDisabled={currentDocumentStatus!==CHECKING_DOC_STATUSES.NEW}
                        // rules={{ required: true }}
                        />
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}>
                        <Button
                            onClick={handleSubmit(onDeclineSubmit)}

                            sx={{
                                ...buttonStyle, backgroundColor: '#ff4d4f', width: '135px',
                                '&:hover': {
                                    backgroundColor: '#DD2D2F',
                                    boxShadow: 'none',
                                }
                            }}
                            disabled={currentDocumentStatus!==CHECKING_DOC_STATUSES.NEW}
                            fullWidth
                            variant="contained">
                            Отклонить
                        </Button>
                        <Button
                            onClick={
                                // setValue('comment', ' ')
                                handleSubmit(onAcceptSubmit)
                            }
                            sx={{
                                ...buttonStyle, backgroundColor: activeColor, width: '135px',
                                '&:hover': {
                                    backgroundColor: '#007DB8',
                                    boxShadow: 'none',
                                }
                            }}
                            disabled={currentDocumentStatus!==CHECKING_DOC_STATUSES.NEW}
                            fullWidth
                            variant="contained">
                            Принять
                        </Button>
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

