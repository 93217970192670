import { Modal, Box, Typography } from "@mui/material";
import { SuccessIcon } from "./SeccessIcon";

export const SucceedWindow = (SucceedWindowProps: {
    open: boolean;
}) => {

    const modalBoxStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '4px',
        width: 312,
        height: 312,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',

        '& h2': {
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: '700',
            color: '#3E3E3E',
        },
    };


    return (
        <Modal
            open={SucceedWindowProps.open}
        
            aria-labelledby='modal-title'
            aria-describedby='modal-description'>
            <Box sx={modalBoxStyle}>
                <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'>
                    Данные успешно внесены!
                </Typography>
                <SuccessIcon />
            </Box>
        </Modal>
    );
}