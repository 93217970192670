import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Rating from "@mui/material/Rating"
import Typography from "@mui/material/Typography"
import serviceDefaultPic from '../../../../assets/Pic.png'
import StarIcon from '@mui/icons-material/Star';

interface ServiceMiniCardItemsProps {
    image?: string,
    serviceName?: string,
    developer?: string,
}

export const ServiceMiniCardItem = ({ image, serviceName, developer }: ServiceMiniCardItemsProps) => {
    return (
        <Card sx={{
            maxWidth: 194, width: '100%', maxHeight: 252, height: '100%',
            backgroundColor: 'white', cursor: 'pointer',
        }}>
            <CardContent>
                <CardMedia sx={{ height: 137 }} image={image ? image : serviceDefaultPic}>

                </CardMedia>
                <Box sx={{ marginTop: '8px' }}>
                    <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 700 }} component="div">
                        {serviceName ? serviceName : 'Название сервисов'}
                    </Typography>
                    <Typography variant='caption' sx={{ fontSize: 11, fontWeight: '700' }} color="text.secondary">
                        Разработчик: {developer ? developer : 'разработчик'}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                           <StarIcon floodColor={'#FAAF00'}/>
                            <Typography >
                                5
                            </Typography>
                        </Box>
                        <CardActions sx={
                            { display: 'flex', justifyContent: 'center' }
                        }>
                            <Button sx={{ backgroundColor: 'white', border: '2px solid #1890FF', color: '#1890FF', fontWeight: '600', fontSize: '10px', width: '82px' }} variant='outlined' size="small">Установить</Button>
                        </CardActions>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
} 