import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia, Rating } from '@mui/material';
import serviceDefaultPic from '../../../../assets/Pic.png'
import { ServiceDataInt } from '../../services-types';




interface serviceCardItemsProps{
    miniCardData: ServiceDataInt,
    action():void,
}

export const ServiceCardItem = ({miniCardData, action}:serviceCardItemsProps)=> {
    const {developer,name, previewPic, price, rating, } = miniCardData
    return (
        <Card sx={{ maxWidth: 260, width: '100%', maxHeight: 350, height: '100%', marginLeft:'30px', marginTop:'30px',
         backgroundColor: '#EEEEEE', cursor:'pointer',
         }}
         onClick={()=>action()}
         >
            <CardContent>
                <CardMedia sx={{ height: 137 }} image={previewPic ? previewPic : serviceDefaultPic}>

                </CardMedia>
                <Box sx={{ marginTop: '12px' }}>
                    <Typography variant="h6" sx={{ fontSize: 16, fontWeight:700}} component="div">
                        {name ? name : 'Название сервисов'}
                    </Typography>
                    <Typography variant='caption' sx={{ fontSize: 12, fontWeight: '700' }} color="text.secondary">
                        Разработчик: {developer ? developer : 'разработчик' }
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Rating name="read-only" value={Number(rating)
                        } readOnly />
                        <Typography >
                            {rating}
                        </Typography>
                    </Box>
                </Box>
                <CardActions sx={
                    { display: 'flex', justifyContent: 'center' }
                }>
                    <Button sx={{ backgroundColor: 'white',
                     border: '2px solid #1890FF',
                      color: '#1890FF',
                       fontWeight: '600',
                        fontSize: '14px' }} fullWidth={true} variant='outlined' size="small">{price} Р</Button>
                </CardActions>
            </CardContent>
        </Card>
    );
}