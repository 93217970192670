import type { SidebarLogoProps } from './types'
import type { FC } from 'react'
import { Box, Stack } from '@mui/material'
import { styles } from './styles'
import { LogoIcon } from '../../icons/LogoIcon'
import { LogoTitleIcon } from '../../icons/LogoTitleIcon'

export const SidebarLogo: FC<SidebarLogoProps> = (props) => {
  const { collapse } = props

  return (
    <Stack sx={styles.root}>
      <Box className={!collapse ? 'logo' : 'logo-collapse'}>
        <LogoIcon />
      </Box>
      <Box className={!collapse ? 'title' : 'title-collapse'}>
        <LogoTitleIcon />
      </Box>
    </Stack>
  )
}
