import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import { Link } from 'react-router-dom';
import {
    AccData,
    EditQueryData,
    statusLabels,
} from '../OrganisationAccounts-types';
import { Box } from '@mui/material';

const tableHeaderText = {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    padding: '13px 16px',
};

interface BankAccountsTableProps {
    bankAccounts: AccData[];
    onChangeMain: (account: AccData) => Promise<void>;
    onClickAcc: (account: AccData) => any;
}

export const BankAccountsTable = ({
    bankAccounts,
    onChangeMain,
    onClickAcc,
}: BankAccountsTableProps) => {
    const statusStyle = {
        borderRadius: '2px',
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'lowercase',
        display: 'flex',
        alignItems: 'center',
        padding: '1px 8px',
        width: 'max-content',
    };

    const confirmedStyle = {
        ...statusStyle,
        backgroundColor: '#F6FFED',
        color: 'rgba(183, 235, 143, 1)',
        border: '1px solid #B7EB8F',
    };

    const draftStyle = {
        ...statusStyle,
        backgroundColor: '#F0F5FF',
        color: '#2F54EB',
        border: '1px solid #ADC6FF',
    };

    const deletedtStyle = {
        ...statusStyle,
        backgroundColor: '#FFF1F0',
        color: '#F5222D',
        border: '1px solid #FFA39E',
    };

    return (
        <TableContainer
            // component={Paper}
            sx={{
                maxWidth: 1050,
                width: '100%',
            }}>
            <Table
                aria-label='simple table'
                size='small'
                sx={{
                    '&.MuiTableCell-root': {
                        color: '#000',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '22px',
                        borderBottom: '1px solid #F0F0F0',
                    },
                }}>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#FAFAFA',
                        }}>
                        <TableCell
                            sx={{
                                ...tableHeaderText,
                                backgroundColor: '#F5F5F5',
                                padding: '8px',
                            }}>
                            Основной счет
                        </TableCell>
                        <TableCell
                            sx={{
                                ...tableHeaderText,
                                padding: '13px 3px 13px 16px',
                            }}
                            align='right'>
                            Номер расчетного счета
                        </TableCell>
                        <TableCell sx={tableHeaderText} align='right'>
                            Наименование банка
                        </TableCell>
                        <TableCell sx={tableHeaderText} align='right'>
                            Статус
                        </TableCell>
                        <TableCell sx={tableHeaderText} align='right'>
                            Имя
                        </TableCell>
                        <TableCell sx={tableHeaderText} align='right'>
                            Действие
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {bankAccounts.map((row: AccData) => (
                        <TableRow
                            // onClick={() => {
                            //     onClickAcc(row);
                            // }}
                            // hover
                            key={row.id}
                            sx={{
                                '& th:first-of-type': {
                                    backgroundColor: '#FAFAFA',
                                },
                                // '&:hover': {
                                //     '& th:first-of-type': {
                                //         backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                //     },
                                // },
                            }}>
                            <TableCell
                                component='th'
                                scope='row'
                                sx={{
                                    // backgroundColor: '#FAFAFA',
                                    textAlign: 'center',
                                    minWidth: '122px',
                                }}>
                                <Radio
                                    icon={
                                        <svg
                                            width='16'
                                            height='16'
                                            viewBox='0 0 16 16'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z'
                                                fill='white'
                                                stroke='#D9D9D9'
                                            />
                                        </svg>
                                    }
                                    checkedIcon={
                                        <svg
                                            width='16'
                                            height='16'
                                            viewBox='0 0 16 16'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z'
                                                fill='white'
                                                stroke='#1890FF'
                                            />
                                            <rect
                                                x='4'
                                                y='4'
                                                width='8'
                                                height='8'
                                                rx='4'
                                                fill='#1890FF'
                                            />
                                        </svg>
                                    }
                                    disableRipple
                                    checked={row.is_main ? true : false}
                                    onChange={() => onChangeMain(row)}
                                    value='true'
                                    name='radio-buttons'
                                    // onClick={() => onChangeMain(row)}
                                />
                            </TableCell>
                            <TableCell
                                component='th'
                                scope='row'
                                sx={{
                                    minWidth: '183px',
                                }}>
                                {row.number}
                            </TableCell>
                            <TableCell
                                component='th'
                                scope='row'
                                sx={{
                                    minWidth: '195px',
                                }}>
                                {row.name}
                            </TableCell>
                            <TableCell
                                component='th'
                                scope='row'
                                sx={{
                                    minWidth: '178px',
                                }}>
                                {row.status === 'CONFIRMED' ? (
                                    <Box sx={confirmedStyle}>
                                        {statusLabels.confirmed}
                                    </Box>
                                ) : row.status === 'DRAFT' ? (
                                    <Box sx={draftStyle}>
                                        {statusLabels.draft}
                                    </Box>
                                ) : (
                                    row.status === 'DELETED' && (
                                        <Box sx={deletedtStyle}>
                                            {statusLabels.deleted}
                                        </Box>
                                    )
                                )}
                            </TableCell>
                            <TableCell
                                component='th'
                                scope='row'
                                sx={{
                                    minWidth: '175px',
                                }}>
                                {row.bank_name}
                            </TableCell>

                            <TableCell
                                onClick={() => {}}
                                align='right'
                                sx={{
                                    minWidth: '82px',
                                }}>
                                <Link to={`/organisations/editInfo/${row.id}`}>
                                    Подробнее
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
