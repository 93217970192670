export const styles = {
  root: {
    backgroundColor: '#2A2A2A',
    color: '#FFFFFF',
    padding: '24px 0',
    position: 'sticky',
    top: '0',
    transition: '300ms ease-out',
    minHeight: '100vh',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '100px',
  },
  collapse: {
    position: 'fixed',
    bottom: '10px',
    left: '8px',
  },
}
