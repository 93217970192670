import { useEffect, useState } from "react"
import { ServiceDataInt, ServicesTabLabels, servicesTabs } from "./services-types"
import { getServicesRequest } from "../../services/ProductService"
import { useParams } from "react-router-dom"


interface getServicesRequestData{
    page?:number,
    perPage?:number,
    sectionName?:string
} 


export const useServicesModel=()=>{
    const { curPage } = useParams();

    const [tabValue, setTab] = useState<ServicesTabLabels>(ServicesTabLabels.MY_SERVICES)
    const [modalData, setModalData] = useState<ServiceDataInt>()
    const [services, setServices] = useState<ServiceDataInt[]>();
    const [page, setPage] = useState(Number(curPage))
    const [pageQty, setPageQty] = useState(0);

    const handleTabChange=(event: React.SyntheticEvent, value:ServicesTabLabels)=>{
        setTab(value)    
    }

    useEffect(()=>{
        if(!!curPage){}else setPage(1);
    },[curPage])
    

    const [isModalOpen, setModalOpen] = useState(false)


    const closeModal =()=>{setModalOpen(false)};
    const openModal =()=>{setModalOpen(true)} ;

    useEffect(()=>{
        console.log(isModalOpen)
    },[isModalOpen])

    useEffect(()=>{
        if(tabValue===ServicesTabLabels.MY_SERVICES) getServices({perPage:5})
        else getServices({sectionName:tabValue, perPage:5})
    },[tabValue])

    useEffect(()=>{
        getServices({sectionName:tabValue, perPage:5, page:page})
    },[page])

    const getServices = async (data?:getServicesRequestData) => {
                try {
                    const response = await getServicesRequest(data)
                 console.log(response)
                 setServices(response.data.data)
                 setPageQty(response.data.last_page)  
                } catch (e) {
                    console.log(e) 
                }
    }


    const myServicesArr=[
    {servicesTitle:"Мой сервис1", devName:"Роман"},
    {servicesTitle:"Мой сервис2", devName:"Роман"},
    {servicesTitle:"Мой сервис3", devName:"Роман"},
    {servicesTitle:"Мой сервис4", devName:"Роман"},
]


    return{
        tabValue,
        handleTabChange,
        myServicesArr,
        services,
        isModalOpen,
        closeModal,
        openModal,
        setModalData,
        page,
        pageQty,
        setPage,
        setPageQty,
        modalData,
    }
}