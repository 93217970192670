import { Box } from '@mui/material'
import { SidebarMenu } from './components'
import { styles } from './styles'
import { useState } from 'react'
import { SidebarLogo } from './components/SidebarLogo'
import { Stack } from '@mui/material'
import { SidebarCollapseButton } from './components/SidebarCollapseButton'

export const Sidebar = () => {
  const [collapse, setCollapse] = useState<boolean>(false)

  return (
    <Stack sx={[styles.root, { width: collapse ? '52px' : '256px' }]}>
      <Box sx={styles.logo}>
        <SidebarLogo collapse={collapse} />
      </Box>
      <SidebarMenu collapse={collapse} setCollapse={setCollapse} />
      <Box sx={styles.collapse}>
        <SidebarCollapseButton collapse={collapse} setCollapse={setCollapse} />
      </Box>
    </Stack>
  )
}
