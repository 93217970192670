import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux";
import { useLoginModuleModel } from "../loginModuleModel";
import { loginRequest, phoneLoginRequest, } from "../../../../services/AuthService";
import { AppDispatch } from "../../../../store/store";
import { setAuth, setAuthInProgress } from "../../../../pages/Login/LoginSlice";
import { log } from "util";
import { useEffect, useState } from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginErrors, TabValues } from "../loginConstants";
import { Navigate, useNavigate } from "react-router-dom";

export const useAuthModel = () => {


    const templateModel = useLoginModuleModel();

    const { isNeedToAuth: isAuth, isAuthInProgress } = useAppSelector(state => state.logReducer)
    const [isErrorHidden, setErorrHidden] = useState<boolean>(true)


    useEffect(() => { console.log(isErrorHidden) }, [isErrorHidden])

    const navigate = useNavigate();

    const toggleErrors = () => {
        setErorrHidden(!isErrorHidden)
    }


        // useEffect(()=>{
        //    if (templateModel.isFormValid){
        //     navigate(`/home/services`)
        //    }
        // },[templateModel.isFormValid])

    const emailLogin = async () => {  
                try {
                    const response = await loginRequest(templateModel.mail, templateModel.password)
                    templateModel.setFormValid(true)
                    localStorage.setItem('accessToken', response.data.access_token)
                    localStorage.setItem('refreshToken', response.data.refresh_token)
                    console.log('Успешно')
                    setErorrHidden(true)
                    window.alert('Авторизация успешна, вы попали на домашнюю страницу')
                    localStorage.setItem('isAuth', 'true')
                    navigate(`/`)
                } catch (e) {
                    console.log(e)
                    templateModel.setFormValid(false)
                    setErorrHidden(false)
                    console.log('Ошибка')
                    window.alert('Авторизация провалена')
                }
      
    }





    const phoneLogin = async () => {
        const clearPhone = templateModel.phone.replace(/\D+/g, '')
                try {
                    const response = await phoneLoginRequest(`+7${clearPhone}`, templateModel.password)
                    templateModel.setFormValid(true)
                    localStorage.setItem('accessToken', response.data.access_token)
                    localStorage.setItem('refreshToken', response.data.refresh_token)
                    console.log('Успешно')
                    setErorrHidden(true)
                    window.alert('Авторизация успешна, вы попали на домашнюю страницу')
                    navigate(`/`)
                } catch (e) {
                    // console.log(e)
                    templateModel.setFormValid(false)
                    setErorrHidden(false)
                    console.log('Ошибка')
                    window.alert('Авторизация провалена')
                }
    }


    const updateInputData = (data: any) => {
        templateModel.setMail(data.email)
        templateModel.setPhone(data.phone)
        templateModel.setPassword(data.password)
        templateModel.setCurrentTabValue(data.currentTab)
    }




    return {
        mail: templateModel.mail,
        password: templateModel.password,
        currentTabValue: templateModel.currentTabValue,
        showPass: templateModel.showPass,
        LoginErrors: LoginErrors,
        isFormValid: templateModel.isFormValid,
        isErrorHidden,
        phone: templateModel.phone,
        updateInputData,
        toggleErrors,
        handleTabChange: templateModel.handleTabChange,
        handlePhoneChange: templateModel.handlePhoneChange,
        handleMailChange: templateModel.handleMailChange,
        handlePasswordChange: templateModel.handlePasswordChange,
        emailLogin,
        phoneLogin,
        togglePasswordHide: templateModel.togglePasswordHide
    }
}