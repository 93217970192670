
import { Box, Button, Typography } from '@mui/material';
import { useOrganisationPageModel } from './OrganisationsModel';
import { OrganisationAllInfo } from './modules/OrganisationAllInfoModule/OrganisationAllInfo';
import { useNavigate } from 'react-router-dom';
import { OrganisationsTable } from './components/OrganisationsTable/OrganisationsTable';
import { blueContainedButtonStyle } from '../../styles/MaterialCustomStyles';




export const OrganisationsPage = () => {
    const organisationsPageModel = useOrganisationPageModel();
    const navigate = useNavigate()

    return (
        <Box sx={{
            minHeight: '100vh'
        }}>
            <Box sx={{ backgroundColor: "white", padding: '20px', position: 'relative' }}>
                <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '483px' }}>
                    <Typography sx={{
                        fontSize: '30px',
                        fontWeight: '500'
                    }} component={'h1'}>
                        Мои организации
                    </Typography>
                    <Button
                        onClick={() => {

                            navigate('/organisations/info')
                        }}
                        variant="contained"
                        sx={
                            {
                                ...blueContainedButtonStyle,
                                '&':{
                                    fontWeight: '400',
                                }
                                
                            }
                        }
                    >+ Добавить организацию</Button>
                </Box>
            </Box>
            <Box sx={{
                margin: '20px',
            }}>
                <OrganisationsTable
                    organisations={organisationsPageModel.organisations}
                >
                </OrganisationsTable>
            </Box>


        </Box>
    )

}