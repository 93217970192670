
export enum EMPLOYEE_TABLE_DOC_STATUSES{
    MISSED = 'Отсутствует',
    ACTUAL = 'Актуальные',
    PROCESSING = 'На проверке',
    ERROR = 'С ошибкой',
    NEW = 'Новый',
    EXPIRED = 'Просрочен'
}


export enum DOC_TYPES{
    EDUCATION_CERTIFICATE = "Удостоверение об основном образовании",
    EDUCATION_DIPLOMA = "Диплом об основном образовании",
    ACCREDIATATION_CERTIFICATE = "Аккредитация специалиста",
    ECP = "Электронная подпись (ЭЦП)",
    QUALIFICATION_CERTIFICATE = "Удостоверение о повышении квалификации"

}

export enum DOC_STATUSES{
    NEW = 'Новый',
    CHECKING = "На проверке",
    CHECKED = 'Проверен',
    ERROR = 'Ошибка',
    EXPIRES = 'Истекает срок',
    EXPIRED = 'Просрочен',
    MISSED = ' Документ отсутствует',
}


export enum CHECKING_DOC_STATUSES{
    ACTUAL = 'Актуален',
    ERROR = 'Ошибка данных',
    NEW = 'Новый'    
}

export enum ContactType {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
}
