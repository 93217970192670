import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Autocomplete, Box, Button, MenuItem, Paper, Stack, Tab, TextField, Toolbar, Typography } from "@mui/material"
import { useOrganisationMainInfoModel } from "./OrganisationMainInfoModel"
import { MainInfoTabsLabels } from "./OrganisationMainInfo-types";
import { useNavigate } from "react-router-dom";
import { blueContainedButtonStyle, textFieldFilledStyle } from "../../../../styles/MaterialCustomStyles";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { AddressesTable } from "../../components/AdressesTable/AddressesTable";


export const OrganisationMainInfo = () => {
    const mainInfoModel = useOrganisationMainInfoModel();
    const navigate = useNavigate();


    const autocompleteStyle = {
        '& .MuiAutocomplete-popupIndicator': {
            scale: '0.85',
            color: '#787878',
        },
        '& .MuiAutocomplete-popupIndicatorOpen': {
            transition: '0.1s ease-out',
            opacity: 0.6,
            transform: 'rotate(-90deg) scale(0.85)',
        },
        '& .MuiAutocomplete-clearIndicator': {
            scale: '0.85',
        },
        '& :not(focused)': {
            transition: 'all 0.1s ease-out',
        },
    }

    const inputStyle = {
        marginTop: '20px',
    }

    const tabStyle = {
        border: "1px solid #D9D9D9",
        borderRadius: '0px, 2px, 2px, 0px',
        color: 'black',
        fontSize: '14px',
        textTransform: 'none',
        fontWeight: '400',
        padding: "5px",
        lineHeight: '0',
        '&.Mui-selected': {
            border: "1px solid #009FDA",
            color: '#009FDA',
        },
        '&': {
            minHeight: '32px'
        },
    }
    return (
        <Box>
            <Box sx={{ backgroundColor: "white", position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '700'
                    }} component={'h3'}>Основная информация</Typography>
                </Toolbar>
            </Box>
            <Box sx={{
                backgroundColor: 'white',
                padding: '0px 24px 24px 24px'
            }}>
                <TabContext value={mainInfoModel.tabValue}>
                    <TabList TabIndicatorProps={{ style: { display: 'none' } }} sx={{ backgroundColor: 'white' }} onChange={mainInfoModel.handleTabChange}>
                        <Tab
                            sx={tabStyle}
                            label={MainInfoTabsLabels.MAIN} value={MainInfoTabsLabels.MAIN} />
                        <Tab sx={tabStyle} label={MainInfoTabsLabels.ADDRESS} value={MainInfoTabsLabels.ADDRESS} />

                    </TabList>
                    <Box sx={{
                        width: '585px',
                        marginTop: 3
                    }}>
                        <TabPanel sx={{ padding: 0, }} value={MainInfoTabsLabels.MAIN}>
                            <Stack spacing={3}>
                                <Autocomplete
                                    options={mainInfoModel.options}
                                    noOptionsText={'Не найдено'}
                                    onChange={(_, newValue: any) =>
                                        mainInfoModel.setSelectedCompany(newValue)
                                    }
                                    value={mainInfoModel.mainInfoData.title}
                                    onInputChange={(_, title) =>
                                        mainInfoModel.handleAutocompleteTitleChange(title)
                                    }
                                    sx={autocompleteStyle}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={textFieldFilledStyle}
                                            variant='filled'
                                            label='Полное наименование'
                                            placeholder='Введите наименование'
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper
                                            elevation={2}
                                            {...props}
                                            sx={{
                                                marginTop: '2px',
                                                padding: '4px, 0px',
                                                '& .MuiAutocomplete-listbox': {
                                                    '& .MuiAutocomplete-option': {
                                                        '&:hover': {
                                                            backgroundColor: '#E6F7FF',
                                                        },
                                                    },
                                                },
                                                '& .MuiAutocomplete-option[data-focus="true"]':
                                                {
                                                    backgroundColor: '#E6F7FF',
                                                },
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option, { inputValue }) => {

                                        const matches = match(option.label, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.label, matches);
                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span

                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#1890ff'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                />

                               

                                <TextField
                                    sx={{ ...textFieldFilledStyle, width: '314px' }}
                                    id="outlined-select-currency"
                                    label="Сокращенное наименование"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    variant="filled"
                                    placeholder='Сокращенное наименование'
                                    autoComplete='off'
                                    value={mainInfoModel.mainInfoData.short_title === null ? '' : mainInfoModel.mainInfoData.short_title}
                                    onChange={mainInfoModel.handleShortTitleChange}
                                >
                                </TextField>
                                <Autocomplete
                                    options={mainInfoModel.options}
                                    noOptionsText={'Не найдено'}
                                    onChange={(_, newValue: any) =>
                                        mainInfoModel.setSelectedCompany(newValue)
                                    }
                                    value={mainInfoModel.mainInfoData.inn}
                                    onInputChange={(_, inn) =>
                                        mainInfoModel.handleINNChange(inn)
                                    }
                                    sx={autocompleteStyle}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{...textFieldFilledStyle, width:'314px'}}
                                            variant='filled'
                                            label='ИНН'
                                            placeholder='Укажите ИНН'
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                    PaperComponent={(props) => (
                                        <Paper
                                            elevation={2}
                                            {...props}
                                            sx={{
                                                marginTop: '2px',
                                                padding: '4px, 0px',
                                                '& .MuiAutocomplete-listbox': {
                                                    '& .MuiAutocomplete-option': {
                                                        '&:hover': {
                                                            backgroundColor: '#E6F7FF',
                                                        },
                                                    },
                                                },
                                                '& .MuiAutocomplete-option[data-focus="true"]':
                                                {
                                                    backgroundColor: '#E6F7FF',
                                                },
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option, { inputValue }) => {

                                        const matches = match(option.label, inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.label, matches);
                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span

                                                            key={index}
                                                            style={{
                                                                color: part.highlight
                                                                    ? '#1890ff'
                                                                    : 'none',
                                                            }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                />
                                <TextField
                                    disabled
                                    sx={{ ...textFieldFilledStyle, width: '314px' }}
                                    id="outlined-select-currency"
                                    label="КПП"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    placeholder="Укажите КПП"
                                    variant="filled"
                                    value={mainInfoModel.mainInfoData.kpp === null ? '' : mainInfoModel.mainInfoData.kpp}
                                    onChange={mainInfoModel.handleKPPChange}
                                >
                                </TextField>
                                <TextField
                                    disabled
                                    sx={{ ...textFieldFilledStyle, width: '314px' }}
                                    id="outlined-select-currency"
                                    label="ОГРН"
                                    placeholder="Укажите ОГРН"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    variant="filled"
                                    value={mainInfoModel.mainInfoData.ogrn === null ? '' : mainInfoModel.mainInfoData.ogrn}
                                    onChange={mainInfoModel.handleOGRNChange}
                                >
                                </TextField>
                                <TextField
                                    sx={{ ...textFieldFilledStyle, width: '314px' }}
                                    disabled
                                    variant="filled"
                                    id="outlined-select-currency"
                                    label="Статус"
                                    placeholder="Неизвестен"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                    value={mainInfoModel.mainInfoData.status === null ? '' : mainInfoModel.mainInfoData.status}
                                    onChange={mainInfoModel.handleStatusChange}
                                >
                                </TextField>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Button
                                        disabled={!mainInfoModel.isValid}
                                        variant="contained"
                                        sx={
                                            { ...blueContainedButtonStyle, height: '48px', width: '314px' }
                                        }
                                        onClick={() => {
                                            mainInfoModel.inEditMode
                                                ? mainInfoModel.editCompany(mainInfoModel.mainInfoData)
                                                : mainInfoModel.createCompany(mainInfoModel.mainInfoData)
                                            navigate('/organisations')

                                        }}>Сохранить</Button>
                                </Box>
                            </Stack>

                        </TabPanel>
                        <TabPanel sx={{ padding: 0 }} value={MainInfoTabsLabels.ADDRESS}>
                            <Box

                            >
                                <AddressesTable></AddressesTable>
                            </Box>
                        </TabPanel>
                    </Box>

                </TabContext>
            </Box>

        </Box>
    )
}