import { Breadcrumbs, Typography } from "@mui/material"
import { useLocationModel } from "./LocationModel";
import { Link } from "react-router-dom";
import { locationLinkStyle, locationTextStyle } from "./styles";



export const Location=()=>{



    const locationModel = useLocationModel();

    return (
        <div style={{display:'flex'}} role="presentation">
        <div>/</div> 
        <Breadcrumbs sx={{marginLeft:1}} aria-label="breadcrumb">
          {locationModel.pathLinks.map((el:any, key:number)=>{
            if(el.to){
              return <Link
            to={`/${el?.to}`}
          ><Typography sx={locationLinkStyle}>{el.label}</Typography></Link>
            } 
          else {
            return <Typography sx={locationTextStyle}>{el.label}</Typography>
          } 
          })}
        </Breadcrumbs>
      </div>
    )
    
}