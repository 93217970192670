import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/redux';
import { useEffect, useState } from 'react';
import {
    AccData,
    EditQueryData,
    NewAccData,
} from './OrganisationAccounts-types';
import {
    getAccountsRequest,
    createAccountRequest,
    setMainAccountRequest,
    editAccountRequest,
} from '../../../../services/BankAccountService';
import { clearAccounts, setAccounts } from './OrganisationAccountsSlice';

export const useBankAccountsInfo = () => {
    const { id } = useParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSuccessOpen, setSuccessOpen] = useState(false);
    const [selectedAcc, setSelectedAcc] = useState<AccData | null>(null);
    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const { bankAccounts } = useAppSelector((state) => state.accReducer);
    const dispatch = useAppDispatch();

    const getAccounts = async (companyId: number) => {
        try {
            const response = await getAccountsRequest(companyId);
            console.log('GOT ACCOUNTS');
            console.log(response);
            dispatch(setAccounts(response.data.data));
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        //id && getAccounts(Number(id));
        if (id) {
            getAccounts(Number(id))
        } else {
            dispatch(clearAccounts())
        }
    }, []);

    useEffect(() => {
        !isModalOpen && setSelectedAcc(null);
    }, [isModalOpen]);

    const handleCreateAccount = async (newAccount: NewAccData) => {
        if (id) {
            try {
                const response = await createAccountRequest(
                    Number(id),
                    newAccount
                );
                console.log(response);
                setModalOpen(false);
                getAccounts(Number(id));
                setSuccessOpen(true);
                setTimeout(() => setSuccessOpen(false), 2000);
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleEditAccount = async (newData: EditQueryData) => {
        if (id) {
            try {
                const response = await editAccountRequest(newData);
                console.log(response);
                setModalOpen(false);
                getAccounts(Number(id));
                setSuccessOpen(true);
                setTimeout(() => setSuccessOpen(false), 2000);
                setSelectedAcc(null);
            } catch (e) {
                console.error(e);
            }
        }
    };

    const onClickAccRow = (account: AccData) => {
        setSelectedAcc(null);
        console.log('row clicked');
        console.log(account);
        setSelectedAcc(account);
        setModalOpen(true);
    };

    const changeMainAccount = async (account: AccData) => {
        try {
            const response = await setMainAccountRequest(account);
            console.log(response);
            getAccounts(Number(id));
        } catch (e) {
            console.error(e);
        }
    };

    const modalBoxStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '4px',
        width: 312,
        height: 312,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',

        '& h2': {
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: '700',
            color: '#3E3E3E',
        },
    };

    return {
        id,
        bankAccounts,
        openModal,
        closeModal,
        isModalOpen,
        handleCreateAccount,
        handleEditAccount,
        onClickAccRow,
        changeMainAccount,
        modalBoxStyle,
        isSuccessOpen,
        selectedAcc,
    };
};
