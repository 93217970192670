
import classes from "./pinCode.module.css";
import ReactCodeInput from "react-code-input";
import React, {FC} from "react";
import {RecPinCodeProps, RecStages} from "../../rec-types";
import Button from "@mui/material/Button";


export const RecPinCodeForm:FC<RecPinCodeProps>=(
    {
        pinCode,
        handlePinChange, action,
        mail,
        phone,
        type,
        error,
        isValid,
        timer,
        timeLeft,
        toggleErrors,
        isErrorHidden,
        repeatConfirmationSend,
        isConfirmationSended,
        isTimerStarted
    })=>{

    return(
        <div className={classes.main}>
            <h2>Восстановление пароля</h2>
            {
                type==='email'
                    ? <p className={classes.text}>Введите код из сообщения. Сообщение с кодом направлено на электронную почту {mail}</p>
                    :
                    <p className={classes.text}>Введите код из сообщения. Сообщение с кодом направлено на номер {phone}</p>
            }

            <ReactCodeInput onChange={handlePinChange} value={pinCode} name={"pincode"} inputMode={'numeric'}  fields={4}/>
            <div className={classes.linkCont}>
                {isConfirmationSended
                    ? isTimerStarted
                        ? <a className={classes.codeLink}
                         href={'#'}>Запросить код повторно через {timeLeft}</a>
                        : <a className={classes.codeLink}
                             onClick={()=>{repeatConfirmationSend()}}
                             href={'#'}>Запросить код повторно</a>
                    : null
                }
                {
                    isErrorHidden
                        ? null
                        : <p onClick={()=>{toggleErrors()}} className={classes.error}>{error}</p>
                }
            </div>


            <Button
                fullWidth={true}

                sx={{
                    color: 'white',
                    textTransform: 'none',
                    fontSize: 16,
                    fontWeight: 700,
                    height: 50,
                    backgroundColor: '#1890FF',
                    marginTop:'24px'
                }}
                onClick={() => { action()}}
                variant='contained'>Подтвердить</Button>

        </div>
    )
}